import { useState } from 'react';
import Breadcrumbs from "../components/breadcrumbs";
import Field from '../snippets/field';
import Image from '../snippets/image';
import api from '../services/api';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

const content = [
  {
    title: 'Llena este formulario de solicitud',
    text: 'Te preguntamos datos generales como el nombre de tu empresa, los productos que incluirias en tu tienda online, si has realizando envíos antes y algunas cosas más',
  },
  {
    title: 'Recibe nuestra respuesta',
    text: 'Nuestro equipo revisará tu solicitud y te dará una respuesta dentro de 3 días máximo',
  },
  {
    title: 'Edita tu perfil  y añade tus productos',
    text: 'Tendrás acceso a nuestra plataforma en línea, donde podrás editar tu perfil de tienda, cargar fotos, descripciones y precios de tus productos para que puedan ser vistos por todo el país',
  },
  {
    title: 'Comienza a vender en línea',
    text: 'Una vez configurado tu perfil y cargados tus productos, estarás listo para comenzar a vender',
  }
]

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImF1dGhlbnRpY2F0aW9uIjp7InJvbGVzIjp7InN1cGVyYWRtaW4iOnRydWUsImFkbWluIjpmYWxzZSwidXNlciI6ZmFsc2UsImd1ZXN0IjpmYWxzZSwiZWRpdG9yIjpmYWxzZSwibW9kZXJhdG9yIjpmYWxzZX0sInN0YXRlIjp0cnVlfSwiX2lkIjoiNjRlNmNkZDlmOTM2NDAxNTcxZGQyMTI2IiwidXNlcm5hbWUiOiJhZG1pbiIsInBhc3N3b3JkIjoiJDJiJDEwJFkuV0ZMTzgvWERUamlaZGtmNXpWc3VwMjNWMjRYeUpFanRzZWtmdUZzVDRTQlJjSVdRSVVpIn0sImlhdCI6MTY5MzM2Mzc2NiwiZXhwIjoxNjkzMzY1NTY2fQ.JhH5ooBZNsz_Os55Du7lgmGxZtHF5og2uGmMsN_e_a4";

const handleOnSendRequest = async (shop_name, owner_fullname, identification_code, ruc, email, phone, address) => {
  const data = {
    shop_name: shop_name,
    owner_fullname: owner_fullname,
    identification_code: identification_code,
    ruc: ruc,
    email: email,
    phone: phone,
    address: address,
  }

  try {
    const shopRequestData = await api.post(`api/shopRequest`, data, token);
    console.log("data: ", shopRequestData)
  } catch (error) {
    console.log("could not send the request: ", error);
  }
}

const ApplicationForm = ({ setShowForm }) => {
  const [shopName, setShopName] = useState("");
  const [ownerFullname, setOwnerFullname] = useState("");
  const [identificationCode, setIdentificationCode] = useState("");
  const [ruc, setRuc] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const inputClass = "input input--outline w-0 pdd-2 bkg-less-black color-black";

  const handleOnCloseForm = () => {
    setShowForm(false);
  }

  const handleOnChange = (e, set) => {
    if ((e.target.name === 'identification_code' || e.target.name === 'ruc' || e.target.name === 'phone') && isNaN(e.target.value))
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    else
      set(e.target.value);
  }

  const handleOnsubmit = async (e) => {
    e.preventDefault();
    handleOnSendRequest(shopName, ownerFullname, identificationCode, ruc, email, phone, address);

    try {
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className="application-form">
      <div className='container wrapper'>
        <div className='application-form__inner'>
          <form onSubmit={handleOnsubmit} className='pos-relative bkg-white pdd-5 br-m d-flex d-flex--column d--gap-2 w-0'>
            <button className='application-form__close button button--outline b-none' onClick={handleOnCloseForm}>
              <Icon path={mdiClose} />
            </button>

            <Image src="aqupe-min-logo.png" title={"aqupe"} classImg={"mrg-auto mw-1"} />

            <div className='ta-center mrg-b-4 fs-xl'>Solicitud de nueva tienda</div>

            <Field label={"Nombre de la tienda: "}>
              <input className={inputClass} type="text" name='shop_name' value={shopName} autoComplete='off' placeholder='Tienda abc...' required onChange={(e) => handleOnChange(e, setShopName)} />
            </Field>

            <Field label={"Propietario: "}>
              <input className={inputClass} type="text" name='owner_fullname' value={ownerFullname} autoComplete='off' placeholder='Nombres y Apellidos...' required onChange={(e) => handleOnChange(e, setOwnerFullname)} />
            </Field>

            <div className='d-flex d-flex--no-wrap d--gap-2 d--bottom'>
              <Field label={"Codigo de identificacion: "} addClass={"d-flex__column--4"}>
                <input className={inputClass} type="text" name='identification_code' value={identificationCode} autoComplete='off' placeholder='0000' maxLength={4} required onChange={(e) => handleOnChange(e, setIdentificationCode)} />
              </Field>

              <Field label={"Correo: "} addClass={"d-flex__column--8"}>
                <input className={inputClass} type="email" name='email' value={email} autoComplete='off' placeholder='abcde@gmail.com' required onChange={(e) => handleOnChange(e, setEmail)} />
              </Field>
            </div>

            <div className='d-flex d-flex--no-wrap d--gap-2 d--bottom'>
              <Field label={"RUC: "} addClass={"d-flex__column--4"}>
                <input className={inputClass} type="text" name='ruc' value={ruc} autoComplete='off' placeholder='00000000000' maxLength={11} required onChange={(e) => handleOnChange(e, setRuc)} />
              </Field>

              <Field label={"Telefono/Celular: "} addClass={"d-flex__column--8"}>
                <input className={inputClass} type="test" name='phone' value={phone} autoComplete='off' placeholder='987654321' maxLength={9} required onChange={(e) => handleOnChange(e, setPhone)} />
              </Field>
            </div>

            <Field label={"Dirección: "}>
              <input className={inputClass} type="text" name='address' value={address} autoComplete='off' placeholder='av. abcd...' required onChange={(e) => handleOnChange(e, setAddress)} />
            </Field>

            <button type='submit' className='button button--primary fs-n bkg-green'>Solicitar</button>
          </form>
        </div>
      </div>
    </div>
  );
}

const JoinUs = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="join-us">
      <div className="container wrapper">
        <Breadcrumbs />
        <div className="join-us__inner d-flex d--center mrg-b-15">
          <div className="join-us__header">
            <div className="join-us__heading h3 weight-2">
              Únete a la comunidad AQUPE en solo 4 pasos
            </div>
          </div>

          <div className="join-us__content d-flex d--between pdd-5 pdd-10!sm pdd-15!md">
            {
              content.map((card, key) => (
                <div className={`join-us__card join-us__card--${(key + 1)} d-flex__column--6 d-flex`} key={key}>
                  <div className="join-us__card-container d-flex d-flex--column d--middle d--center d--gap-2 mrg-5 mrg-10!md pdd-2 pdd-5!md bkg-white">
                    <div className="join-us__card-number h2 color-yellow weight-2">
                      {(key + 1)}
                    </div>
                    <div className="join-us__card-title h4 weight-2">
                      {card.title}
                    </div>
                    <div className="join-us__card-text">
                      {card.text}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          <button type="button" title="join-us-form" className="button button--primary p1" onClick={() => setShowForm(true)}>Llenar el formulario</button>
        </div>

        {
          showForm && <ApplicationForm setShowForm={setShowForm} />
        }
      </div>
    </div>
  );
}

export default JoinUs;