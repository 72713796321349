import { useState} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UseWindowSize from "../scripts/window-size";
import Carousel from './carousel';
import imgFn from '../scripts/image-functions';
import ls from "../services/ls";
import config from "../config/config";
import onImageRescaling from "../scripts/imageRescaling";

const RecentProducts = ({ heading, handleAddToCart }) => {
  const [productList, setProductList] = useState(config.recentProducts)
  const [controls, setControls] = useState(true);
  const [rebuildSlider, setRebuildSlider] = useState(false);
  const screen = UseWindowSize();

  const handleOnRemoveRecentProduct = (variant_id) => {
    const filterProductList = productList.filter((p) => p.first_variant._id !== variant_id);

    setProductList(filterProductList);
    ls.set("recentProducts", JSON.stringify(filterProductList));
    removeControls(filterProductList);
    onRebuildCarouselContent();
  }

  const onRebuildCarouselContent = () => {
    setRebuildSlider(true);

    setTimeout(() => {
      setRebuildSlider(false);
    }, 500);
  }

  const removeControls = (list) => {
    if (
      (list.length <= 6 && screen.width >= 1440) ||
      (list.length <= 4 && screen.width >= 1024) ||
      (list.length <= 3 && screen.width >= 750) ||
      (list.length < 2 && screen.width < 480)
      
    ) {
      setControls(false);
    } else {
      setControls(true);
    }
  }



  return (
    <div className="recent-products">
      {
        productList.length > 0 && (
          <div className="pdd-v-2">
            <h2 className='fs-b ft-upper'>{heading}</h2>

            <Carousel
              containerClass='recent-products__slider'
              controlContainerClass='recent-products__controls'
              controlsClass='recent-products__nav-control'
              v1440Items={6}
              v1024Items={4}
              v750Items={3}
              v480Items={2}
              vDefaultItems={1}
              speed={500}
              showControls={controls}
              rebuild={rebuildSlider}
              controlsSmall={true}>
              <ul className='recent-products__slider d-flex d-flex--no-wrap'>
                {
                  productList.map((product, index) => (
                    <li key={product._id} className={`d-flex d-flex--column d--between ${(index + 1) !== productList.length && "mrg-r-2"}`}>
                      <div className='b-n br-n bkg-white'>
                        <button
                          onClick={() => handleOnRemoveRecentProduct(product.first_variant._id)}
                          className="button button--outline b-none pdd-1 color-silver ta-right w-0 fs-c">
                          Eliminar
                        </button>

                        <a href={`/product/${product.first_variant.variant_name}/${product.first_variant._id}`}>
                          <div className='recent-products__tile-inner'>
                            <div className='fs-m fw-700 ta-center'>
                              {product.first_variant.brand}
                            </div>

                            <div className='mrg-auto mw-6 w-0 pdd-2 mh-5'>
                              <LazyLoadImage
                                onError={(e) => imgFn.onErrorLoadingImage(e)}
                                alt={product.first_variant.images[0].nameImage}
                                src={onImageRescaling(product.first_variant.images[0].imageUrl, 380)}   />              

                            </div>

                            <div
                              className='recent-products__product-title fs-n fw-700 ta-center'
                              title={`${product.first_variant.product_name} ${product.first_variant.variant_name}`}>
                              <p className='mrg-0'>{product.first_variant.product_name} {product.first_variant.product_name} {product.first_variant.product_name}{product.first_variant.variant_name}</p>
                            </div>

                            <div className='ta-center'>
                              {
                                product.first_variant.offer && (
                                  <div className='td-through'>S/{(product.first_variant.price).toFixed(2)}</div>
                                )
                              }

                              {
                                product.first_variant.offer ? (
                                  <div className='fs-m fw-700 color-primary'>S/
                                    {
                                      product.first_variant.offer.symbol === "%" ? (
                                        (product.first_variant.price - ((product.first_variant.offer.value / 100) * product.first_variant.price)).toFixed(2)
                                      ) : (
                                        (product.first_variant.price - product.first_variant.offer.value).toFixed(2)
                                      )
                                    }
                                  </div>

                                ) : (
                                  <div className='fs-m color-primary'>S/{(product.first_variant.price).toFixed(2)}</div>
                                )
                              }
                            </div>
                          </div>
                        </a>

                        <div className='pdd-2'>
                          <button
                            onClick={() => handleAddToCart(product.first_variant)}
                            className='button button--outline w-0'
                            type='button'
                            title='Agregar producto al carrito'>
                            Añadir al Carrito
                          </button>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </Carousel>
          </div>
        )
      }
    </div>
  );
}

export default RecentProducts;
