import { LazyLoadImage } from 'react-lazy-load-image-component'

const Image = ({ src, title, url, classImg }) => {
  return (
    <div className={`image ${classImg ? classImg : ''}`}>
      {url ? (
        <a href={url} className={`link__${title}`}>
          <LazyLoadImage
            src={require(`../assets/${src}`)}
            title={title}
            alt={title}
            effect='opacity'
          />
        </a>
      ) : (
        <div className={`image__container`}>
          <LazyLoadImage
            src={require(`../assets/${src}`)}
            title={title}
            alt={title}
            effect='opacity'
          />
        </div>
      )}
    </div>
  )
}

export default Image
