import ls from "../services/ls";
import config from "../config/config";

const recentProducts = (product) => {
  const { variants } = product;
  const { _id, brand, images, offer, product_id, product_name, variant_name, price } = variants[0];
  const { name, symbol, value } = offer ? offer : { name: null, symbol: null, value: null };
  const productList = config.recentProducts;

  const filterProductData = {
    _id: product_id,
    first_variant: {
      _id,
      product_id,
      brand,
      price,
      images,
      product_name,
      variant_name,
      offer: {
        name,
        symbol,
        value
      }
    }
  }

  if (productList.length > 0) {
    const findProduct = productList.find(p => p._id === filterProductData._id);

    if (!findProduct) {
      productList.push(filterProductData);

      if (productList.length > 10) {
        productList.shift();
      }

      ls.set("recentProducts", JSON.stringify(productList));
    }
  } else {
    productList.push(filterProductData);
    ls.set("recentProducts", JSON.stringify(productList));
  }
}

const storage = {
  recentProducts,
}

export default storage;
