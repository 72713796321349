const validateNumber = (e, set) => {
  const value = e.target.value;
  let validValue = value.replace(/[^0-9.]+/, '');

  const parts = validValue.split('.');
  if (parts.length > 2) {
    validValue = parts[0] + '.' + parts.slice(1).join('');
  }

  set(validValue);
}

const transform = (text) => {
  const lowercaseText = text.toLowerCase();
  const textSpaces = lowercaseText.replace(/ /g, '_');
  const cleanedText = textSpaces.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return cleanedText;
}

const getDate = (date) => {
  const objDate = new Date(date);

  const newDate = {
    year: objDate.getFullYear(),
    month: objDate.toLocaleString('es-ES', { month: '2-digit' }),
    day: objDate.toLocaleString('es-ES', { day: '2-digit' }),
    hours: objDate.toLocaleTimeString('es-ES', { hour: '2-digit' }),
    minutes: objDate.toLocaleTimeString('es-ES', { minute: '2-digit' }),
    seconds: objDate.toLocaleTimeString('es-ES', { second: '2-digit' }),
  }

  return newDate;
}

const formatDate = (date) => {
  const objDate = new Date(date);

  const day = objDate.getUTCDate();
  const month = objDate.getUTCMonth() + 1; // Sumar 1 porque enero es 0
  const year = objDate.getUTCFullYear();
  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  return formattedDate;
}

const redirectTo = (newVariable) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlValue = urlParams.get(newVariable);

  if (urlValue === 'checkout') {
    window.location.href = `/${urlValue}`;
  }
}

const getUrlValue = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const firstParamName = urlParams.keys().next().value;
  const firstParamValue = urlParams.get(firstParamName);

  return firstParamValue;
}

const setUrlValue = (newVariable, value) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  urlParams.set(newVariable, value);

  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
}

const updateUrlValue = (newVariable, value) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  urlParams.set(newVariable, value);

  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
}

const removeUrlValue = (variableToRemove) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  urlParams.delete(variableToRemove);

  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
}

const removeAllUrlValues = () => {
  const newUrl = `${window.location.pathname}`;
  window.history.replaceState(null, '', newUrl);
}

const showNotification = (text) => {
  const notification = document.querySelector(".notification");
  const notificationText = document.querySelector(".notification__text");

  if (notification && notificationText) {
    notificationText.textContent = text;
    notification.dataset.trigger = true;

    setTimeout(() => {
      notification.dataset.trigger = false;
    }, 2000);
  }
}

const common = {
  transform,
  validateNumber,
  getDate,
  formatDate,
  redirectTo,
  getUrlValue,
  setUrlValue,
  updateUrlValue,
  removeUrlValue,
  removeAllUrlValues,
  showNotification,
}

export default common;
