import { useState, useEffect } from 'react';
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Login from "./login";
import LoginMenu from './login-menu';
import Register from './register';
import RestorePassword from './restore-password';
import authFn from './authFunctions';
import ls from '../services/ls';

const Authentication = ({ innerClass = "", hasClose = true, onSessionChange }) => {
  const [update, setUpdate] = useState(false);
  const [isLogin, setIsLogin] = useState(ls.get("accountData") ? true : false);
  const [viewRegister, setViewRegister] = useState(false);
  const [restore, setRestore] = useState(false);

  const handleOnRestorePassword = () => {
    setRestore(true);
  }

  const handleOnShowRegister = () => {
    setViewRegister(true);
  }

  useEffect(() => {
    if (!update) {
      setIsLogin(ls.get("accountData") ? true : false);
    }
  }, [update]);

  return (
    <div className="auth">
      <div className={innerClass}>
        {
          hasClose && (
            <button onClick={authFn.closeMenu} className="button button--outline pdd-0 mrg-v-2 b-none" type="button" title="cerrar">
              <Icon path={mdiClose} size={1} />
            </button>
          )
        }

        {
          restore ? (
            <RestorePassword setRestore={setRestore} />
          ) : (
            viewRegister ? (
              <Register setViewRegister={setViewRegister} />
            ) : (
              !isLogin ? (
                <Login setUpdate={setUpdate} handleOnRestorePassword={handleOnRestorePassword} handleOnShowRegister={handleOnShowRegister} onSessionChange={onSessionChange }  />
              ) : (
                <LoginMenu setUpdate={setUpdate} onSessionChange ={onSessionChange } />
              )
            )
          )
        }
      </div>
    </div>
  );
}

export default Authentication;
