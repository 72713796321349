import { useEffect } from "react";
import common from "../../scripts/common";
import ls from "../../services/ls";
import mail from "../../services/mail";
import template from "../../templates/template";
import imgFn from "../../scripts/image-functions";
import onImageRescaling from "../../scripts/imageRescaling";

const ChkOrderDetail = ({ order }) => {
  const date = common.getDate(order.created_at);

  const handleOnPrintDoc = () => {
    window.print();
  };

  const handleOnAcept = () => {
    ls.clear("cart");
    window.location.href = "/";
  };

  useEffect(() => {
    const content = document.querySelector(
      ".chk-order-detail__content"
    ).innerHTML;

    mail(
      order.client.user.email,
      `NUEVO PEDIDO 🛍️ - Nro. AQUPE-P-0000000${order.order_number}`,
      "",
      template.orderConfirmation(content)
    ).then((response) => {
      console.log(response);
    });
  }, [order]);

  return (
    <div className="chk-order-detail">
      <div className="chk-order-detail__content mw-18 w-0 bkg-white pdd-2 mrg-auto b-m br-n">
        <div className="">
          <div className="bkg-primary color-white d--between d-flex pdd-2">
            <h1>ORDEN DE COMPRA</h1>
            <div className="d--gap-1 d--middle d-flex fs-m">
              <p className="mrg-0">Nro de orden:</p>
              <div className="">
                <span className="">00000{order.order_number}</span>
              </div>
            </div>
            {/* <img className="w-2" src={logo} alt="logo de la tienda" /> */}
          </div>

          <div className="d--right d-grid d-grid__row--2fr ta-right mrg-t-2">
            <div className="d--gap-1 d--middle d-flex">
              <p className="mrg-0">Fecha:</p>
              <div className="">
                <span className="mrg-0">
                  {`${date.day}/${date.month}/${date.year}`}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-grid d-grid__column--2fr">
          <div className="d-flex">
            <h2 className="fs-b mrg-v-3 td-underline">Datos del cliente</h2>
            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Estado:</p>
              <div className="">
                <span className="">Pendiente</span>
              </div>
            </div>

            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Nombre o razon social:</p>
              <div className="">
                <span className="">
                  {order.client.user.name + " " + order.client.user.last_name}
                </span>
              </div>
            </div>

            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Telefono:</p>
              <div className="">
                <span className="">{order.contact_number || "000000000"}</span>
              </div>
            </div>
            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Correo electronico:</p>
              <div className="">
                <span className="">{order.client.user.email}</span>
              </div>
            </div>

            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Metodo de pago:</p>
              <div className="">
                <span className="">
                  {order.client.payment_method.method +
                    "/" +
                    order.client.payment_method.type}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <h2 className="fs-b mrg-v-3 td-underline">Dirección de envío</h2>
            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Direccion:</p>
              <div className="">
                <span className="mrg-l-2">
                  {order.delivery_address.full_address}
                </span>
              </div>
            </div>
            <div className="d--gap-1 d-flex d-flex--no-wrap w-0">
              <p className="mrg-0 fw-600">Referencia:</p>
              <div className="">
                <span className="">{order.delivery_address.reference}</span>
              </div>
            </div>
            <div className="d--gap-1 d--middle d-flex w-0">
              <p className="mrg-0 fw-600">Ubigeo:</p>
              <div className="">
                <span className="">{order.delivery_address.ubigeo}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mrg-t-8">
          <h2 className="fs-l mrg-t-4 td-underline">Lista de Productos:</h2>
          <table className="mrg-t-5  orders-table">
            <thead>
              <tr>
                <th className="w-2">Nro</th>
                <th className="w-3">Articulos</th>
                <th className="w-2">Cantidad</th>
                <th className="w-2">Precio Unitario</th>
                <th className="w-2">Precio Total</th>
              </tr>
            </thead>
            <tbody>
              {order.products.map((item,index) => (
                <tr key={item.variant._id}>
                  <td className="ta-center">
                    <p>000{index+1}</p>
                  </td>
                  <td className="ta-center">
                    <div className="d--around d-flex">
                    <img
                      className="w-1"
                      src={onImageRescaling(item.variant.images[0].imageUrl, 200)}                
                      alt={item.variant.variant_name}
                      onError={(e) => imgFn.onErrorLoadingImage(e)}
                    />
                    <p>{item.variant.variant_name} {item.variant.product_code}</p>
                    </div>
                   
                  </td>
                  <td className="ta-center">{item.quantity}</td>
                  <td className="ta-right">S/{item.price.toFixed(2)}</td>
                  <td className="ta-right">
                    S/{(item.price * item.quantity).toFixed(2)}
                  </td>
                </tr>
              ))}
              {order.coupon !== "" && (
                <tr>
                  
                  <td colSpan="4" className="ta-right" style={{ width: '84%' }} >
                    <strong>Descuento por ({order.coupon})</strong>
                  </td>
                  <td className="ta-right">
                    <p className="color-red">
                      - S/
                      {(
                        (order.total_price *
                          parseFloat(order.coupon.replace("%", ""))) /
                        100
                      ).toFixed(2)}
                    </p>
                  </td>
                </tr>
              )}

              {order.coupon !== "" && (
                <tr>
                 
                  <td colSpan="4" className="ta-right" style={{ width: '84%' }}>
                    <strong>Subtotal:</strong>
                  </td>
                  <td className="ta-right">
                    <p>S/
                      {Number(
                        (
                          order.total_price *
                          (1 - parseFloat(order.coupon.replace("%", "")) / 100)
                        ).toFixed(2)
                      )}
                    </p>
                  </td>
                </tr>
              )}

              <tr>
                
              <td colSpan="4" className="ta-right" style={{ width: '84%' }}>
                  <strong>Costo de Envio</strong>
                </td>
                <td className="ta-right">
                  <p>S/{order.shipping_cost.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
              <td colSpan="4" className="ta-right" style={{ width: '84%' }}>
                  <strong>Total a pagar</strong>
                </td>
                <td className="ta-right">
                  <strong>S/{order.total_price.toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="d-flex d--center d--gap-2 mw-18 w-0 pdd-2 mrg-auto">
        <button
          className="button button--outline bc-black color-black"
          title="aceptar"
          type="button"
          onClick={handleOnAcept}
        >
          Regresar a Home
        </button>
        <button
          className="button button--primary bkg-green-2"
          title="imprimir"
          type="button"
          onClick={handleOnPrintDoc}
        >
          Imprimir
        </button>
      </div>
    </div>
  );
};

export default ChkOrderDetail;
