import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/_index.scss";
import authFn from "./auth/authFunctions";
import serverResources from "./services/server-resources";
import userTraking from "./services/tranking";

// Principal Elements
import DinamycRoutes from "./router/router";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<DinamycRoutes />);

authFn.verifySession();
serverResources.get();
userTraking();
