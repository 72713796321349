import { useState, useEffect } from 'react';
import Field from "../../../snippets/field";
import Select from "../../../snippets/select";

const ChkPaymentForm = () => {
  const inputClass = "input input--outline bkg-white w-0 pdd-1 b-m";
  const labelClass = "mrg-0 mrg-b-1 fs-s color-white";
  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const years = Array.from({ length: 18 }, (_, index) => new Date().getFullYear() + index);
  const [monthSelected, setMonthSelect] = useState("mes");
  const [yearSelected, setYearSelect] = useState("año");

  return (
    <form className="chk-payment-form bkg-blue pdd-2">
      <div className="d-flex d--gap-9">
        <Field label={"Número de tarjeta"} addClass={"mw-5 w-0"} addClassLabel={labelClass}>
          <input className={inputClass} type="text" placeholder="" maxLength={"16"} />
        </Field>

        <div className="d-flex mw-8 w-0">
          <Field label={"Fecha de caducidad"} addClass={"mw-6 w-0"} addClassLabel={labelClass} addClassContent={"d-flex d--gap-2"}>
            <Select addClass={`${inputClass} mw-2 w-0`} addClassLabel={"pdd-v-0"} value={monthSelected} setValue={setMonthSelect} options={months} />
            <Select addClass={`${inputClass} mw-3 w-0`} addClassLabel={"pdd-v-0"} value={yearSelected} setValue={setYearSelect} options={years} />
          </Field>

          <Field label={"CCV"} addClass={"mw-1 w-0"} addClassLabel={labelClass}>
            <input className={`${inputClass} ta-center`} type="text" placeholder="cvv" maxLength={"3"} />
          </Field>
        </div>
      </div>

      <div className='d-flex d-flex--column d--gap-2 pdd-t-2'>
        <p className='mrg-t-4 mrg-b-0 fs-m color-white'>INFORMACIÓN DE FACTURACIÓN</p>

        <div className='d-flex d--gap-4 d-flex--no-wrap'>
          <Field label={"Nombre"} addClass={"mw-5 w-0"} addClassLabel={labelClass}>
            <input className={`${inputClass}`} type="text" />
          </Field>

          <Field label={"Apellidos"} addClass={"mw-5 w-0"} addClassLabel={labelClass}>
            <input className={`${inputClass}`} type="text" />
          </Field>
        </div>

        <div className='d-flex d--gap-4 d-flex--no-wrap'>
          <Field label={"Dirección de facturación"} addClass={"mw-8 w-0"} addClassLabel={labelClass}>
            <input className={`${inputClass}`} type="text" />
          </Field>

          <Field label={"Telefono"} addClass={"mw-4 w-0"} addClassLabel={labelClass}>
            <input className={`${inputClass}`} type="text" />
          </Field>
        </div>

        <div className='d-flex color-white'>
          <input type="checkbox" name="" id="" />
          <label htmlFor="">Guardar mi información de pago para facilitar el proceso de pago la próxima vez</label>
        </div>
      </div>
    </form>
  );
}
export default ChkPaymentForm;
