import Image from "../snippets/image";
import session from "../services/session";
import config from "../config/config";

const LoginMenu = ({ setUpdate,onSessionChange  }) => {
  const ctaClass = "button button--primary bkg-text-label w-0";
  const userData = config.accountData ? config.accountData.user : {};

  const handleOnSessionClose = () => {
    setUpdate(true);

    setTimeout(() => {
      setUpdate(false);
      session.signOff();
      onSessionChange();

      if (window.location.href.includes("profile")) {
        window.location.href = "/";
      }
    }, 500);
  }

  return (
    <div className="login-menu">
      <div className="mrg-b-5">
        <div className="d-flex d--middle d--center d--gap-1">
          <span className="ta-center w-0">Bienvenid(a/o)</span>
          <span className="fs-m fw-700">{userData.name} {userData.last_name}</span>

          {
            userData.nickname && <span className="color-silver">({userData.nickname})</span>
          }
        </div>
      </div>

      <div className="pdd-t-10 pdd-l-6">
        <div className="login-menu__image pdd-h-1">
          <Image src={"profile.jpg"} title={"Imagen de perfil"} classImg={"mw-4 w-0 b-l br-circle of--hidden"} />
        </div>

        <div className="d-flex mw-8 w-8 d--center d--gap-3 ta-center pdd-t-1">
          <a className={`${ctaClass} mw-6`} href="/profile?view=informacion">Ver Perfil</a>
          <a className={`${ctaClass} mrg-l-4 pdd-l-4 ta-right`} href="/profile?view=pedidos">Estado de mis pedidos</a>
          <button
            onClick={handleOnSessionClose}
            className={`${ctaClass} mrg-l-6 mw-6`}
            type="button"
            title="cerrar sesión">
            Cerrar Sesión
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginMenu;
