import ls from "../services/ls";

// SERVER
const serverUrl = "https://backend-two.onrender.com/" // process.env.SERVER_URL || "https://backend-two.onrender.com/";
const secretKey = process.env.SECRET_KEY || "aqupe_v1";
const ssToken = ls.get("ssToken") || null;
const basicToken = ls.get("basicToken") || null;
const imageBaseUrl = process.env.IMAGE_BASE_URL || "https://res.cloudinary.com/serveraqupe/image/upload/v1710464225/";

// SESSION
const sessionId = ls.get("sessionId") || null;
const accountData = ls.get("accountData") ? JSON.parse(ls.get("accountData")) : null;

// CART
const cartVariants = ls.get('cart') ? JSON.parse(ls.get('cart')) : { cart: [], subTotal: 0 };
// LIST FAVORITES
const favoriteVariants = ls.get('favorites') ? JSON.parse(ls.get('favorites')) : { favorites: []};

// BASIC INFORMATION
const listCategories = ls.get("listCategories") ? JSON.parse(ls.get("listCategories")) : [];
const recentProducts = ls.get("recentProducts") ? JSON.parse(ls.get("recentProducts")) : [];

const config = {
  // External resources
  serverUrl,
  secretKey,
  ssToken,
  basicToken,
  imageBaseUrl,

  // Session
  sessionId,
  accountData,

  // Cart
  cartVariants,

  // favorites
  favoriteVariants,

  // Basic Information
  listCategories,
  recentProducts,

}

export default config;
