import { useEffect, useState } from "react";
import IconProfile from "../snippets/icon-profile";
import IconHeart from "../snippets/icon-heart";
import IconCart from "../snippets/icon-cart";
import Image from "../snippets/image";
import { SideMenu } from "./side-menu";
import Icon from "@mdi/react";
import { mdiCloseBox, mdiMenu } from "@mdi/js";
import Search from "../snippets/search";
import Cart from "./cart";
import FavoritesList from "./favoritesList";
import CartNotification from "../snippets/cart-notification";
import Authentication from "../auth/authentication";
import Notification from "../snippets/notification";
import UseWindowSize from "../scripts/window-size";
import NavBar from "./navbar/navbar";
import Modal from "./modal";
import ls from "../services/ls";
import config from "../config/config";
import { useLocation } from "react-router-dom";


const setHeight = () => {
  setTimeout(() => {
    const header = document.querySelector("#header");
    const heightHeader = document.querySelector(".header");

    if (header && heightHeader) {
      header.style.height = heightHeader.clientHeight + 1 + "px";
    }
  }, 100);
};

const Header = () => {
  const screen = UseWindowSize();
  const [verifySession, setVerifySession] = useState(ls.get("accountData") ? true : false);
  const [showNavbarModal, setShowNavbarModal] = useState(false);
  const [userData, setUserData] = useState(config.accountData);
  const [showSearch, setShowSearch] = useState(true); // Estado para controlar si se muestra el componente de búsqueda
  const location = useLocation();


  const handleOnCloseNavbarModal = (e) => {
    if (e.target.dataset.modalName === "modal") setShowNavbarModal(false);
  };


  useEffect(() => {
    // Verificar si la URL es de una página de tienda
    const isShopPage = window.location.href.includes("/shops/tienda/");
    // Actualizar el estado para mostrar o no el componente de búsqueda
    setShowSearch(!isShopPage);
  }, [location]);

  useEffect(() => {
    setHeight();
  }, [screen]);

 
  const onSessionChange = ()=>{
    const accountData = JSON.parse(ls.get("accountData"));
      if (accountData) {
        setUserData(accountData)
        // Si accountData existe, establecer verifySession en true 
        setVerifySession(true);

      } else {
        // Si accountData no existe, establecer verifySession en false
        setVerifySession(false);
      }
  }

  return (
    <header id="header">
      <div className="header">
        {screen.width >= 480 ? (
          <>
            <CartNotification />
            <Notification />
            <input
              className="announcement-bar-checkbox"
              type="checkbox"
              id="chk_announcement-bar"
              title="checkbox"
            />
            <div className="announcement-bar">
              <div className="container wrapper">
                <div className="announcement-bar__inner">
                  <div className="announcement-bar__text">
                    DELIVERY GRATIS EN TODAS LAS COMPRAS MAYORES A S/199.00
                  </div>

                  <label
                    htmlFor="chk_announcement-bar"
                    className="announcement-bar__close"
                    onClick={setHeight}
                  >
                    <Icon path={mdiCloseBox} size={1.5} />
                  </label>
                </div>
              </div>
            </div>

            <div className="container wrapper">
              <div className="header__container">
                <div className="header__section header__section--left">
                  <Image
                    src={"max-logo.png"}
                    title={"aqupe"}
                    url={"/"}
                    classImg={"mw-4"}
                  />
                </div>

                <div className="header__section header__section--middle mrg-l-2 w-15!lg">
                {showSearch && <Search />}

                </div>

                <div className="header__section header__section--right">
                  <SideMenu
                    containerClass="mw-10 w-0"
                    name={"profile"}
                    isLink={true}
                    url={""}
                    label={
                      verifySession && userData
                        ? `Hola, ${userData.user.name}`
                        : "Inicia sesión"
                    }
                    IconLink={IconProfile}
                    cursor={true}
                    side={"right"}
                  >
                    <Authentication onSessionChange ={onSessionChange } />
                  </SideMenu>

                  <SideMenu
                    name={"favorites"}
                    isLink={true}
                    url={""}
                    label={"Favoritos"}
                    IconLink={IconHeart}
                    cursor={true}
                    side={"right"}
                  >
                    <FavoritesList  verifySession={verifySession} />
                  </SideMenu>

                  <SideMenu
                    name={"cart"}
                    isLink={true}
                    url={""}
                    label={"Carro de compras"}
                    IconLink={IconCart}
                    cursor={true}
                    side={"right"}
                  >
                    <Cart.SideCart verifySession={verifySession} />
                  </SideMenu>
                </div>
              </div>

              <div className="pdd-v-2">
                <NavBar />
              </div>
            </div>
          </>
        ) : (
          <div className="container wrapper">
            <div className="header__container">
              <div className="header__section header__section--left d--around">
                <div className="navbar__option">
                  <button
                    onClick={() => setShowNavbarModal(true)}
                    className="button button--primary d-flex d--center d--middle d--gap-2 bkg-light-black fw-700 w-0"
                    type="button"
                    title="ver opciones"
                  >
                    <Icon path={mdiMenu} size={1} />
                  </button>
                </div>

                <Modal
                  modalInnerClass="w-0 h-0 bkg-silver"
                  iconClass="color-white"
                  showModal={showNavbarModal}
                  onClose={handleOnCloseNavbarModal}
                >
                  <NavBar />
                </Modal>

                <Image
                  src={"loading.png"}
                  title={"aqupe"}
                  url={"/"}
                  classImg={"w-1"}
                />
                <div className="header__section header__section--right">
                  <SideMenu
                    containerClass="mw-10 w-0"
                    name={"profile"}
                    isLink={true}
                    url={""}
                    label={
                      verifySession
                        ? `Bienvenido ${userData.user.name} ${userData.user.last_name} `
                        : "Inicia sesión"
                    }
                    IconLink={IconProfile}
                    cursor={true}
                    side={"right"}
                  >
                    <Authentication onSessionChange={onSessionChange} />
                  </SideMenu>

                  <SideMenu
                    name={"favorites"}
                    isLink={true}
                    url={""}
                    label={"Favoritos"}
                    IconLink={IconHeart}
                    cursor={true}
                    side={"right"}
                  >
                    <FavoritesList verifySession={verifySession} />
                  </SideMenu>

                  <SideMenu
                    name={"cart"}
                    isLink={true}
                    url={""}
                    label={"Carro de compras"}
                    IconLink={IconCart}
                    cursor={true}
                    side={"right"}
                  >
                    <Cart.SideCart verifySession={verifySession} />
                  </SideMenu>
                </div>
              </div>

              <div className="header__section header__section--middle">
              {showSearch && <Search />}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
