import { useState, useEffect } from 'react';
import Field from "../../../snippets/field";
import Select from "../../../snippets/select";
import common from '../../../scripts/common';
import variables from '../../variables';

const ProfileAddressForm = ({ mainClass, heading, address, set, setDisabledBtn }) => {
  const inputClass = "input input--outline w-0 bkg-white";
  const fieldClass = "pdd-h-3 mrg-b-4";
  const fieldSelectClass = "d-flex__column--4 pdd-h-3";
  const fieldLabelClass = "mrg-b-1 color-silver";
  const selectClass = "bkg-white";
  const json_departments = variables.peru.departments.departments;
  const json_provinces = variables.peru.provinces.provinces;
  const json_districts = variables.peru.districts.districts;
  const [department, setDepartment] = useState(address.department ? address.department : json_departments[0]);
  const [jsonProvinces, setJsonProvinces] = useState(address.province ? json_provinces[common.transform(address.province)] : json_provinces.amazonas);
  const [province, setProvince] = useState(address.province ? address.province : json_provinces.amazonas[0]);
  const [jsonDistricts, setJsonDistricts] = useState(json_districts[common.transform(address.province ? address.province : json_provinces.amazonas[0])].map(p => p.name));
  const [district, setDistrict] = useState(address.district ? address.district : json_districts[common.transform(json_provinces.amazonas[0])][0].name);
  const [ubigeo, setUbigeo] = useState(json_districts[common.transform(address.province ? address.province : json_provinces.amazonas[0])][0].ubigeo);
  const [fullAddress, setFullAddress] = useState(address.full_address);
  const [referenceAddress, setReferenceAddress] = useState(address.reference);

  useEffect(() => {
    if (department !== "seleccionar") {
      setJsonProvinces(json_provinces[common.transform(department)]);
      setProvince(address.province ? address.province : json_provinces[common.transform(department)][0]);
    }
  }, [address, department, json_provinces]);

  useEffect(() => {
    if (province !== "seleccionar") {
      setJsonDistricts(json_districts[common.transform(province)].map(p => p.name));
      setDistrict(json_districts[common.transform(province)][0].name);
      setUbigeo(json_districts[common.transform(province)][0].ubigeo);
    }
  }, [address, province, json_districts]);

  useEffect(() => {
    if (province !== "seleccionar") {
      setDistrict(json_districts[common.transform(province)].filter((d) => d.name === district).map((d) => d.name)[0]);
      setUbigeo(json_districts[common.transform(province)].filter((d) => d.name === district).map((d) => d.ubigeo)[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district, json_districts]);

  useEffect(() => {
    const data = {
      full_address: fullAddress,
      department: department,
      province: province,
      district: district,
      ubigeo: ubigeo,
      reference: referenceAddress,
    }

    set(data);
  }, [set, fullAddress, department, province, district, ubigeo, referenceAddress]);

  useEffect(() => {
    setDepartment(address.department ? address.department : "seleccionar");
    setProvince(address.province ? address.province : "seleccionar");
    setDistrict(address.district ? address.district : "seleccionar");
  }, [address]);

  return (
    <div className={`profile-address-form b-l bc-background pdd-3 ${mainClass}`}>
      <p className='pdd-h-3 mrg-0 mrg-b-4 fs-m'>{heading}</p>

      <div className="d-flex">
        <Field label={"Dirección completa:"} addClass={`${fieldClass} d-flex__column--8`} addClassLabel={fieldLabelClass}>
          <input className={inputClass} type="text" placeholder="direccion..." value={fullAddress} onChange={(e) => setFullAddress(e.target.value)} />
        </Field>

        <Field label={"Referencia:"} addClass={`${fieldClass} d-flex__column--4`} addClassLabel={fieldLabelClass}>
          <input className={inputClass} type="text" placeholder="referencia..." value={referenceAddress} onChange={(e) => setReferenceAddress(e.target.value)} />
        </Field>
      </div>

      <div className="d-flex">
        <Field label={"Departamento:"} addClass={`${fieldSelectClass}`} addClassLabel={fieldLabelClass}>
          <Select
            addClass={selectClass}
            name={'department'}
            options={json_departments}
            value={department}
            setValue={setDepartment}
            setDisableOption={setDisabledBtn} />
        </Field>

        <Field label={"Provincia:"} addClass={`${fieldSelectClass}`} addClassLabel={fieldLabelClass}>
          <Select
            addClass={selectClass}
            name={'province'}
            options={jsonProvinces}
            value={province}
            setValue={setProvince}
            setDisableOption={setDisabledBtn} />
        </Field>

        <Field label={"Distrito:"} addClass={`${fieldSelectClass}`} addClassLabel={fieldLabelClass}>
          <Select
            addClass={selectClass}
            name={'district'}
            options={jsonDistricts}
            value={district}
            setValue={setDistrict}
            setDisableOption={setDisabledBtn} />
        </Field>
      </div>
    </div>
  );
}

export default ProfileAddressForm;