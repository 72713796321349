import React from 'react'
import imgFn from '../scripts/image-functions';
import onImageRescaling from '../scripts/imageRescaling';


function ImageMosaic({ images, onMosaicImageMouseOver }) {

  return (
    <>
      {images.map((image, index) => (
        <img className='b-xs h-1' 
          style={{objectFit: 'contain'}}
          key={index}
          src={onImageRescaling(image.imageUrl, 200)}                
          alt={`Variante ${index + 1}`}
          onError={(e) => imgFn.onErrorLoadingImage(e)}

          onMouseOver={() => onMosaicImageMouseOver(image.imageUrl)}
        />
      ))}
    </>
  )
}

export default ImageMosaic
