import React, { useState, useEffect } from 'react'
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const FavoritesList = ({hasClose = true,verifySession }) => {

  const [cartNotEmpty, setCartNotEmpty] = useState(false);


  const closeMenu = () => {
    const hideMenu = document.querySelector('.side-menu__favorites  ');
    const menuContainer = hideMenu.querySelector('.side-menu__container');
  
    if (hideMenu && menuContainer) {
      hideMenu.classList.add('hidden');
      menuContainer.removeAttribute('style');
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      const sideCart = document.querySelector('.favorites-list__products');
      if (sideCart && sideCart.hasChildNodes()) {
        setCartNotEmpty(true);
      } else {
        setCartNotEmpty(false);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='d-flex d--gap-2 mw-10 d--center'>
       {
          hasClose && (
            <button onClick={closeMenu} className="b-none button button--outline d--right d-flex mrg-v-2 pdd-0 w-0" type="button" title="cerrar">
              <Icon path={mdiClose} size={1} />
            </button>
          )
        }
      <div className='fs-b fw-700 w-0'>Lista de favoritos</div>

      {!verifySession &&
      <div className='fs-n'>
        <a href="/login" className='td-underline'>Inicia sesión</a>
        <span> para acceder a tu carro de compras desde cualquier dispositivo</span>
      </div>
       } 
      <div className='fs-m w-0'>
        Productos favoritos: <span className='favorites-list__quantity'> </span>
      </div>

      <div className='d--gap-1 d-grid d-grid__column--2fr!lg favorites-list__products'> </div>
      
      {!cartNotEmpty && (
        <p className='w-0'>No hay productos en la lista de Favoritos</p>
      )}
      
    </div>
  )
}

export default FavoritesList
