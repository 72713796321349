import Loading from '../../snippets/loading';

const ChkResumeOffers = ({ handleOnSearchAndValidateOffer, isLoading, couponInputValue, setCouponInputValue, offerValue }) => {
  return (
    <div className="chk-resume-offers pdd-v-2">
      <div>
        <p className="mrg-0 mrg-b-2 fw-700">Cupon: </p>

        <div className="d-grid d-grid__column--2fa d--gap-1">
          <input
            onChange={(e) => setCouponInputValue(e.target.value)}
            value={couponInputValue}
            className="input w-0"
            type="text"
            name="coupon"
            placeholder="cupon..."
            title="Cupon" />

          <button onClick={handleOnSearchAndValidateOffer} type='button' title='validar' className="button button--outline bc-green-2 color-green-2 w-2">
            {
              isLoading ? (
                <Loading imgHeight={"16px"} />
              ) : (
                "validar"
              )
            }
          </button>
        </div>

        {
          offerValue.state === "unavailable" && <p className='mrg-b-0 color-red'>Cupon no valido!</p>
        }
      </div>

      {
        offerValue.state === "available" && (
          <div className="chk-resume-offers__promotion pdd-t-3">
            <div className='d-flex d-flex--column d--gap-1'>
              <span>Valor:</span>
              <span>Subtotal con cupon:</span>
            </div>
            <div className="d-flex d-flex--column d--gap-1 ta-right">
              <span>- {offerValue.data.value}</span>
              <span>S/{(offerValue.data.amount).toFixed(2)}</span>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default ChkResumeOffers;
