import { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiNotEqualVariant } from '@mdi/js';
import Field from "../snippets/field";
import Loading from '../snippets/loading';
import authFn from "./authFunctions";

const RestorePassword = ({ setRestore }) => {
  const inputClass = "input input--outline b-s w-0";
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);

  const handleOnFindUsername = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(false);

    authFn.findUsername(username).then(() => {
      setMessage(true);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      setError(true);
    });
  }

  return (
    <div className="restore-password">
      <h3 className='ta-center'>Restaurar contraseña</h3>

      <form onSubmit={handleOnFindUsername}>
        <Field label={"Ingrese su usuario: "} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input className={inputClass} type="text" placeholder="usuario..." value={username} onChange={(e) => setUsername(e.target.value)} required />
        </Field>

        <div className='d-flex d--gap-2'>
          <button
            className='button button--outline bkg-text-label color-white b-none fw-700 w-0'
            type="submit"
            title='validar'>
            {
              isLoading ? <Loading imgHeight='25' /> : "Validar usuario"
            }
          </button>

          <button
            onClick={() => setRestore(false)}
            className='button button--outline w-0'
            type="button"
            title='cancelar'>
            Cancelar
          </button>
        </div>
      </form>

      {
        message && (
          <div>
            <p className='d-flex d--middle d--center d--gap-1 color-green'>
              Nombre de usuario encontrado
              <Icon path={mdiCheckCircle} size={1} />
            </p>

            <p className='pdd-2 bkg-whatermark color-silver'>
              Para completar el proceso, por favor revisa tu correo electrónico y haz clic en el enlace de validación que te hemos enviado.
            </p>
          </div>
        )
      }

      {
        error && (
          <p className='d-flex d--middle bkg-whatermark d--center d--gap-1 color-red'>
            Usuario no encontrado
            <Icon path={mdiNotEqualVariant} size={1} />
          </p>
        )
      }
    </div>
  );
}

export default RestorePassword;
