import api from "./api";
import ls from "./ls";
import config from "../config/config";

const get = () => {
  return new Promise((resolve, reject) => {
    const listCategories = config.listCategories;

    api
      .get(`api/serverResources/initialData`)
      .then((response) => {
        ls.set("basicToken", response.data.public_token);

        if (listCategories.length === 0) {
          ls.set(
            "listCategories",
            JSON.stringify(response.data.list_categories)
          );
        }

        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const set = () => {
  // const ipAddress = await (
  //   await fetch("https://api64.ipify.org?format=json")
  // ).json();
  // const browser = navigator.userAgent;
  // const userTracking = {
  //   ip: ipAddress.ip,
  //   browser: browser,
  // };
  // if (Object.keys(userTracking).length > 0) {
  //   return new Promise((resolve, reject) => {
  //     api
  //       .post(`api/serverResources/userTraking`, userTracking)
  //       .then((response) => {
  //         console.log(response.data);
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }
};

const serverResources = {
  get,
  set,
};

export default serverResources;
