import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const Modal = ({ modalClass = "", modalInnerClass = "", iconClass = "", showModal = false, onClose = () => { }, children }) => {
  return (
    showModal && (
      <div className={`modal ${modalClass}`} data-modal-name="modal" onClickCapture={onClose}>
        <div className={`modal__inner ${modalInnerClass}`}>
          <button
            onClickCapture={onClose}
            className="modal__close button button--outline pdd-0 b-none color-black"
            type="button"
            title="cerrar"
            data-modal-name="modal">
            <Icon path={mdiClose} size={1} className={iconClass} />
          </button>

          <div className="modal__content">
            {children}
          </div>
        </div>
      </div >
    )
  )
}

export default Modal;
