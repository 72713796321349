import imgFn from "../../../scripts/image-functions";
import onImageRescaling from "../../../scripts/imageRescaling";
import ProgressBar from "../../../snippets/progress-bar";

const ProfileOrderItem = ({ order, handleViewDetail }) => {
  //ejemplo random de progreso de envio

  const statusOptions = ["Pendiente", "Alistándose", "Enviado", "Recibido"];
  const randomIndex = Math.floor(Math.random() * statusOptions.length);
  const randomStatus = statusOptions[randomIndex];

  let percentage = 0;
  let barColor = "";

  switch (randomStatus) {
    case "Pendiente":
      percentage = 25;
      barColor = "bkg-yellow";
      break;
    case "Alistándose":
      percentage = 50;
      barColor = "bkg-yellow";
      break;
    case "Enviado":
      percentage = 75;
      barColor = "bkg-green";
      break;
    case "Recibido":
      percentage = 100;
      barColor = "bkg-green";
      break;
    default:
      percentage = 0;
      barColor = "bkg-yellow";
  }
  ///////////////////////

  return (
    <tr
      className="b-b-s bc-black order__item cr--pointer"
      onClick={() => handleViewDetail(order.status,order.order_number, order.products)}
    >
      <td className="ta-center" width={"15%"}>
        N°{order.order_number}
      </td>

      <td width={"60%"} className="order__item-content">
        {order.products.map((product) => (
          <div
            className="d--gap-1 d--middle d-flex order__item-detail pdd-t-2"
            key={product.variant._id}
          >
            <img
              className="w-1"
              src={onImageRescaling(product.variant.images[0].imageUrl, 200)}                
              alt={product.variant.variant_name}
              onError={(e) => imgFn.onErrorLoadingImage(e)}
            />{" "}
            {product.variant.variant_name}
          </div>
        ))}
      </td>
      <td className="d--middle d-flex">
        {order.products.map((product, index) => (
          <div
            className="d--center d--middle d-flex h-1 ta-center pdd-t-2 w-0 mrg-b-3"
            key={index}
          >
            <b>(x{product.quantity})</b>
          </div>
        ))}
      </td>

      {/* <div className="d-flex d--center d--middle d--betwee d-flex--column">
          <div>{order.status[0].type === "pending" && "Pendiente"}</div>

          <ProgressBar
            containerClasses={"bkg-black w-0 mw-2"}
            height={10}
            percentage={(1 / 5) * 100}
            barColor={"bkg-yellow"}
          />
        </div> */}
      <td width={"25%"}>
        <div className="d-flex d--center d--middle d--betwee d-flex--column">
          <div>{randomStatus}</div>

          <ProgressBar
            containerClasses={"bkg-black w-0 mw-2"}
            height={10}
            percentage={percentage}
            barColor={barColor}
          />
        </div>
      </td>
    </tr>
  );
};

export default ProfileOrderItem;
