import { useState, useEffect } from "react";
import ls from "../../services/ls";
import Icon from "@mdi/react";
import { mdiArrowLeft as iconBack, mdiChevronRightBox } from "@mdi/js";
import ChkSections from "./chk-sections";
import ChkContent from "./chk-content";
import ChkResume from "./chk-resume";
import ChkPayment from "./chk-payment";
import ChkOrderDetail from "./chk-order-detail";
import popup from "../../snippets/popup";
import Image from "../../snippets/image";
import variables from "../variables";
import checkoutFn from "./checkoutFunctions";
import config from "../../config/config";

const sectionHeaders = [
  "REVISA Y EDITA TUS PRODUCTOS",
  "MÉTODO DE PAGO",
  "DETALLE DEL PEDIDO",
];

const Checkout = () => {
  const [cartVariants, setCartVariants] = useState(config.cartVariants);
  const [offerValue, setOfferValue] = useState({
    state: "",
    data: { amount: 0, value: "" },
  });
  const [subTotalCosts, setSubTotalCosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [paymentSelected, setPaymentSelected] = useState(
    variables.paymentOptions[0].name
  );
  const [info, setInfo] = useState("");
  const [sendTo, setSendTo] = useState("main");
  const [order, setOrder] = useState("");
  const [dataPopup, setDataPopup] = useState({
    showPopup: false,
    textClass: "",
    text: "",
  });
  const btnClass =
    "button button--outline d-flex d--middle pdd-0 fs-s bc-white color-light-black br-none";
  const logoClass = "button button--outline b-none br-none mw-3 w-0 pdd-0";
  const [update, setUpdate] = useState(false);
  const [rucInfo, setRucInfo] = useState("");

  const onChangePage = (position) => {
    setPage(position);
  };

  const onShowPopup = (text, textClass = "fs-l color-text-label") => {
    setDataPopup({ showPopup: true, textClass: textClass, text: text });

    setTimeout(() => {
      setDataPopup((prevData) => ({ ...prevData, showPopup: false }));
    }, 2000);
  };

  const handleRemoveOfCart = async (e) => {
    const id = e.currentTarget.dataset.id;
    const filterProducts = cartVariants.cart.filter((v) => v.item._id !== id);

    if (filterProducts.length > 0) {
      setCartVariants({
        cart: filterProducts,
        subTotal: cartVariants.subTotal,
      });
    }
    return filterProducts;
  };

  const handleOnProductRemove = async (e) => {
    setUpdate(true);

    try {
      await handleRemoveOfCart(e);
    } catch (error) {
      console.error("Error al eliminar el producto del carrito:", error);
    } finally {
      setUpdate(false);
    }
  };
  const handleOnShowResume = () => {
    const resume = document.querySelector(".chk-content");

    if (resume) {
      resume.style.opacity = 1;
      resume.style.visibility = "visible";
      resume.style.transform = `translateX(0)`;
    }
  };

  useEffect(() => {
    if (config.accountData) {
      const accountData = config.accountData;

      setInfo(accountData);
      setSendTo(accountData.user.profile.address.shipment_address);
    }
  }, []);

  const updateCart = async () => {
    let updateCartVariant = []; // Array para almacenar los productos actualizados
    let subTotal = 0; // Variable para calcular el subtotal

    for (const variant of cartVariants.cart) {
      try {
        const ubigeo = info && info.user.profile.address[sendTo].ubigeo;
        const res = ubigeo
          ? await checkoutFn.checkProductShipment(
              variant.item.product_id,
              ubigeo
            )
          : null;

        let offerPrice = variant.item.price;
        let discount = 0;

        if (variant.item.offer) {
          const offer = variant.item.offer;

          if (offer.symbol === "%") {
            offerPrice =
              variant.item.price - (offer.value / 100) * variant.item.price;

            if (res && res.status === "available") {
              discount =
                variant.item.price - (offer.value / 100) * variant.item.price;
            }
          } else {
            offerPrice = variant.item.price - offer.value;

            if (res && res.status === "available") {
              discount = variant.item.price - offer.value;
            }
          }
          subTotal += discount * variant.qty;
        } else {
          // Aquí se maneja el caso en el que no hay una oferta para el producto
          offerPrice = variant.item.price; // Precio normal del producto
          discount = 0; // No hay descuento

          // Se agrega el precio normal del producto multiplicado por la cantidad al subtotal
          subTotal += offerPrice * variant.qty;
        }

        // Actualizar el estado de envío del producto
        variant["shipmentStatus"] = res ? res.status : "available";

        // Establecer el precio con descuento en el producto
        variant["offerPrice"] = offerPrice;

        // Agregar el producto actualizado al array de productos actualizados
        updateCartVariant.push(variant);
      } catch (error) {
        // Si ocurre un error, simplemente omitir este producto y continuar con el cálculo
        console.error("Error updating cart variant:", error);
      }
    }

    // Crear el esquema de carrito actualizado con los productos y el subtotal
    const cartSchema = {
      cart: updateCartVariant,
      subTotal: subTotal,
    };

    // Actualizar el estado del carrito con los productos actualizados y el subtotal
    setCartVariants(cartSchema);

    // Guardar el carrito actualizado en el almacenamiento local
    ls.set("cart", JSON.stringify(cartSchema));
  };

  useEffect(() => {
    if (cartVariants.cart.length > 0 && !update) {
      updateCart();
    } else {
      // Si el carrito está vacío, establecer el subtotal en cero
      setCartVariants({ cart: [], subTotal: 0 });
      ls.set("cart", JSON.stringify({ cart: [], subTotal: 0 }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, sendTo, update]);

  //construccion de la data agregando shipmentStatus y offerPrice
  const updateProducts = async (products) => {
    // Iterar sobre cada producto en la colección
    const updatedProducts = await Promise.all(
      products.map(async (product) => {
        // Obtener el ubigeo del usuario
        const ubigeo = info && info.user.profile.address[sendTo].ubigeo;
        // Verificar si hay información de envío
        const res = ubigeo
          ? await checkoutFn.checkProductShipment(
              product.item.product_id,
              ubigeo
            )
          : null;

        let offerPrice = product.item.price;
        let shipmentStatus = "available"; // Por defecto, si no hay información de envío

        if (product.item.offer) {
          const offer = product.item.offer;
          if (offer.symbol === "%") {
            offerPrice =
              product.item.price - (offer.value / 100) * product.item.price;
          } else {
            offerPrice = product.item.price - offer.value;
          }
        }

        if (res) {
          shipmentStatus = res.status;
        }

        // regresa el producto actualizado con offerPrice y shipmentStatus
        return {
          ...product,
          offerPrice: offerPrice,
          shipmentStatus: shipmentStatus,
        };
      })
    );

    return updatedProducts;
  };

  // agregando productos al localstorage
  const addToCart = async (products) => {
    setUpdate(true);
    try {
      // Actualizar los productos con la nueva información
      const updatedProducts = await updateProducts(products);

      // Actualizar el estado del carrito con los nuevos productos
      const updatedCart = [...cartVariants.cart];

      updatedProducts.forEach((updatedProduct) => {
        // Buscar si el producto ya está en el carrito
        const index = updatedCart.findIndex(
          (cartProduct) => cartProduct.item._id === updatedProduct.item._id
        );
        if (index === -1) {
          // Si el producto no está en el carrito, agregarlo
          updatedCart.push(updatedProduct);
        }
      });

      // Actualizar el estado del carrito
      setCartVariants({ cart: updatedCart, subTotal: cartVariants.subTotal });

      // Actualizar el almacenamiento local (localStorage) con el nuevo estado del carrito
      ls.set(
        "cart",
        JSON.stringify({ cart: updatedCart, subTotal: cartVariants.subTotal })
      );
      // window.location.reload(false);
      setUpdate(false);
    } catch (error) {
      console.error("Error al agregar producto(s) al carrito:", error);
    }
  };

  //actualizacion de stock

  const updateStockCartVariants = (updatedCart) => {
    setCartVariants({ cart: updatedCart, subTotal: cartVariants.subTotal });
    updateCart();
  };

  return (
    <div className="checkout bkg-background">
      <nav className="d-flex d--middle d--gap-2 pdd-v-4 pdd-h-8 bkg-white b-b-n bc-black">
        {page > 0 && page < 2 && (
          <button
            onClick={() => onChangePage(page - 1)}
            className={btnClass}
            type="button"
            title="atras"
          >
            <Icon path={iconBack} size={1} />
          </button>
        )}

        <Image
          src={"max-logo.png"}
          title={"logo"}
          url={"/"}
          classImg={logoClass}
        />
      </nav>

      <div className="container wrapper">
        <div className="checkout__inner">
          {dataPopup.showPopup && (
            <popup.Message
              addClassText={dataPopup.textClass}
              text={dataPopup.text}
            />
          )}
          {page < 2 && (
            <div className="ta-center pdd-v-5 mrg-v-4 br-n">
              <h1 className="mrg-0 fs-m fs-xl!sm fs-b!md">
                {sectionHeaders[page]}
              </h1>
              {/* <div className="step-indicators d--gap-6 d--center d-flex mrg-t-2 ">
            <div
              className={`step-indicator pos--relative bkg-background-2 b-m bc-black br-circle color-black d--center d--middle d-flex fw-800 h-1 w-1 ${
                page === 0 ? "active" : page > 0 ? "complete" : ""
              }`}
            >
              1
            </div>
            <div
              className={`step-indicator pos--relative bkg-background-2 b-m bc-black br-circle color-black d--center d--middle d-flex fw-800 h-1 w-1 ${
                page === 1 ? "active" : page > 1 ? "complete" : ""
              }`}
            >
              2
            </div>
            {/* <div
              className={`step-indicator pos--relative bkg-background-2 b-m bc-black br-circle color-black d--center d--middle d-flex fw-800 h-1 w-1 ${
                page === 2 ? "complete" : ""
              }`}
            >
              3
            </div> 
          </div> */}
            </div>
          )}

          {page === 0 && (
            <ChkSections sectionClass={"chk-sections--2"}>
              <div className="checkout__display-resume">
                <button
                  onClick={handleOnShowResume}
                  className="button button--outline pdd-0"
                >
                  <Icon path={mdiChevronRightBox} size={2} />
                </button>
              </div>

              <ChkContent
                cartVariants={cartVariants}
                handleOnProductRemove={handleOnProductRemove}
                info={info}
                sendTo={sendTo}
                setSendTo={setSendTo}
                addToCart={addToCart}
                updateStockCartVariants={updateStockCartVariants}
              />

              <ChkResume
                onChangePage={onChangePage}
                offerValue={offerValue}
                setOfferValue={setOfferValue}
                subTotalCosts={subTotalCosts}
                setSubTotalCosts={setSubTotalCosts}
                total={total}
                setTotal={setTotal}
                cartVariants={cartVariants}
                info={info}
                sendTo={sendTo}
                setRucInfo={setRucInfo}
              />
            </ChkSections>
          )}

          {page === 1 && (
            <ChkSections>
              <ChkPayment
                onShowPopup={onShowPopup}
                onChangePage={onChangePage}
                cartVariants={cartVariants}
                paymentOptions={variables.paymentOptions}
                paymentSelected={paymentSelected}
                setPaymentSelected={setPaymentSelected}
                offerValue={offerValue}
                subTotalCosts={subTotalCosts}
                setOrder={setOrder}
                info={info}
                sendTo={sendTo}
                total={total}
              />
            </ChkSections>
          )}

          {page === 2 && order && <ChkOrderDetail order={order} />}

          {page === 0 && (
            <div className="bkg-whatermark pdd-2 b-xs color-silver mrg-v-4 fs-c fs-n!sm">
              En caso de que algún producto figure como <b>NO DISPONIBLE</b>,
              lamentamos informarle que la tienda que ofrece dicho producto no
              realiza envíos a su ubicación. Le recomendamos verificar los
              detalles del producto para confirmar su disponibilidad de envío.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
