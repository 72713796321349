import React, { useState, useEffect } from 'react'
import ShopCard from '../snippets/shop-card'
import Breadcrumbs from '../components/breadcrumbs'
import api from '../services/api'

const Shops = () => {
  const [datashop, setDatashop] = useState([])

  useEffect(() => {
 
      api
        .get(`api/allShops/productsByShop?limit=3`)
        .then(async (response) => {
          const data = response.data
          setDatashop(data.data)
        })
        .catch((error) => {
          console.error('Error al obtener los datos:', error)
        })

  }, [])

  return (
    <div className='shops mrg-b-4 mrg-t-5'>
      <div className='container wrapper'>
        <Breadcrumbs />
        <div className='shops__inner'>
        <ShopCard data={datashop} />
        </div>
      </div>
    </div>
  )
}

export default Shops
