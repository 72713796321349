import UseWindowSize from '../scripts/window-size'
import ClassChange from '../scripts/class-change'
import { Open, Close } from '../scripts/open-close'
import Link from '../snippets/link'

export const SideMenu = ({ containerClass = "", name, Icon, cursor, side, isLink, url, label, IconLink, children }) => {
  const sizeScreen = UseWindowSize();
  const className = ClassChange([750], ['mw-1'], sizeScreen.width, 'mw-1');

  return (
    <div className={`menu menu__${name}`}>
      {
        Icon && (
          <div
            className={`menu__icon ${cursor ? 'pointer' : ''} ${className} icon--${name}`}
            data-menu={`data-menu-${name}`}
            onClick={(e) => Open(e, name, side)}>
            <button className='button pdd-1 bc-transparent bkg-transparent color-black'>
              <Icon />
            </button>
          </div>
        )
      }
      {
        isLink && (
          <div
            className='side-menu--link'
            data-menu={`data-menu-${name}`}
            onClick={(e) => Open(e, name, side)}>
            <Link url={url} label={label} Icon={IconLink} iconName={name} />
          </div>
        )
      }

      <aside>
        <div
          className={`side-menu side-menu__${name} hidden`}
          data-close-menu={`data-menu-${name}`}
          onClick={(e) => Close(e, name)}
        >
          <div className={`side-menu__container ${containerClass} ${side === 'right' ? 'appear--right' : 'appear--left'}`}>
            <div id={`MenuContent-${name}`} className='side-menu__inner h-0'>
              {children}
            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}
