const ChkResumeCta = ({ onChangePage, total, hasItems }) => {
  const status = total === 0 || !hasItems ? false : true;

  return (
    <div className="chk-resume-cta pdd-2">
      <button
        onClick={() => onChangePage(1)}
        className={`button button--primary w-0 ${!status ? "disabled" : ""}`}
        type="button"
        title="continuar" >
        Continuar
      </button>
    </div >
  )
}

export default ChkResumeCta;
