import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import ImageMosaic from "./product-mosaic";
import Product from "./product";
import Specifications from "./table-features";
import UseWindowSize from "../scripts/window-size";

import Icon from "@mdi/react";
import {
  mdiStar,
  mdiTruckDelivery,
  mdiShieldAlertOutline,
  mdiStore,
} from "@mdi/js";
import api from "../services/api";
import storage from "../scripts/storage";
import imgFn from "../scripts/image-functions";
import ls from "../services/ls";
import Modal from "./modal";
import Carousel from "./carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import onImageRescaling from "../scripts/imageRescaling";
import config from "../config/config";

const ProductDetail = ({
  handleAddToCart,
  handleAddToList,
  handleRemoveOfFavorites,
}) => {
  const [product, setProduct] = useState({});
  const [currentVariant, setCurrentVariant] = useState(0);
  const [products, setProducts] = useState([]);
  const [mainImage, setMainImage] = useState();
  // const [currentPage, setCurrentPage] = useState(1)
  // const [productsPerPage, setProductsPerPage] = useState(6)
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState();
  const [showImageModal, setShowImageModal] = useState(false);
  const [controls, setControls] = useState(true);
  const screen = UseWindowSize();

  const { id } = useParams();

  useEffect(() => {
    setCurrentVariant(0);
  }, [id]);

  useEffect(() => {
    api
      .get(`api/variant/data/product/${id}`)
      .then(async (response) => {
        const data = response.data;
        setProduct(data.product);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
        setLoading(false);
      });
  }, [id]);

  //mensaje de votacion de estrellas lograda o no lograda
  const alertMessageRating = (message) => {
    const thankYouMessage = document.getElementById("thankYouMessage");
    thankYouMessage.textContent = message;
    thankYouMessage.style.display = "block";
    setTimeout(() => {
      thankYouMessage.classList.add("fade-out");
    }, 1000);

    setTimeout(() => {
      thankYouMessage.style.display = "none";
      thankYouMessage.classList.remove("fade-out");
    }, 2000);
  };

  //actualizacion de la data rating
  const saveDataRating = (clickedRating) => {
    const accountData = JSON.parse(ls.get("accountData"));
    const data = {
      score: clickedRating,
      user: accountData.user._id,
    };
    return new Promise((resolve, reject) => {
      api
        .put(`api/variant/rating/${id}`, data,config.ssToken ,config.sessionId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  const handleVerifySession = (valueRating) => {
    // Verificar si hay un token de sesión en el almacenamiento local
    const token = localStorage.getItem("ssToken");
    if (!token) {
      console.log("Usuario no logeado");
      alertMessageRating("Debe estar logeado para calificar un producto");
      return;
    }
  
    // Si hay un token de sesión, guardar la calificación del producto
    alertMessageRating("¡Gracias por tu calificación!");
    saveDataRating(valueRating);
  };
  
  
  //click de votacion de las estrellas
  const handleStarClick = (valueRating) => {
    handleVerifySession(valueRating);
    setRating(valueRating);
  };

  //pintado de estrellas al pasar el mouse
  const setHoveredRating = (index) => {
    setRating(index);
  };

  //click para ir a una nueva variante de ese producto
  const handleShowNextVariant = (prevVariant) => {
    setCurrentVariant(prevVariant);
    setMainImage(product.variants[prevVariant].images[0].imageUrl);
    if (product.variants[currentVariant].rating.average === "") {
      setRating(0);
    } else {
      setRating(product.variants[currentVariant].rating.average);
    }
  };

  const handleMosaicImageMouseOver = (newImageUrl) => {
    setMainImage(newImageUrl);
  };

  // traer productos destacados de la tienda
  useEffect(() => {
    api
      .get(`api/product/pagination?page=1`)
      .then(async (response) => {
        setProducts(response.data.data);
        updateScreen();
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, []);

  const updateScreen = () => {
    if (
      (products.length <= 5 && screen.width >= 1440) ||
      (products.length <= 4 && screen.width >= 1024) ||
      (products.length <= 3 && screen.width >= 750) ||
      (products.length <= 2 && screen.width >= 480)
    ) {
      setControls(false);
    } else {
      setControls(true);
    }
  };

  useEffect(() => {
    updateScreen();
  }, [products, screen]);

  useEffect(() => {
    if (product.variants && product.variants.length > 0) {
      setMainImage(product.variants[currentVariant].images[0].imageUrl);

      if (product.variants[currentVariant].rating.average === "") {
        setRating(0);
      } else {
        setRating(product.variants[currentVariant].rating.average);
      }
    }
  }, [product, currentVariant]);

  //actualizar el estado con la  ID de la sección a la que se quiere desplazar
  const section1Ref = useRef(null);

  const scrollToSection = (e) => {
    e.preventDefault();
    const yOffset = -155;
    window.scrollTo({
      top: section1Ref.current.offsetTop + yOffset,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (Object.keys(product).length > 0) {
      storage.recentProducts(product);
    }
  }, [product]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  const handleOnCloseImageModal = (e) => {
    if (e.target.dataset.modalName === "modal") setShowImageModal(false);
  };

  return (
    <div className="container wrapper ">
      {product &&
        product.variants &&
        product.variants[currentVariant] &&
        product.variants[currentVariant].images && (
          <>
            <div className="d--center d--gap-5 d-grid d-grid__column d-grid__column--2af!lg d-grid__column--2af!md d-grid__column--1aut!sm pdd-t-2 product-detail__container">
              <div className="bkg-white d-flex pdd-5 product-detail__container__image w-0 d--center br-l d--gap-2">
                {product.variants[currentVariant].images.length > 1 && (
                  <div className=" detail-mosaic pdd-v-8!lg w-1!tn w-2!lg d--gap-2 w-0">
                    <ImageMosaic
                      images={product.variants[currentVariant].images}
                      onMosaicImageMouseOver={handleMosaicImageMouseOver}
                    />
                  </div>
                )}

                <div className="detail-mainImage">
                  <LazyLoadImage
                    src={mainImage}
                    alt={`Imagen de ${product.name}`}
                    onError={(e) => imgFn.onErrorLoadingImage(e)}
                    className="detail-mainImage main-image w-10!sm w-13!md w-15!lg w-8!tn mrg-t-5 "
                  />

                  <div
                    className="overlay-message color-white pdd-h-2 pdd-v-1 b-b br-s bkg-black w-5"
                    onClick={() => setShowImageModal(true)}
                  >
                    Ver imagen ampliada
                  </div>

                  <Modal
                    modalInnerClass="w-20 "
                    iconClass="color-black"
                    showModal={showImageModal}
                    onClose={handleOnCloseImageModal}
                  >
                    <LazyLoadImage
                      src={mainImage}
                      alt={`Imagen de ${product.name}`}
                      onError={(e) => imgFn.onErrorLoadingImage(e)}
                      className="h-16!m"
                    />
                  </Modal>
                </div>
              </div>
              {/* detalles del producto  */}
              <div className="d--gap-2 d-flex product-detail__container__description w-0">
                <div className="product-detail__container__description--name d-flex d-flex--column pdd-2 bkg-white br-l">
                  <div className="ta-center">
                    <h1 className="product-details__brand mrg-0 fw-300 w-0">
                      {product.brand}
                    </h1>
                    <h2 className="product-details__name mrg-0 fw-300">
                      {product.variants[currentVariant].product_name}{" "}
                      {product.variants[currentVariant].variant_name}
                    </h2>
                    <ul className="">
                      {product.variants[currentVariant].tags.map(
                        (property, index) => (
                          <li
                            key={index}
                            className="br-b features-list-li mrg-1 pdd-h-2 pdd-v-1"
                          >
                            {property}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="d--middle d-flex w-0 d--gap-2">
                    <div className="d-flex pdd-2 w-0 d--gap-5">
                      <h2 className="product-details__price fs-b!tn mrg-0 pdd-v-5">
                        S/ {product.variants[currentVariant].price.toFixed(2)}
                      </h2>

                      <div className="product-details__rating d--middle d-flex">
                        <div className="d--middle d-flex star-rating">
                          {[1, 2, 3, 4, 5].map((index) => (
                            <div
                              key={index}
                              className={`star ${
                                index <= rating ? "filled" : ""
                              }`}
                              onMouseEnter={() => setHoveredRating(index)}
                              onClick={() => handleStarClick(index)}
                              style={{
                                color: index <= rating ? "#f1c40f" : "#bbb",
                              }}
                            >
                              <Icon
                                className="star-icon"
                                path={mdiStar}
                                size={1.5}
                              />
                            </div>
                          ))}

                          <div
                            id="thankYouMessage"
                            className="menssage-rating w-9"
                          ></div>
                        </div>

                        {product.variants[currentVariant].rating.scores.length >
                        0 ? (
                          <p>
                            <span className="product-details__rating__average">
                              {product.variants[
                                currentVariant
                              ].rating.average.toFixed(1)}
                            </span>{" "}
                            votos:(
                            {
                              product.variants[currentVariant].rating.scores
                                .length
                            }
                            ){" "}
                            <span className="product-details__rating__votes">
                              {product.variants[currentVariant].scores}
                            </span>
                          </p>
                        ) : (
                          <p>
                            votos(0) ¡Tu opinión es importante para nosotros!
                            ¡Déjanos saber qué piensas!
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className="d--gap-3 d-flex product-details__specifications w-0"
                      onClick={scrollToSection}
                    >
                      <p className="product-details__stock">
                        Stock: {product.variants[currentVariant].stock}
                      </p>
                      <p className="product-details__specifications__link td-underline">
                        Ver especificaciones técnicas
                      </p>
                    </div>
                  </div>

                  <div className="product-details__description">
                    <p className="cr--pointer">
                      Descripción:{" "}
                      <span className="resume-text">
                        {product.description.split(/\s+/).length > 30
                          ? product.description
                              .split(/\s+/)
                              .slice(0, 30)
                              .join(" ") + "..."
                          : product.description}
                        {product.description.split(/\s+/).length > 30 && (
                          <span
                            className="product-details__read-more td-underline color-red"
                            onClick={scrollToSection}
                          >
                            Ver más
                          </span>
                        )}
                      </span>
                    </p>
                  </div>

                  {product.variants.length > 1 && (
                    <div className="product-details__variants d-flex">
                      VARIANTES:
                      {product.variants.map((variant, index) => (
                        <img
                          key={variant._id}
                          className="product-details__variants__image pdd-2 w-2"
                          src={onImageRescaling(
                            variant.images[0].imageUrl,
                            200
                          )}
                          alt={`Miniatura de ${product.name} - Variante ${variant.value}`}
                          onClick={() => handleShowNextVariant(index)}
                          onError={(e) => imgFn.onErrorLoadingImage(e)}
                        />
                      ))}
                    </div>
                  )}
                </div>

                <div className="bkg-white br-l d-flex pdd-h-2 pdd-v-5 product-detail__container__description--info w-0">
                  <div className="pdd-2 w-0">
                    <h3 className=" mrg-0 d--center d-flex">
                      <Icon path={mdiStore} size={1} />
                      {product.shop_name}
                    </h3>

                    <div className="mrg-0 mrg-b-2 ta-center">
                      <a
                        href={`/shops/tienda/${encodeURIComponent(
                          product.shop.name
                        ).replace(/%20/g, "-")}/${product.shop._id}`}
                        className="link-destacado color-green link-destacado td-underline"
                      >
                        Descubre más productos en la tienda{" "}
                        <span>"{product.shop.name}"</span>
                        <br />
                        <span>¡Haz clic aquí para explorar ahora!</span>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex d--middle pdd-1 w-0">
                    <Icon path={mdiTruckDelivery} size={1} />
                    <p className="pdd-h-1 mrg-0">Delivery a domicilio</p>
                  </div>
                  <div className="d-flex d-flex--column w-0">
                    <div className="d--middle d-flex">
                      <Icon path={mdiShieldAlertOutline} size={1} />
                      <span>Garantia:</span>
                      <p className="br-b">{product.warranty.clauses}</p>
                    </div>
                    <div className="b-s bc-black br-s cr--pointer descripcion-container mrg-b-3 pdd-2 w-0">
                      <details className="">
                        <summary className="d--gap-1 d--middle d-flex fw-700 ">
                          Detalles de la garantia
                        </summary>
                        <div>{product.warranty.warranty_desc}</div>
                      </details>
                    </div>
                  </div>

                  <div className="d--center d--middle d-flex fs-l!lg d--gap-2 w-0">
                    <a
                      href="/checkout"
                      className="bkg-red br-n color-white d--center d--middle d-flex fs-n h-1 pdd-h-2 product-detail-button-buy-now ta-center"
                      title="comprar ahora"
                      type="button"
                      onClick={() => {
                        handleAddToCart(product.variants[currentVariant]);
                      }}
                    >
                      Comprar Ahora
                    </a>
                    <button
                      className="bkg-red br-n color-white fs-n h-1 pdd-h-2 product-detail-button-add-to-cart"
                      title="añadir carrito"
                      type="button"
                      onClick={() => {
                        handleAddToCart(product.variants[currentVariant]);
                      }}
                    >
                      Añadir al Carrito
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* el slider  */}
            <div className="bkg-white mrg-t-8 slider-product-details pdd-v-4">
              {products.length > 0 && (
                <div>
                  <h1 className="slider-product-details__litle fs-xl!tn mrg-0  ft-upper mrg-v-4 w-0 ta-left">
                    Productos Relacionados
                  </h1>

                  <Carousel
                    containerClass="products__slider"
                    controlContainerClass="products__controls"
                    controlsClass="products__nav-control"
                    v1440Items={5}
                    v1024Items={3}
                    v750Items={2}
                    v480Items={1}
                    vDefaultItems={1}
                    speed={500}
                    showControls={controls}
                    controlsStyles="smallCircle bkg-black"
                  >
                    <ul className="products__slider d-flex d-flex--no-wrap d--gap-2 mrg-v-2">
                      {products
                        .filter(
                          (product) =>
                            product.variants && product.variants.length > 0
                        ) // Filtrar las variantes nulas o vacías
                        .map((product, index) => (
                          <Product
                            key={index}
                            handleAddToCart={handleAddToCart}
                            dataVariant={product.variants[0]}
                            handleAddToList={handleAddToList}
                            handleRemoveOfFavorites={handleRemoveOfFavorites}
                          />
                        ))}
                    </ul>
                  </Carousel>
                </div>
              )}
            </div>

            <div ref={section1Ref} className="h-1"></div>

            <div className="spect">
              {/* tabla de especificaciones tecnicas */}
              <Specifications
                product={product}
                currentVariant={currentVariant}
              />
            </div>
          </>
        )}
    </div>
  );
}

export default ProductDetail;
