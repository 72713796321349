import { useRef, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Icon from '@mdi/react'
import { mdiPlus, mdiMinus, mdiHeartBroken,mdiDelete } from '@mdi/js'
import imgFn from '../scripts/image-functions'
import onImageRescaling from '../scripts/imageRescaling'

const ProductCardV2 = ({ handleOnPlus, handleOnMinus, handleRemoveOfCart, isCart, handleRemoveOfFavorites, variant, index }) => {
  const [offerPrice, setOfferPrice] = useState(variant.item.price);
  const qty = variant.qty;
  const quantityRef = useRef()
  variant = variant.item;

  useEffect(() => {
    if (variant.offer) {
      if (variant.offer.symbol === "%") {
        setOfferPrice(variant.price - ((variant.offer.value / 100) * variant.price));
      } else {
        setOfferPrice(variant.price - variant.offer.value);
      }
    }
  }, [offerPrice, variant]);

  return (
    <div className='product-card-v2'>
      <div className='product-card-v2__inner d-flex d--middle'>
        <div
          className={`d-grid d-grid__row--2fa d--gap-2`}>
          <a
            href={`/product/${variant.variant_name}/${variant._id}`}
            className='product-card-v2__product d-flex d--middle'
          >
            <div className='d-flex__column--3'>
              <div className='product-card-v2__image-container'>
                <div className={`product-card-v2__image`}>
                  {
                    variant.images && (
                      <LazyLoadImage
                        onError={imgFn.onErrorLoadingImage}
                        src={onImageRescaling(variant.images[0].imageUrl, 200)}                
                        alt={variant.images[0].nameImage}
                        title={variant.images[0].nameImage}
                        effect='blur'
                      />
                    )
                  }
                </div>
              </div>
            </div>

            <div className='d-flex__column--9'>
              <div className='product-card-v2__content d-flex d-flex__column d--start'>
                <div className='fs-n fw-700'>{`${variant.product_name} - ${variant.variant_name}`}</div>

                <div className='fs-c color-silver'>
                  {variant.brand}
                </div>

                <div className='d-flex d--gap-2'>
                  {
                    variant.offer && (
                      <div className='color-silver td-through'>
                        S/{(variant.price).toFixed(2)}
                      </div>
                    )
                  }

                  <div className='fw-700 color-primary'>
                    S/{(offerPrice).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </a>

          {isCart === false ? (
            <div
              className='product-card-v2__remove d-flex d--middle'
              data-id={variant._id}
              onClick={handleRemoveOfFavorites}
            >
              <Icon path={mdiHeartBroken} size={1} />
            </div>
          ) : (
            <div className='product-card-v2__cta d-flex d--middle d--right d--gap-2'>
              <div
                className='product-card-v2__remove d-flex d--middle'
                data-id={variant._id}
                onClick={handleRemoveOfCart}
              >
                <Icon path={mdiDelete} size={1} />
              </div>

              <div className='product-card-v2__label h4'>
                x{' '}
                <span
                  className='product-card-v2__quantity'
                  data-id={variant._id}
                  ref={quantityRef}
                >
                  {qty}
                </span>
              </div>

              <div className='product-card-v2__buttons--2 d-grid d-grid__column--2fr d--gap-2'>
                <button
                  type='button'
                  title='plus'
                  className='product-card-v2__button product-card-v2__button--plus button button--primary pdd-1 bkg-yellow color-black'
                  name='plus'
                  onClick={() => handleOnPlus(quantityRef, index)}
                >
                  <Icon path={mdiPlus} size={1} />
                </button>

                <button
                  type='button'
                  title='minus'
                  className='product-card-v2__button button product-card-v2__button--minus button--primary pdd-1 bkg-yellow color-black'
                  name='minus'
                  onClick={() => handleOnMinus(quantityRef, index)}
                >
                  <Icon path={mdiMinus} size={1} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCardV2
