import { useEffect, useRef } from 'react';

const ProgressBar = (props) => {
  const { containerClasses, height, percentage, barColor } = props;
  const innerBarRef = useRef();

  useEffect(() => {
    if (percentage === 100)
      innerBarRef.current.classList.add("progress-bar__inner--complete");
    else
      innerBarRef.current.classList.remove("progress-bar__inner--complete");
  });

  return (
    <div className={`progress-bar ${containerClasses}`} style={{ height: `${height}px` }}>
      <div
        ref={innerBarRef}
        className={`progress-bar__inner ${barColor}`}
        style={{
          width: `${percentage}%`,
          height: '100%',
        }}
      ></div>
    </div>
  );
}

export default ProgressBar;