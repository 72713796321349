const set = (key, value) => {
  localStorage.setItem(key, value);
}

const clear = (key) => {
  localStorage.removeItem(key);
}

const get = (key) => {
  return localStorage.getItem(key);
}

const ls = { set, clear, get };

export default ls;
