import Icon from '@mdi/react';
import { mdiDirections } from '@mdi/js';
import ChkShippingAddress from './chk-shipping-address';
import config from '../../config/config';

const ChkShipping = ({ info, sendTo, setSendTo }) => {
  const handleOnChangeAddress = (e) => {
    setSendTo(e.target.value)
  }

  return (
    <div className="chk-shipping bkg-white pdd-h-4 pdd-v-4 mrg-b-5 br-n">
      {
        info && info.user.profile.address[sendTo].ubigeo ? (
          <div className='d-grid d-grid__column--2fa'>
            <div>
              <h4 className="mrg-t-0 mrg-b-3 ft-upper">Dirección: </h4>
              <ChkShippingAddress address={info.user.profile.address[sendTo]} />
            </div>

            <div>
              <div>
                <a href={`/profile?view=direcciones`} className='button button--outline pdd-0 b-none color-text-label'>Actualizar dirección 🛣️</a>
              </div>

              {
                info.user.profile.address.secondary.ubigeo && (
                  <div className='color-silver fs-s pdd-t-2'>
                    <div>Seleccionar dirección de envio:</div>
                    <div className='d-flex d--gap-1'>
                      <input className='input mrg-0 ' type="radio" name="shipmentAddress" defaultValue={"main"} checked={sendTo === "main"} onChange={handleOnChangeAddress} />
                      <p className='mrg-0'>Principal</p>
                    </div>

                    <div className='d-flex d--gap-1'>
                      <input className='input mrg-0' type="radio" name="shipmentAddress" defaultValue={"secondary"} checked={sendTo === "secondary"} onChange={handleOnChangeAddress} />
                      <p className='mrg-0'>Secundaria</p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        ) : (
          <a href={`/${config.sessionId ? "profile?view=direcciones&from=checkout" : "login?from=checkout"}`} className='d-flex d-flex--no-wrap d--middle d--gap-2 td-underline'>
            <Icon path={mdiDirections} size={1} className='color-primary' />
            <span className='fs-n fs-m!sm'>
              {
                config.sessionId ? "Para proceder con la compra, es necesario registrar una dirección de envío" : "Para realizar pedidos, es necesario iniciar sesión en tu cuenta"
              }
            </span>
          </a>
        )
      }
    </div>
  );
}

export default ChkShipping;
