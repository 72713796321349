import { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

const firstLevel = {
  name: 'AQUPE',
  url: '/',
}

const Breadcrumbs = ({ addClass }) => {
  const [secondLevel, setSecondLevel] = useState({})

  useEffect(() => {
    setSecondLevel({
      name: window.location.pathname.split('/'),
      url: window.location.href,
    })
  }, [])

  return (
    <div className={`breadcrumbs ${addClass}`}>
      <div className='breadcrumbs__inner d-flex d--middle'>
        <a href={firstLevel.url} className='breadcrumbs__level fw-300'>
          {firstLevel.name}
        </a>

        <Icon path={mdiChevronRight} size={1} color={'#000'} />

        <div className='breadcrumbs__level fw-600'>{secondLevel.name}</div>
      </div>
    </div>
  )
}

export default Breadcrumbs
