const Loading = ({ imgHeight = "auto" }) => {
  return (
    <div className="loading">
      <div className="loading__inner">
        <div className="image">
          <img src={require('../assets/loading.png')} alt="loading" width={100} height={imgHeight} />
        </div>
      </div>
    </div>
  );
}

export default Loading;
