import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Product from "../components/product";
import api from "../services/api";
import ReactSlider from "react-slider";
import UseWindowSize from "../scripts/window-size";
import Icon from "@mdi/react";
import {mdiFilterOutline} from "@mdi/js";

const ProductList = ({
  setProducts,
  loading,
  setLoading,
  brands,
  setBrands,
  selectedFilter,
  handleFilterChange,
  selectedPriceFilter,
  handlePriceFilterChange,
  filteredProducts,
  handleAddToCart,
  handleAddToList,
  handleRemoveOfFavorites,
}) => {
  const [variants, setvariants] = useState([]);
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);

  //accediendo a los parametros de la URL
  const { category, subcategory } = useParams();

  const screen = UseWindowSize();
  /// lista desplegable

  useEffect(() => {
    if (category) {
      setLoading(true);
      let url = `api/product/pagination?page=${page}&limit=10&filterBy=category.category_name&filterValue=${category}`;
  
      // Si se proporciona la subcategoría, agregarla a la URL
      if (category && subcategory) {
       url = `api/product/pagination?page=1&limit=10&filterBy=category.subcategory&filterValue=${subcategory}`;
      }
      // Agregar este console.log

      api
        .get(url)
        .then(async (response) => {
          const data = response.data;
  
          // Actualizar el estado solo una vez con la data completa
          setProducts(data.data);
          setvariants(data);
          setLoading(false);
  
          const elements = [];
  
          for (let i = 1; i <= data.total_pages; i++) {
            let currentPage = false;
  
            if (data.current_page === i) {
              currentPage = true;
            }
  
            elements.push(
              <span className="product__page" data-current-page={currentPage}>
                {i}
              </span>
            );
          }
  
          setPageNumbers(elements);
  
          // Extraer marcas disponibles de los productos y eliminar duplicados
          const uniqueBrands = [
            ...new Set(data.data.map((product) => product.brand)),
          ];
          setBrands(uniqueBrands);
        })
        .catch((error) => {
          console.error("Error al obtener los datos:", error);
          setLoading(false);
        });
    }
  }, [category, subcategory, page]);
  

  const handleResetFilters = () => {
    handleFilterChange("todos");
    handlePriceFilterChange(null);
  };

  const toggleFilterContent = () => {
    setFiltersVisible((prevVisible) => {
      if (!prevVisible) {
        // Si el filtro se está abriendo, aplicamos los estilos al ul
        document.querySelector("ul").style.opacity = "0.1";
        document.querySelector("ul").style.pointerEvents = "none";
      } else {
        // Si el filtro se está cerrando, quitamos los estilos del ul
        document.querySelector("ul").style.opacity = "1";
        document.querySelector("ul").style.pointerEvents = "auto";
      }
      // Invertimos el estado de visibilidad del filtro
      return !prevVisible;
    });
  };
  


  return (
    <div className="product-provider">
      <div className="container wrapper">
        <div className="product-provider__inner bkg-white ">
          <div className="ta-center ft-upper pdd-h-1">
            <h1 className="pdd-v-2 mrg-v-4">
              Lista de Productos de {category}
            </h1>
          </div>

          {/* Filtro por marca */}
          <div className="product-provider__content ">
            {screen.width >= 480 ? (
              <div
                className={`br-s mrg-l-2 pdd-2 w-0 mrg-b-2 product-provider__filters ${
                  filtersVisible ? "show" : ""
                }`}
              >
                <div className="filter-by-type mrg-b-5">
                  <span className="filter-title">FILTROS</span>
                  <label className="filter-label">
                    <p
                      className="reset-filters cr--pointer"
                      href="#"
                      onClick={() => handleResetFilters()}
                    >
                      Quitar filtro
                    </p>
                  </label>

                  <div className="group-type">
                    <details className="b-r-none b-t-l bc-light-silver color-black filter-dropdown">
                      <summary
                        className="filter-dropdown-litle cr--pointer w-0"
                        title="Marca"
                      >
                        Marca
                      </summary>
                      <div className="d--gap-1 d-flex d-flex--column filter-dropdown-content mrg-t-2 mrg-b-2">
                        {/* Agregar opción "Todos" */}
                        <label key="todos" className="filter-label">
                          <input
                            type="checkbox"
                            className="filter-input"
                            name="brand"
                            value=""
                            checked={!selectedFilter.length} // Verificar si no hay marcas seleccionadas
                            onChange={() => handleFilterChange("todos")}
                          />
                          Todos
                        </label>

                        {/* Mapear las marcas restantes */}
                        {brands.map((brand) => (
                          <label key={brand} className="filter-label__brand">
                            <input
                              type="checkbox"
                              className="filter-input"
                              name="brand"
                              value={brand}
                              checked={selectedFilter.includes(brand)}
                              onChange={() => handleFilterChange(brand)}
                            />
                            {brand}
                          </label>
                        ))}
                      </div>
                    </details>
                  </div>
                </div>

                <div className="d-grid filter-by-type mrg-b-5">
                  <span className="filter-title">Precio</span>

                  {/* Control deslizante para el rango de precios */}
                  <div className="price-slider-container">
                    <div className="price-slider-container__title d-flex d--center d--middle">
                      Min
                      <input
                        type="number"
                        className="filter-input b-b w-2 h-0"
                        value={selectedPriceFilter.min}
                        onChange={(e) =>
                          handlePriceFilterChange([
                            parseInt(e.target.value),
                            selectedPriceFilter.max,
                          ])
                        }
                        // onBlur={() => console.log('Se aplica el filtro')}
                      />
                      <p>-</p>
                      <input
                        type="number"
                        className="filter-input b-b w-2 h-0"
                        value={selectedPriceFilter.max}
                        onChange={(e) =>
                          handlePriceFilterChange([
                            selectedPriceFilter.min,
                            parseInt(e.target.value),
                          ])
                        }
                        // onBlur={() => console.log('Se aplica el filtro')}
                      />
                      Max
                    </div>

                    {/* Control deslizante */}
                    <ReactSlider
                      className="custom-slider ta-center"
                      thumbClassName="custom-slider__thumb bkg-silver"
                      trackClassName="custom-slider__track"
                      value={[selectedPriceFilter.min, selectedPriceFilter.max]}
                      min={0}
                      max={1000}
                      step={1}
                      ariaLabel={["Lower thumb", "Upper thumb"]}
                      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      pearling
                      minDistance={10}
                      onChange={(values) => handlePriceFilterChange(values)}
                    />
                  </div>

                  {/* Botón para filtrar todos los precios */}
                  <label className="filter-label mrg-t-5">
                    <input
                      type="radio"
                      className="filter-input"
                      name="price"
                      value=""
                      checked={
                        selectedPriceFilter.min === 0 &&
                        selectedPriceFilter.max === 1000
                      }
                      onChange={() => handlePriceFilterChange([0, 1000])}
                    />
                    Todos
                  </label>
                </div>
              </div>
            ) : (
              <>
              <div
                className={`br-s mrg-l-2 pdd-2 w-0 mrg-b-2 product-provider__filters ${
                  filtersVisible ? "show" : "hidden"
                }`}
              >
              <div className="cr--pointer ta-right"onClick={toggleFilterContent}>
                X
                </div>
                <div className="filter-by-type mrg-b-5">
                  <span className="filter-title fw-700">FILTROS</span>
                  <label className="filter-label">
                    <p
                      className="reset-filters cr--pointer"
                      href="#"
                      onClick={() => handleResetFilters()}
                    >
                      Quitar filtro
                    </p>
                  </label>

                  <div className="group-type">
                    <details className="b-r-none b-t-l bc-light-silver color-black filter-dropdown">
                      <summary
                        className="filter-dropdown-litle cr--pointer w-0"
                        title="Marca"
                      >
                        Marca
                      </summary>
                      <div className="d--gap-1 d-flex d-flex--column filter-dropdown-content mrg-t-2 mrg-b-2">
                        {/* Agregar opción "Todos" */}
                        <label key="todos" className="filter-label">
                          <input
                            type="checkbox"
                            className="filter-input"
                            name="brand"
                            value=""
                            checked={!selectedFilter.length} // Verificar si no hay marcas seleccionadas
                            onChange={() => handleFilterChange("todos")}
                          />
                          Todos
                        </label>

                        {/* Mapear las marcas restantes */}
                        {brands.map((brand) => (
                          <label key={brand} className="filter-label__brand">
                            <input
                              type="checkbox"
                              className="filter-input"
                              name="brand"
                              value={brand}
                              checked={selectedFilter.includes(brand)}
                              onChange={() => handleFilterChange(brand)}
                            />
                            {brand}
                          </label>
                        ))}
                      </div>
                    </details>
                  </div>
                </div>

                <div className="d-grid filter-by-type mrg-b-5">
                  <span className="filter-title fw-700">Precio</span>

                  {/* Control deslizante para el rango de precios */}
                  <div className="price-slider-container">
                    <div className="price-slider-container__title d-flex d--center d--middle">
                      Min
                      <input
                        type="number"
                        className="filter-input b-b w-2 h-0"
                        value={selectedPriceFilter.min}
                        onChange={(e) =>
                          handlePriceFilterChange([
                            parseInt(e.target.value),
                            selectedPriceFilter.max,
                          ])
                        }
                        // onBlur={() => console.log('Se aplica el filtro')}
                      />
                      <p>-</p>
                      <input
                        type="number"
                        className="filter-input b-b w-2 h-0"
                        value={selectedPriceFilter.max}
                        onChange={(e) =>
                          handlePriceFilterChange([
                            selectedPriceFilter.min,
                            parseInt(e.target.value),
                          ])
                        }
                        // onBlur={() => console.log('Se aplica el filtro')}
                      />
                      Max
                    </div>

                    {/* Control deslizante */}
                    <ReactSlider
                      className="custom-slider ta-center"
                      thumbClassName="custom-slider__thumb bkg-silver"
                      trackClassName="custom-slider__track"
                      value={[selectedPriceFilter.min, selectedPriceFilter.max]}
                      min={0}
                      max={1000}
                      step={1}
                      ariaLabel={["Lower thumb", "Upper thumb"]}
                      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow}</div>
                      )}
                      pearling
                      minDistance={10}
                      onChange={(values) => handlePriceFilterChange(values)}
                    />
                  </div>

                  {/* Botón para filtrar todos los precios */}
                  <label className="filter-label mrg-t-5">
                    <input
                      type="radio"
                      className="filter-input"
                      name="price"
                      value=""
                      checked={
                        selectedPriceFilter.min === 0 &&
                        selectedPriceFilter.max === 1000
                      }
                      onChange={() => handlePriceFilterChange([0, 1000])}
                    />
                    Todos
                  </label>
                </div>
              </div>
              <div className="b-xs bc-black br-n cr--pointer d-flex mrg-l-5 mrg-t-5 pdd-h-4 w-4" onClick={toggleFilterContent}>
              <Icon path={mdiFilterOutline} size={1} />
              <button className="b-none bkg-transparent" >Mostrar filtros</button>
              </div>
              </>
            )}

            {loading ? (
              <p className="product-list-loading">Cargando...</p>
            ) : filteredProducts.length === 0 ? (
              <div className="product-list-li w0">
                "No se encontraron productos que coincidan con su búsqueda.
                Pruebe con otros términos o categorías."
              </div>
            ) : (
              <ul className="d-grid d-grid__column--1aut!sm d-grid__column--3fr!md d-grid__column--5fr!lg d--gap-4 d--center">
                {filteredProducts.map((product) => (
                  <Product
                    key={product._id}
                    handleAddToCart={handleAddToCart}
                    dataVariant={product.variants[0]}
                    handleAddToList={handleAddToList}
                    handleRemoveOfFavorites={handleRemoveOfFavorites}
                  />
                ))}
              </ul>
            )}
          </div>

          {variants.total_pages > 1 && (
            <div className="section mrg-b-3">
              <ul className="d-flex d--center d--middle d--gap-2">
                <li
                  className={`product__nav mrg-r-2 ${
                    page === 1 ? "product__nav--unavailable" : ""
                  }`}
                  onClick={() => setPage(page - 1)}
                >
                  {"<<"}
                </li>
                {pageNumbers.length > 0 && (
                  <>
                    {page > 3 && <li>...</li>}
                    {pageNumbers
                      .slice(
                        Math.max(0, page - 3),
                        Math.min(pageNumbers.length, page + 2)
                      )
                      .map((pageNumber, index) => (
                        <li
                          key={index + 1}
                          onClick={(e) =>
                            setPage(parseInt(e.target.textContent))
                          }
                        >
                          {pageNumber}
                        </li>
                      ))}
                    {page < variants.total_pages - 2 && <li>...</li>}
                  </>
                )}
                <li
                  className={`product__nav mrg-l-2 ${
                    page === variants.total_pages
                      ? "product__nav--unavailable"
                      : ""
                  }`}
                  onClick={() => setPage(page + 1)}
                >
                  {">>"}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductList;
