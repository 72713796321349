import {useEffect, useState} from 'react'
import imageDefault from '../assets/profile-default.png';
import UseWindowSize from '../scripts/window-size';
import Carousel from './carousel';

const HomeShopCard = ({ filtershop, uniqueClass }) => {
  const [controls, setControls] = useState(true);
  const screen = UseWindowSize();

  useEffect(() => {
    if (
      (filtershop.length <= 6 && screen.width >= 1440) ||
      (filtershop.length <= 4 && screen.width >= 1024) ||
      (filtershop.length <= 3 && screen.width >= 750) ||
      (filtershop.length < 2 && screen.width < 480)
    ) {
      setControls(false);
    } else {
      setControls(true);
    }
  }, [screen, filtershop]);

  return (
    <div className='slider-section-shop-container'>
      <div className={`slider-section-shop-container__middle ${uniqueClass}`}>
        <Carousel
          containerClass='slider-section-shop-container__slider'
          controlContainerClass='slider-section-shop-container__controls'
          controlsClass='slider-section-shop-container__nav-control'
          v1440Items={6}
          v1024Items={4}
          v750Items={3}
          v480Items={2}
          vDefaultItems={1}
          speed={500}
          showControls={controls}
          controlsStyles="smallCircle bkg-black"
          >
          <ul className='slider-section-shop-container__slider d-flex d-flex--no-wrap d--gap-1'>
            {
              filtershop.map((shop, key) => (
                <li
                  className={`b-b br-l shops-card-element shop-card--${key} `}
                  key={key}>
                  <a className='cr--pointer eff-zoom--5' href={`/shops/tienda/$${encodeURIComponent(shop.shop_name).replace(/%20/g, '-')}/${shop._id}`}>
                    <div className='shops-card__header d-flex d-flex--column d--middle pdd-2 w-0'>
                      <div className='shops-card__logo'>
                        <img
                          className='w-0 mw-2 h-2'
                          src={shop.images.logo.imageUrl}
                          title={shop.shop_name}
                          alt={shop.shop_name}
                          onError={(e) => {
                            e.target.src = imageDefault // imagen predeterminada en caso de error
                          }}
                        />
                      </div>
                      <div className='shops-card__content h-1'>
                        <div className='shops-card__heading'>
                          {shop.shop_name}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              ))
            }
          </ul>
        </Carousel>
      </div>
    </div>
  );
}

export default HomeShopCard;
