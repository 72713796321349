import React from "react";
import imgNotFound from "../assets/NotFoundPage.jpg"

const NotFoundPage = () => {
  return (
    <div className="bkg-white container not-found-container wrapper">
      <div className="d--center d--middle d-flex not-found-content">
        <div className="fs-b w-12">
          <h2 className="fw-700">Oops!</h2>
          <p>Nosotros no podemos encontrar la página que estás buscando.</p>
          <p>Error code: 404</p>
        </div>
        <div className="not-found-image">
          <img className="w-15" src={imgNotFound} alt="Imagen de error 404" />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
