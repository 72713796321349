import api from "../../services/api";
import config from "../../config/config";

const fetchProfile = (user_id) => {
  return new Promise((resolve, reject) => {
    api.get(`api/user/${user_id}`, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const fetchOrders = (user_id) => {
  return new Promise((resolve, reject) => {
    api.get(`api/order/byUser/${user_id}`, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const updateUser = (user_id, data) => {
  return new Promise((resolve, reject) => {
    api.put(`api/user/${user_id}`, data, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const updateAccount = (account_id, data) => {
  return new Promise((resolve, reject) => {
    api.put(`api/account/${account_id}`, data, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const profileFn = {
  fetchProfile,
  fetchOrders,
  updateUser,
  updateAccount,
}

export default profileFn;