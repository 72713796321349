import Icon from "@mdi/react";
import { mdiBellCheckOutline as bell } from "@mdi/js";

const Notification = () => {
  return (
    <div className="notification" data-trigger={false}>
      <div className="notification__inner">
        <div className="notification__icon">
          <Icon path={bell} size={1.2} />
        </div>

        <div className="notification__text">
          Notificación
        </div>
      </div>
    </div>
  );
}

export default Notification;