
import onImageRescaling from "../../scripts/imageRescaling"

const ChkResumeItem = ({ variant, index }) => {
  return (
    variant.shipmentStatus === 'available' && (
        <div className='chk-resume__item d--middle'>
          <div className='chk-resume__number'>{index + 1}</div>

          <div className='chk-resume__info d--middle d-grid d-grid__column--2af'>
            <img
            className="w-1"
            src={onImageRescaling(variant.item.images[0].imageUrl, 200)}                
            alt={variant.item.variant_name}
            />
            <span className='chk-resume__name mrg-l-1' title={variant.item.name}>
              {variant.item.variant_name}
            </span>
         
          </div>

          <div className="ta-center">{variant.qty}</div>

          <div className='ta-right'>
            S/
            {variant.offerPrice
              ? (variant.offerPrice * variant.qty).toFixed(2)
              : 0}
          </div>
        </div>

    )
  )
}

export default ChkResumeItem
