const ChkResumeResult = ({ label, result = 0, labelClass = "", resultClass = "" }) => {
  return (
    <div className="d-grid d-grid__column--2af ta-right mrg-t-4 pdd-t-2 b-t-n bc-black">
      <span className={labelClass}>{label}:</span>
      {
        typeof result === 'number' && (
          <span className={resultClass}>S/{result.toFixed(2)}</span>
        )
      }
    </div>
  );
}

export default ChkResumeResult;
