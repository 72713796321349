import api from "./api";
import ls from "./ls";

const userTraking = async () => {
  if (ls.get("userTracking")) {
    const userTracking = JSON.parse(ls.get("userTracking"));

    userTracking.browsing_history.push({
      url: window.location.href,
      date: new Date().toJSON(),
    });

    ls.set("userTracking", JSON.stringify(userTracking));
  } else {
    const ipAddress = await (
      await fetch("https://api64.ipify.org?format=json")
    ).json();
    const browser = navigator.userAgent;
    const userTracking = {
      ip: ipAddress.ip,
      browser: browser,
      browsing_history: [
        {
          url: window.location.href,
          date: new Date().toJSON(),
        },
      ],
    };

    ls.set("userTracking", JSON.stringify(userTracking));
  }

  // if (Object.keys(userTracking).length > 0) {
  //   return new Promise((resolve, reject) => {
  //     api
  //       .post(`api/serverResources/userTraking`, userTracking)
  //       .then((response) => {
  //         console.log(response.data);
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }
};

export default userTraking;
