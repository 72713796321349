import Icon from "@mdi/react";
import { mdiOffer, mdiCached, mdiViewList } from "@mdi/js";
import Categories from "./categories";

const NavBar = ({ navbarClass = "" }) => {
  const buttonClass = "button button--primary bkg-light-black d-flex d--middle d--center fw-700 d--gap-1 pdd-2 b-s br-s w-0";
  const optionClass = "navbar__option d-flex__column--12 d-flex__column--6!tn d-flex__column--4!sm d-flex__column--3!md d-flex__column--2!lg";

  const onMouseLeaveOfOption = () => {
    const navbarAllCategories = document.querySelectorAll(".navbar__category");
    const navbarSubcategories = document.querySelector(".navbar__subcategories");

    if (navbarSubcategories) {
      navbarSubcategories.classList.add("navbar__subcategories--hidden");
    }

    if (navbarAllCategories.length > 0) {
      navbarAllCategories.forEach((category) => { category.classList.remove("navbar__category--selected") });
    }
  }

  const handleOnShowCategories = () => {
    const domCategories = document.querySelector(".navbar__categories");

    if (domCategories) {
      const visibleClass = "navbar__categories--visible";

      if (domCategories.classList.contains(visibleClass)) {
        domCategories.classList.remove(visibleClass);
      } else {
        domCategories.classList.add(visibleClass);
      }
    }
  }

  return (
    <div className={`navbar ${navbarClass}`}>
      <div className="navbar__inner d-flex">
        <div
          onMouseLeave={onMouseLeaveOfOption}
          className={optionClass}>
          <button
            onClick={handleOnShowCategories}
            className={buttonClass}
            type="button"
            title="ver categorías">
            <Icon path={mdiViewList} size={1} />
            Todas las categorías
          </button>

          <Categories />
        </div>

        <div className={optionClass}>
          <button
            className={buttonClass}
            type="button"
            title="ver ofertas">
            <Icon path={mdiOffer} size={1} />
            Ofertas
          </button>
        </div>

        <div className={optionClass}>
          <button
            className={buttonClass}
            type="button"
            title="próximamente">
            <Icon path={mdiCached} size={1} />
            Próximamente
          </button>
        </div>
      </div>
    </div>
  )
}

export default NavBar;
