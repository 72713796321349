import React, { useState } from "react";
import api from "../../../services/api";
import ls from "../../../services/ls";
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import config from "../../../config/config";

const ProfileOrderItemSelected = ({ listProducts }) => {
  const [rating, setRating] = useState(0);

  const status = [
    "Alistándose en tienda",
    "Recepcionado en la oficina de envíos",
    "Ha sido enviado",
    "Ha sido recibido",
  ];
  //restructuracion de la data por cada tienda
  const productsByShop = Object.values(
    listProducts.products.reduce((shops, product) => {
      const shopId = product.shop._id;
      const shopName = product.shop.shop_name;
      // Verificar si la tienda ya está en el objeto de tiendas
      const existingShop = shops.find((shop) => shop._id === shopId);
      if (!existingShop) {
        // Si la tienda no está en el array, crear una nueva entrada
        shops.push({
          _id: shopId,
          shop_name: shopName,
          products: [product],
        });
      } else {
        // Agregar el producto a la tienda correspondiente
        existingShop.products.push(product);
      }
      return shops;
    }, [])
  );

  const alertMessageRating = (message, valueRating, shopId) => {
    const thankYouMessage = document.getElementById('thankYouMessage')
    thankYouMessage.textContent = message
    thankYouMessage.style.display = 'block'
    setTimeout(() => {
      thankYouMessage.classList.add('fade-out')
      saveDataRating(valueRating, shopId);
    }, 1000)

    setTimeout(() => {
      thankYouMessage.style.display = 'none'
      thankYouMessage.classList.remove('fade-out')
    }, 2000)
  }

  //actualizacion de la data rating
  const saveDataRating = (valueRating,shopId) => {
    const accountData = JSON.parse(ls.get('accountData'))
    const data = {
      score: valueRating,
      user: accountData.user._id,
    }

    return new Promise((resolve, reject) => {
      api
        .put(`api/shop/rating/${shopId}`, data,config.ssToken ,config.sessionId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  
  //click de votacion de las estrellas
  const handleStarClick = (valueRating, shopId) => {
    alertMessageRating('¡Gracias por tu calificación!', valueRating, shopId);
    setRating(valueRating);
  }

  //pintado de estrellas al pasar el mouse
  const setHoveredRating = (index) => {
    setRating(index);
  };

  return (
    <div className="order__section pdd-4 mrg-b-4 mrg-b-8!md b-t-m bc-black">
      <div className="order__header d-flex d--between mrg-b-5">
        <div className="order__heading fs-m">
          <span className="fs-n mrg-r-1">🛍️</span>
          <span>{`PEDIDO N° ${listProducts.order_number}`}</span>
        </div>

        {/* <div className='order__button'>
          <button
            className='button button--primary bkg-yellow color-black'
            type='button'
            title='order-view-file'
          >
            VER BOLETA DEL PEDIDO
          </button>
        </div> */}
      </div>

      <div className="order__content d-flex d--gap-5">
        <div className="d--gap-2 d-grid order__content-detail">
          <div className="order__content d-flex d--gap-5">
              {productsByShop.map((shop, shopIndex) => (
                <div  className="d--gap-2 d-grid d-grid__column--1fr!tn d-grid__column--2fr!lg d-grid__column--2fr!md d-grid__column--2fr!sm order__content-detail" key={shopIndex}>
                  {shop.products.map((variant, index) => (
                    <div
                      className={`d--gap-1 d-flex d-flex--column ${index}`}
                      key={index}
                    >
                      <div className="d-flex d-flex--column  fs-n order__content-title ta-center">
                        <span>📦 {variant.variant.variant_name}</span>
                        <span className="color-silver">
                          ({variant.variant.product_code})
                        </span>
                      </div>
                      <div>
                        <div className="w-0">TIENDA</div>
                        <span>
                          Vendido y entregado por:
                          <a
                            href={`/shops/tienda/${shop.shop_name}/${shop._id}`}
                            className="cr--pointer mrg-l-1 td-underline"
                          >
                            {shop.shop_name}
                          </a>
                        </span>
                      </div>
                      <div>
                        <span>Marca: {variant.variant.brand}</span>
                      </div>
                      <div>
                        <div>Fecha estimada de llegada:</div>
                        <div>El 20-23 de marzo (8:00 a 20:00)</div>
                      </div>
                      <div>
                        <div className="h4">Estado:</div>
                        {status.map((state, key) => (
                          <div
                            key={key}
                            className="d-flex d--middle d--gap-1 p1 mrg-v-2"
                          >
                            <span
                              className={` order__content-state ${
                                key === 0 &&
                                listProducts.status[0].type === "pending"
                                  ? "bkg-green"
                                  : ""
                              }`}
                            >
                              {key + 1}
                            </span>{" "}
                            {state}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  <div className="b-xl pdd-2">
                    <p>¡Tu opinión es valiosa!</p>
                    <span>
                      Comparte tu experiencia con {shop.shop_name} y ayuda a otros
                      compradores como tú a tomar la mejor decisión.
                    </span>
                    <div className="cr--pointer d--middle d-flex star-rating w-5">
                      {[1, 2, 3, 4, 5].map((index) => (
                        <div
                          key={index}
                          className={`star ${index <= rating ? "filled" : ""}`}
                          onMouseEnter={() => setHoveredRating(index)}
                          onClick={() => handleStarClick(index, shop._id)}                          
                          style={{
                            color: index <= rating ? "#f1c40f" : "#bbb",
                          }}
                        >
                          <Icon
                            className="star-icon"
                            path={mdiStar}
                            size={1.5}
                          />
                        </div>
                      ))}
                      <div
                        id="thankYouMessage"
                        className="menssage-rating w-9"
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileOrderItemSelected;
