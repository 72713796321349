const ChkShippingAddress = ({ address }) => {
  return (
    <div className="chk-shipping-address">
      <div className="d-flex d-flex--column d--gap-1">
        <span>Dirección completa: {address.full_address}</span>
        <span>
          Ubicación: {address.department}<span className="color-silver">[Dep]</span> - {address.province}<span className="color-silver">[Prov]</span> - {address.district}<span className="color-silver">[Dist]</span>
        </span>
        <span>Ubigeo: {address.ubigeo}</span>
      </div>
    </div>
  );
}

export default ChkShippingAddress;
