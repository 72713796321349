const ChkResumeShipping = ({ subTotalCosts }) => {
  const allSubTotalCosts = Array.from(subTotalCosts).reduce((sum, cost) => cost + sum, 0);

  return (
    <div className="chk-resume-shipping mrg-t-2">
      <div className="d-grid d-grid__column--2fa pdd-v-1">
        <p className="mrg-0 fw-700">Costo de envío: </p>
        <p className="mrg-0">S/{(allSubTotalCosts).toFixed(2)}</p>
      </div>
    </div>
  )
}

export default ChkResumeShipping;