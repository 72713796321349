import { useState } from 'react';
import Field from "../snippets/field";
import Loading from '../snippets/loading';
import authFn from "./authFunctions";
import session from '../services/session';
import jwtDecode from '../scripts/jwt-decode';

const Login = ({ setUpdate, handleOnRestorePassword, handleOnShowRegister,onSessionChange }) => {
  const inputClass = "input input--outline b-s w-0";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ state: false, message: "" });

  const handleOnLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError({ state: false, message: "" });
    setUpdate(true);

    authFn.login(username, password).then(response => {
      const { exp, iat, ...restAccountData } = jwtDecode(response.token);

      setIsLoading(false);
      setUpdate(false);
      session.signIn(restAccountData, response.token);
      onSessionChange(); 
    }).catch((error) => {
      if (error.response.data.message.includes("invalid")) {
        setError({ state: true, message: "Lo sentimos, parece que el nombre de usuario o la contraseña son incorrectos. Por favor, verifica tus datos e inténtalo nuevamente." })
      } else if (error.response.data.message === "unauthenticated account") {
        setError({ state: true, message: "Para completar el proceso, necesitamos verificar tu correo electrónico. Por favor, revisa tu bandeja de entrada y confirma tu dirección de correo electrónico para continuar." });
      } else {
        setError({ state: true, message: "Lo sentimos, en estos momentos nuestros servicios se encuentran en mantenimiento, vuelve a intentarlo más tarde." });
      }
      setIsLoading(false);
    });
  }

  return (
    <div className="login">
      <h3 className='ta-center'>Inicio de sesión</h3>

      <form onSubmit={handleOnLogin}>
        <Field label={"Usuario:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input className={inputClass} type="text" placeholder="usuario..." value={username} onChange={(e) => setUsername(e.target.value)} required />
        </Field>

        <Field label={"Contraseña:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input className={inputClass} type="password" placeholder="☼☼☼☼" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </Field>

        <button className="button button--primary w-0" type="submit" title='Inicio de sesión'>
          {
            !isLoading ? "Iniciar sesión" : <Loading />
          }
        </button>
      </form>

      <div className='mrg-t-4 fs-n ta-center'>
        <span className='mrg-r-2'>¿Aún no tienes una cuenta?</span>

        <button
          onClick={handleOnShowRegister}
          className='button button--outline bkg-transparent color-black pdd-0 b-none fw-700'
          type="button"
          title='Registrar'>
          Regístrate
        </button>
      </div>

      <div className='ta-center'>
        <button
          onClick={handleOnRestorePassword}
          className='button button--outline bkg-transparent fs-s color-black pdd-0 b-none'
          type="button"
          title='Restaurar contraseña'>
          Olvide mi contraseña
        </button>
      </div>

      {
        error.state && (
          <div className='mw-10 w-0 bkg-whatermark pdd-2 b-xs mrg-t-4 color-red-whatermark ta-center'>
            {error.message}
          </div>
        )
      }
    </div >
  );
}

export default Login;
