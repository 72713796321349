import Carousel from "./carousel";
import ls from "../services/ls";
import clearTextString from "../scripts/clear-text-string";
import imgFn from "../scripts/image-functions";

const HomeCategories = () => {
  const listCategories = ls.get("listCategories") ? JSON.parse(ls.get("listCategories")) : [];
  const categoryClass = "home-categories__category pos--relative d-flex h-0 b-n br-n of--hidden eff-color--secondary";

  return (
    <div className="home-categories pdd-v-5">
      {
        listCategories.length > 0 && (
          <Carousel
            containerClass='home-categories__slider'
            controlContainerClass='home-categories__controls'
            controlsClass='home-categories__nav-control'
            v1440Items={4}
            v1024Items={3}
            v750Items={2}
            v480Items={1}
            vDefaultItems={1}
            autoplay={true}
            loop={true}
            speed={500}>
            <div className="home-categories__slider d-flex d-flex--no-wrap">
              {
                listCategories.map((category, index) => (
                  <a
                    key={index}
                    href={`/productos/${category.name}`}
                    className={`${categoryClass} ${(index + 1) !== listCategories.length && "mrg-r-3"}`}>
                    <img
                      onError={(e) => imgFn.onErrorLoadingImage(e)}
                      className="w-0 mw-0 h-10 img--cover img--center eff-zoom--10"
                      alt={category.name}
                      src={require(`../assets/slider-categories/${clearTextString(category.name)}.jpg`)} />

                    <div className="pos--absolute pos--bottom color-white bkg-black-rgba-5 pdd-v-2 fs-c fs-m!md w-0 ta-center">{category.name}</div>
                  </a>
                ))
              }
            </div>
          </Carousel>
        )
      }

    </div>
  );
}

export default HomeCategories;
