const Message = ({ text, addClassText = "", actions = false, handleOnCancel = () => { }, handleOnAccept = () => { } }) => {
  return (
    <div className="popup-message">
      <div className="popup-message__inner">
        <div className={`popup-message__text ${addClassText}`}>
          {text}
        </div>

        {
          actions && (
            <div className="popup-message__cta">
              <button className='button button--outline color-primary w-0 mw-3' type='button' name="cancel" onClick={handleOnCancel}>Cancelar</button>
              <button className='button button--outline bc-green color-green w-0 mw-3' type='button' name="accept" onClick={handleOnAccept}>Aceptar</button>
            </div>
          )
        }
      </div>
    </div>
  );
}

const popup = {
  Message,
}

export default popup;