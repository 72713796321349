import React from 'react'
import HomeShopCard from './home-shop-card'

const PromotionsShop = ({ data, datapromotions, uniqueClass }) => {
  return (
    <section className='home-promotions-text-and-products bkg-white mrg-b-6'>
      <div className='pdd-v-6 pdd-h-4'>
        <article className='d-flex d-flex--column d--center ta-center d--gap-4'>
          <h2 className='fw-700 fs-xb mrg-0'>{data.heading}</h2>

          <p className='callout-text-and-products__text mrg-0'>{data.text}</p>

          <div className='d-flex d--center'>
            <a href={data.url} className='button button--primary mrg-b-4'>
              {data.textButton}
            </a>
          </div>
        </article>

        {
          datapromotions && datapromotions.shops ? (
            <HomeShopCard
              filtershop={datapromotions.shops}
              uniqueClass={uniqueClass}
            />
          ) : (
            <p>Cargando productos...</p>
          )
        }
      </div>
    </section>
  )
}

export default PromotionsShop;
