import axios from "axios";
import config from "../config/config";

const headers = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": "",
    "secretKey": "",
  }
}

const get = (uri, token = "", secretKey = "") => {
  if (token && token !== "") {
    headers.headers.Authorization = `Bearer ${token}`;
    headers.headers.secretKey = secretKey;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(config.serverUrl + uri, headers);
      const data = response.data;

      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

const post = (uri, requestData, token = "", secretKey = "") => {
  if (token && token !== "") {
    headers.headers.Authorization = `Bearer ${token}`;
    headers.headers.secretKey = secretKey;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(config.serverUrl + uri, requestData, headers);
      const data = response.data;

      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

const put = (uri, requestData, token = "", secretKey = "") => {
  if (token && token !== "") {
    headers.headers.Authorization = `Bearer ${token}`;
    headers.headers.secretKey = secretKey;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(config.serverUrl + uri, requestData, headers);
      const data = response.data;

      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

const remove = (uri, token = "", secretKey = "") => {
  if (token && token !== "") {
    headers.headers.Authorization = `Bearer ${token}`;
    headers.headers.secretKey = secretKey;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(config.serverUrl + uri, headers);
      const data = response.data;

      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

const api = { get, post, put, remove };

export default api;
