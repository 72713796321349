import React, { useState, useEffect } from "react";
import Product from "./product";
import UseWindowSize from "../scripts/window-size";
import Carousel from "./carousel";

const PromotionsProducts = ({
  position,
  categoryName,
  data,
  dataProduct,
  handleAddToCart,
  handleAddToList,
  handleRemoveOfFavorites,
}) => {
  const [controls, setControls] = useState(true);
  const screen = UseWindowSize();

  useEffect(() => {
    if (
      (dataProduct.length <= 4 && screen.width >= 1440) ||
      (dataProduct.length <= 3 && screen.width >= 1024) ||
      (dataProduct.length <= 2 && screen.width >= 750) ||
      (dataProduct.length < 2 && screen.width < 750) 
    ) {
      setControls(false);
    } else {
      setControls(true);
    }
  }, [screen, dataProduct]);

  return (
    <section className="bkg-white home-promotions-text-and-products mrg-b-6 pdd-h-5">
      <div className="home-promotions-text-and-products__inner">
        <article className="pdd-r-1 b-r-m d--center d--gap-4 d-flex d-flex--column w-8!lg mrg-v-6">
          <h2 className="fw-700 mrg-0 ta-center">
            {data.heading}
            <span className="ft-upper">{categoryName}</span>
          </h2>

          <p className="callout-text-and-products__text mrg-0">{data.text}</p>

          <div className="d-flex d--center">
            <a
              href={`/productos/${categoryName}`}
              className="button button--primary"
            >
              {data.textButton}
            </a>
          </div>
        </article>
        {dataProduct.length > 0 && (
          <aside className="promotion-products mrg-b-2">
            <div className="promotion-products__container bkg-white">
              <Carousel
                containerClass={`promotion__slider--${position}`}
                controlContainerClass={`promotion__controls--${position}`}
                controlsClass={`promotion__nav-control--${position}`}
                v1440Items={4}
                v1024Items={3}
                v750Items={2}
                v480Items={2}
                vDefaultItems={1}
                speed={500}
                showControls={controls}
                autoWidth={false}
                controlsStyles="smallCircle bkg-black"
              >
                <ul
                  className={`promotion__slider--${position} d-flex d-flex--no-wrap d--gap-2 mrg-v-2`}
                >
                  {dataProduct.map((product, index) => (
                    <Product
                      key={index}
                      handleAddToCart={handleAddToCart}
                      dataVariant={product.first_variant}
                      handleAddToList={handleAddToList}
                      handleRemoveOfFavorites={handleRemoveOfFavorites}
                    />
                  ))}
                </ul>
              </Carousel>
            </div>
          </aside>
        )}
      </div>
    </section>
  );
};

export default PromotionsProducts;
