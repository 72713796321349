import { useState, useEffect, Fragment } from 'react';
import ProfileOrderItem from './orders/profile-order-item';
import ProfileOrderItemSelected from './orders/profile-order-item-selected';
import Loading from '../../snippets/loading';
import profileFn from './profileFunctions';

const ProfileOrder = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [listProducts, setListProducts] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    profileFn.fetchOrders(userId).then((response) => {
      setOrders(response);
      setIsLoading(false);
    });
  }, [userId]);

  useEffect(() => {
    if (orders.length > 0 && orders[0].products.length > 0) {
      setListProducts({status:orders[0].status, order_number: orders[0].order_number, products: orders[0].products });
    }
  }, [orders]);

  const handleViewDetail = (status,order_number, products,) => {
    setListProducts({ order_number, products,status });
    
  }
  return (
    <div className='order'>
      {
        isLoading ? (
          <Loading />
        ) : (
          <Fragment>
          {orders.length > 0 ? (
            <div className='order__section order__section--top mrg-b-4 mrg-b-8!md'>
              <table className='order__resume'>
                <thead>
                  <tr>
                    <th className='h4'>PEDIDO</th>
                    <th className='h4'>PRODUCTOS</th>
                    <th className='h4'>CANTIDAD</th>
                    <th className='h4'>ESTADO</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <ProfileOrderItem key={order._id} order={order} handleViewDetail={handleViewDetail} />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No tienes órdenes de compra</p>
          )}
        
          {listProducts && <ProfileOrderItemSelected listProducts={listProducts} />}
        </Fragment>
        
        )
      }
    </div >
  )
}

export default ProfileOrder;