import api from "../services/api";
import config from "../config/config";
import common from "../scripts/common";
import session from "../services/session";

const closeMenu = () => {
  const hideMenu = document.querySelector('.side-menu__profile');
  const menuContainer = hideMenu.querySelector('.side-menu__container');

  if (hideMenu && menuContainer) {
    hideMenu.classList.add('hidden');
    menuContainer.removeAttribute('style');
  }
}

const login = (username, password) => {
  return new Promise((resolve, reject) => {
    api.post(`service/login/`, { username, password })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const verifySession = () => {
  if (!config.ssToken) {
    session.signOff(false);
    console.log("no session");
    return
  }

  return new Promise((resolve, reject) => {
    api.post(`service/login/session`, {}, config.ssToken, config.sessionId)
      .then(response => {
        console.log(response.data.message);
        resolve(response.data);
      })
      .catch(error => {
        session.signOff(false);
        common.removeAllUrlValues();
        reject(error);
      });
  });
}

const findUsername = (username) => {
  return new Promise((resolve, reject) => {
    api.get(`api/account/findUsername/${username}`, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const accountRegistration = (data) => {
  return new Promise((resolve, reject) => {
    api.post(`api/account`, data, "")
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const authFn = {
  closeMenu,
  login,
  verifySession,
  findUsername,
  accountRegistration,
}

export default authFn;