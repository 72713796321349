import { useState } from 'react';
import Icon from '@mdi/react';
import { mdiClose, mdiPhoneDial, mdiPlus } from '@mdi/js';
import Field from "../snippets/field";
import Loading from '../snippets/loading';
import authFn from "./authFunctions";
import common from '../scripts/common';

const Register = ({ setViewRegister }) => {
  const inputClass = "input input--outline b-s w-0";
  const liClass = "list-inside mrg-l-1";
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phones, setPhones] = useState([]);
  const [validatePassword, setValidatePassword] = useState(false);
  const [validatedRequirements, setValidatedRequirements] = useState(false);
  const [validationAlerts, setValidationsAlerts] = useState({ hasMayus: false, hasNumber: false, hasSpecialChar: false });
  const [error, setError] = useState({ state: false, message: "" });

  const validateRequirements = (password) => {
    const hasMayus = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

    setValidatePassword(true);
    setValidationsAlerts({ hasMayus, hasNumber, hasSpecialChar });

    if (hasMayus && hasNumber && hasSpecialChar) {
      setValidatedRequirements(true);
      setValidatePassword(false);
    } else {
      setValidatedRequirements(false);
    }

    return password;
  }

  const handleOnAddPhone = () => {
    setPhones([...phones, phone]);
    setPhone("");
  }

  const handleOnRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError({ state: false, message: "" });

    const data = {
      username: username,
      password: password,
      name: name,
      last_name: lastname,
      email: email,
      profile: {
        phones: phones,
      }
    }

    authFn.accountRegistration(data).then(() => {
      setIsLoading(false);
      common.showNotification("Registro exitoso");
      setViewRegister(false);
      clearValues();

      setTimeout(() => {
        common.showNotification("¡Por favor, verifica tu correo electrónico para activar tu cuenta!");
      }, 2000);
    }).catch((error) => {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.errors && error.response.data.error.errors.username) {
        setError({ state: true, message: "¡El nombre de usuario que has ingresado ya está en uso! Por favor, elige otro nombre que esté disponible para continuar." });
      } else {
        setError({ state: true, message: "Se ha producido un error inesperado. Por favor, inténtalo de nuevo más tarde." });
      }

      setIsLoading(false);
    });
  }

  const clearValues = () => {
    setUsername("");
    setPassword("");
    setName("");
    setLastname("");
    setEmail("");
    setPhone("");
    setPhones([]);
  }

  return (
    <div className="register">
      <h3 className='ta-center'>Registro</h3>

      <form onSubmit={handleOnRegister}>
        <Field label={"Nombre de Usuario:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input
            onChange={(e) => setUsername(e.target.value)}
            className={inputClass}
            type="text"
            value={username}
            placeholder="usuario..."
            autoComplete="off"
            required />
        </Field>

        <Field label={"Contraseña:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input
            onChange={(e) => setPassword(validateRequirements(e.target.value))}
            className={inputClass}
            type="password"
            value={password}
            placeholder="☼☼☼☼"
            autoComplete="off"
            minLength={6}
            maxLength={18}
            required />
        </Field>

        {
          validatePassword && (
            <div className='bkg-whatermark pdd-v-2 pdd-h-1 mrg-v-2 mrg-b-4 color-red fs-s'>
              <ul>
                {
                  !validationAlerts.hasMayus && <li className={liClass}>La contraseña no contiene mayúscula</li>
                }
                {
                  !validationAlerts.hasNumber && <li className={liClass}>La contraseña no contiene número</li>
                }
                {
                  !validationAlerts.hasSpecialChar && <li className={liClass}>La contraseña no contiene carácter especial</li>
                }
              </ul>
            </div>
          )
        }

        <Field label={"Nombres:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input
            onChange={(e) => setName(e.target.value)}
            className={inputClass}
            type="text"
            value={name}
            placeholder="nombres..."
            autoComplete="off"
            required />
        </Field>

        <Field label={"Apellidos:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input
            onChange={(e) => setLastname(e.target.value)}
            className={inputClass}
            type="text"
            value={lastname}
            placeholder="apellidos..."
            autoComplete="off"
            required />
        </Field>

        <Field label={"Correo:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className={inputClass}
            type="email"
            value={email}
            placeholder="correo@..."
            autoComplete="off"
            required />
        </Field>

        <Field label={"Teléfono/Celular:"} addClassLabel={"color-silver"} addClass={"mrg-b-4"}>
          <div className='d-flex d--gap-2 d-flex--no-wrap'>
            <input
              onChange={(e) => common.validateNumber(e, setPhone)}
              className={inputClass}
              type="text"
              value={phone}
              placeholder="99999..."
              autoComplete="off"
              minLength={8}
              maxLength={12} />

            <button
              onClick={handleOnAddPhone}
              className="button button--outline bc-green-2 color-green-2 mw-1 w-0 pdd-0"
              type="button"
              title="agregar teléfono">
              <Icon path={mdiPlus} size={1} className='mrg-auto' />
            </button>
          </div>

          <div className='d-flex d--gap-2 mrg-v-2'>
            {
              phones.map((p, index) => (
                <span key={index} className='d-flex d--gap-1 d--middle pdd-1 b-s br-n bkg-light-black color-white'>
                  <Icon path={mdiPhoneDial} size={0.6} />
                  {p}

                  <button onClick={() => setPhones(phones.filter((_, key) => key !== index))} className='bkg-transparent b-none' type='button' title='borrar número'>
                    <Icon path={mdiClose} size={0.6} className='color-red' />
                  </button>
                </span>
              ))
            }
          </div>
        </Field>

        {
          error.state && (
            <div className='mw-10 w-0 bkg-whatermark pdd-2 b-xs mrg-v-4 color-red-whatermark ta-center'>
              {error.message}
            </div>
          )
        }

        <button className={`button button--primary bkg-green-2 w-0 mrg-b-4 ${validatedRequirements ? "enable" : "disabled"}`} type="submit" title='Registro'>
          {
            !isLoading ? "Registrarse" : <Loading imgHeight='30' />
          }
        </button>

        <button onClick={() => setViewRegister(false)} className="button button--outline w-0" type="button" title='Cancelar'>
          Cancelar
        </button>
      </form>
    </div>
  );
}

export default Register;
