import { useState } from "react";
import Icon from "@mdi/react";
import { mdiCart as iconCart, mdiTrashCan as trash } from "@mdi/js";
import imgFn from "../../scripts/image-functions";
import onImageRescaling from "../../scripts/imageRescaling";

const ChkProductFavorite = ({ handleOnProductFavoriteRemove, variant, isSelected, onSelectItem}) => {

  const handleCheckboxChange = () => {
    onSelectItem(variant._id);
  };

  return (
    <li
      className={`chk-product-tile bkg-white b-n br-n d-grid d-grid__column--2fa d--middle`}
    >
      <div className="d--gap-1 d--middle d-grid d-grid__column--1af!tn d-grid__column--4af!lg d-grid__column--4af!md d-grid__column--4af!sm fs-c fs-n!sm pdd-2">
        <input
          type="checkbox"
          id={`chk-product__Favorite-${variant._id}`}
          checked={isSelected} // Establece el estado del checkbox
          onChange={handleCheckboxChange}
        />
      <div className="d--center d-flex">
          <img
            className="w-3"
            onError={imgFn.onErrorLoadingImage}
            src={variant.images.length > 0 && onImageRescaling(variant.images[0].imageUrl, 200)}
            alt={
              variant.images.length > 0
                ? variant.images[0].nameImage
                : ""
            }
          />
        </div>

        <div className="d-flex d-flex--column d--gap-1">
          <p className="d-flex d--center d--gap-1 mrg-0 ft-upper fw-700 ta-center">{variant.product_name + " " + variant.variant_name}
          </p>

          <div className="d-flex d--center d--gap-3">
          {variant.offer && variant.offer.value && (
          <span className="br-n chk-product-tile__offer pdd-h-6 w-1">
            {variant.offer.value &&
              ("-" + variant.offer.symbol === "%"
                ? variant.offer.value + variant.offer.symbol
                : variant.offer.symbol +
                  variant.offer.value.toFixed(2))}
          </span>
        )}
            <span
              className={`${
                variant.offer && variant.offer.value
                  ? "td-through color-silver"
                  : ""
              }`}
            >
              PU: S/{variant.price.toFixed(2)}
            </span>
            
            {variant &&
              variant.offer &&
              variant.offer.value && (
                <span>PU S/${(variant.price - (variant.offer.value / 100) * variant.price).toFixed(2)}</span>

              )}
          </div>
        </div>

        <button
          onClick={handleOnProductFavoriteRemove}
          className="button button--outline pdd-0 mrg-l-auto h-1"
          title="eliminar"
          type="button"
          data-id={variant._id}
        >
          <Icon path={trash} size={1} />
        </button>
      </div>

      
    </li>
  );
};

export default ChkProductFavorite;
