import { useState } from "react";
import ChkProductTile from "./chk-product-tile";
import ChkShipping from "./chk-shipping";
import Icon from "@mdi/react";
import { mdiCloseBox } from "@mdi/js";
import ChkProductFavorite from "./chk-products-favorites";
import ls from "../../services/ls";
import config from "../../config/config";

const ChkContent = ({
  cartVariants,
  handleOnProductRemove,
  setUpdate,
  info,
  sendTo,
  setSendTo,
  addToCart,
  updateStockCartVariants,
}) => {
  const [favoritesStorage, setFavoritesStorage] = useState(
    config.favoriteVariants
  );
  // Recuperando la lista de productos favoritos del localStorage
  const [selectAllFavorites, setSelectAllFavorites] = useState(false);
  const [selectedItemsFavorites, setSelectedItemsFavorites] = useState([]);
  const [selectAllCartProduct, setSelectAllCartProduct] = useState(false);
  const [selectedItemsCartProduct, setSelectedItemsCartProduct] = useState([]);

  const handleOnCloseResume = () => {
    const resume = document.querySelector(".chk-content");
    if (resume) {
      resume.removeAttribute("style");
    }
  };

  //agregar a favoritos
  const handleSelectAllFavorites = () => {
    if (selectAllFavorites) {
      // Deseleccionar todos los productos si ya estaban seleccionados
      setSelectedItemsFavorites([]);
    } else {
      // Filtrar los productos favoritos que no están en el carrito de compras
      const newSelectedItems = favoritesStorage.favorites
        .filter(
          (variant) =>
            !cartVariants.cart.some(
              (cartItem) => cartItem.item._id === variant.item._id
            )
        )
        .map((variant) => variant.item._id);

      // Mostrar una alerta si no se encontraron productos
      if (newSelectedItems.length === 0) {
        alert(
          "Todos los productos de la lista de favoritos ya existen en el carrito de compras."
        );
        selectAllFavorites(false);
      }

      setSelectedItemsFavorites(newSelectedItems);
    }
    setSelectAllFavorites(!selectAllFavorites);
  };

  const handleAddSelectedToCart = () => {
    // Crear una colección de productos seleccionados
    const selectedProducts = [];

    // Itera sobre los IDs de los productos seleccionados
    selectedItemsFavorites.forEach((selectedItemId) => {
      // Buscar el producto correspondiente en favoritesStorage
      const selectedProduct = favoritesStorage.favorites.find(
        (product) => product.item._id === selectedItemId
      );
      // Verificar si se encontró el producto
      if (selectedProduct) {
        // Agregar el producto a la colección de productos seleccionados
        selectedProducts.push(selectedProduct);
      }
    });

    addToCart(selectedProducts);
    setSelectAllFavorites(false);
  };

  const handleToggleItemFavorites = (itemId) => {
    if (selectedItemsFavorites.includes(itemId)) {
      setSelectedItemsFavorites(
        selectedItemsFavorites.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsFavorites([...selectedItemsFavorites, itemId]);
    }
  };

  ///agregar a carrito de compras 
  const handleSelectAllCartProducts = () => {
    if (selectAllCartProduct) {
      // Deseleccionar todos los productos si ya estaban seleccionados
      setSelectedItemsCartProduct([]);
    } else {
      // Seleccionar solo los productos que no están en la lista de favoritos
      const newSelectedItems = cartVariants.cart
        .filter(
          (variant) =>
            !favoritesStorage.favorites.some(
              (favorite) => favorite.item._id === variant.item._id
            )
        )
        .map((variant) => variant.item._id);

      if (newSelectedItems.length === 0) {
        alert(
          "Todos los productos de la lista de carrito de compras ya existen en la lista de favoritos."
        );
        setSelectAllCartProduct(false);
      }
      setSelectedItemsCartProduct(newSelectedItems);
    }
    // setSelectAllCartProduct(!selectAllCartProduct);
  };

  const handleAddSelectedToFavorite = () => {
    // Filtrar los productos seleccionados que no están en la lista de favoritos
    const selectedProductsNotInFavorites = selectedItemsCartProduct.filter(
      (itemId) =>
        !favoritesStorage.favorites.some(
          (favorite) => favorite.item._id === itemId
        )
    );

    // Crear una lista de productos actualizada para agregar a la lista de favoritos
    const updatedFavorites = [
      ...favoritesStorage.favorites,
      ...selectedProductsNotInFavorites.map((itemId) => ({
        item: cartVariants.cart.find((product) => product.item._id === itemId)
          .item, // Obtener la información completa del producto
        qty: 1,
        // Agregar otros campos necesarios aquí
      })),
    ];
    // Actualizar el estado de favoritesStorage con los nuevos productos
    setFavoritesStorage({ favorites: updatedFavorites });
    // Actualizar el almacenamiento local con el nuevo estado de la lista de favoritos
    ls.set("favorites", JSON.stringify({ favorites: updatedFavorites }));
    setSelectedItemsCartProduct([]);
    setSelectAllCartProduct(false);
  };

  const handleToggleItemCartProduct = (itemId) => {
    if (selectedItemsCartProduct.includes(itemId)) {
      setSelectedItemsCartProduct(
        selectedItemsCartProduct.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsCartProduct([...selectedItemsCartProduct, itemId]);
    }
  };

  const handleOnProductFavoriteRemove = (e) => {
    const productId = e.currentTarget.dataset.id;
    // Filtrar los productos favoritos para mantener solo aquellos que no coincidan con el ID del producto que se va a eliminar
    const updatedFavorites = favoritesStorage.favorites.filter(
      (favorite) => favorite.item._id !== productId
    );
    // Actualizar el estado de favoritesStorage con la nueva lista de favoritos filtrada
    setFavoritesStorage({ favorites: updatedFavorites });
    ls.set("favorites", JSON.stringify({ favorites: updatedFavorites }));
  };


  

  return (
    <section className="chk-content">
      <div className="chk-content__inner pdd-2 br-n fs-c fs-n!sm h-0">
        <div className="chk-content__close">
          <button
            onClick={handleOnCloseResume}
            className="button button--outline pdd-0"
          >
            <Icon path={mdiCloseBox} size={1} />
          </button>
        </div>
        <ChkShipping info={info} sendTo={sendTo} setSendTo={setSendTo} />

        <div className="chk-content__products">
          <h2 className="w-0 ta-center">
            Carro de compras ({cartVariants.cart.length} items)
          </h2>
          {cartVariants.cart.length > 0 && (
            <div className="d--between d-flex w-0">
              <div
                className="td-underline cr--pointer"
                onClick={handleSelectAllCartProducts}
              >
                {selectAllCartProduct
                  ? "Quitar todo lo seleccionado"
                  : "Seleccionar toda la lista productos"}
              </div>
              {selectedItemsCartProduct.length > 0 && (
                <button
                  onClick={handleAddSelectedToFavorite}
                  className="bkg-transparent br-n pdd-h-2 pdd-v-1"
                  title="mover a la lista de favoritos"
                  type="button"
                >
                  Mover a la lista de favoritos
                </button>
              )}
            </div>
          )}

          <ul className="bkg-white br-n d--gap-2  pdd-2 d-grid">
            {cartVariants &&
              cartVariants.cart.length > 0 &&
              cartVariants.cart.map((variant, index) => (
                <ChkProductTile
                  key={variant.item._id}
                  handleOnProductRemove={handleOnProductRemove}
                  variant={variant}
                  dataVariant={cartVariants.cart[index]}
                  index={index}
                  isSelected={selectedItemsCartProduct.includes(
                    variant.item._id
                  )}
                  onSelectItem={handleToggleItemCartProduct}
                  updateStockCartVariants={updateStockCartVariants}
                  cartVariants={cartVariants}
                />
              ))}
          </ul>

          <div className="chk-content__products--favorite">
            <h2 className="w-0 ta-center">
              Lista de favoritos ({favoritesStorage.favorites.length} items)
            </h2>
            {favoritesStorage.favorites.length > 0 && (
              <div className="d--between d-flex w-0">
                <div
                  className="td-underline cr--pointer"
                  onClick={handleSelectAllFavorites}
                >
                  {selectAllFavorites
                    ? "Quitar todo lo seleccionado"
                    : "Seleccionar toda la lista de favoritos"}
                </div>
                {selectedItemsFavorites.length > 0 && (
                  <button
                    onClick={handleAddSelectedToCart}
                    className="bkg-transparent br-n pdd-h-2 pdd-v-1"
                    title="agregar a carrito de compras"
                    type="button"
                  >
                    Mover al carrito de compras
                  </button>
                )}
              </div>
            )}

            <ul className="bkg-white br-n d--gap-2 d-grid d-grid__column--1aut!tn d-grid__column--2fr!lg d-grid__column--2fr!md pdd-2">
              {favoritesStorage.favorites &&
                favoritesStorage.favorites.length > 0 &&
                favoritesStorage.favorites.map((variant, index) => (
                  <ChkProductFavorite
                    key={variant.item._id}
                    handleOnProductFavoriteRemove={
                      handleOnProductFavoriteRemove
                    }
                    variant={variant.item}
                    index={index}
                    isSelected={selectedItemsFavorites.includes(
                      variant.item._id
                    )}
                    onSelectItem={handleToggleItemFavorites}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChkContent;
