import { LazyLoadImage } from "react-lazy-load-image-component";
import Icon from "@mdi/react";
import { mdiStar, mdiDelete } from "@mdi/js";
import imgFn from "../scripts/image-functions";
import onImageRescaling from "../scripts/imageRescaling";

const ProductCard = ({
  products,
  handleRemoveOfFavorites,
  handleAddToCart,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    handleAddToCart(products);
  };

  // console.log("variante que llega favortios",products)

  return (
    <div className="product-card">
      <div className={`product-card__inner product-card__inner`}>
        <a
          href={`/product/${encodeURIComponent(products.variant_name).replace(
            /%20/g,
            "-"
          )}/${products._id}`}
          className=""
        >
          <div className="product-card__image-container w-5 h-5 cr--pointer">
            <div
              className={`product-card__image product-card__image`}
            >
              <LazyLoadImage
                className="eff-zoom--10"
                src={onImageRescaling(products.images[0].imageUrl, 200)}                
                alt={products.product_name}
                title={products.product_name}
                effect="blur"
                onError={(e) => imgFn.onErrorLoadingImage(e)}
              />
            </div>
          </div>

          <div className="product-card__content">
            <div className="product-card__heading h4 weight-2">
              {products.product_name}
            </div>
            <div className="product-card__info">
              <div className="product-card__prices">
                {/* <div className='product-card__price product-card__price--old'>
                      S/{' '}
                      {products.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </div> */}
                <div className="product-card__price product-card__price--new weight-2">
                  S/{" "}
                  {products.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>

              <div className="product-card__score">
                <div className="icon icon--reviews">
                  <Icon path={mdiStar} />
                </div>
                <div className="product-card__reviews">
                  <div className="product-card__rating p2">
                    {products.rating.average}
                  </div>
                  {/*
                      <div className='product-card__views p2'>
                        ({products.view})
                      </div>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="product-card__tags">
              {products.offer && (
                <div className="product-card__discount color-white">
                  -{products.offer.value}%
                </div>
              )}
            </div>
          </div>
        </a>

        <div className="product-card__buttons">
          <div className="product-card__button product-card__button--add-to-cart">
            <button
              className="button button--primary pdd-2"
              type="button"
              onClick={(e) => handleOnClick(e)}
            >
              Añadir al carrito
            </button>
          </div>
          <div
          className=" d-flex d--middle cr--pointer"
          data-id={products._id}
          onClick={handleRemoveOfFavorites}
        >
          <Icon path={mdiDelete} size={1} />
        </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
