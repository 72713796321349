import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ShopContentSkeleton = () => {
    
  return (
    
    <div className='container'>
     <SkeletonTheme baseColor="#F2F2F2" highlightColor="#FFFFFF">
      <div className='shop-container'>
        {/* Profile Component Skeleton */}
        <div className='shop-profile'>
      <div className='shop-profile__cover'>
        <div className='w-0'>
          {/* Shop Cover Image Skeleton */}
          <Skeleton height={500} width='100%' />
        </div>

        <div className='info-container'>
          <div className='info-text d--bottom'>
            <div>
              {/* Profile Image Skeleton */}
              <Skeleton circle height={200} width={200} />
            </div>
            <div className='profile-info'>
              {/* Shop Name Skeleton */}
              <h1 className='shop-name fs-h4'>
                <Skeleton width={200} />
              </h1>
            </div>
          </div>
          <div className='container-rating d--flex'>
            <div className='rating d--between d-flex  w-5'>
              {/* Star Icons Skeleton */}
              {[1, 2, 3, 4, 5].map((index) => (
                <Skeleton key={index} height={20} width={20} />
              ))}
            </div>
            <div className='likes'>
              <span>
                {/* Like Count Skeleton */}
                <Skeleton width={50} />
              </span>
            </div>
            <div className='like-container'>
              {/* Follow Button Skeleton */}
              <Skeleton width={100} height={30} />
            </div>
          </div>
        </div>
      </div>

      <div className='shop-profile__info'>
        <p className='shop-description'>
          {/* Shop Description Skeleton */}
          <Skeleton count={5} />
        </p>
      </div>
    </div>
        <div className='shop-products-container'>
          <div>
            {/* Title Skeleton */}
            <h1>
              <Skeleton width={200} />
            </h1>

            {/* Product List Skeleton */}
            <ul className='d-grid d-grid__column--2fr!sm d-grid__column--3fr!md d-grid__column--5fr!lg d--gap-2'>
              {[1, 2, 3, 4, 5].map((index) => (
                <div key={index} className='product-skeleton'>
                  {/* Product Image Skeleton */}
                  <Skeleton height={150} />

                  {/* Product Info Skeleton */}
                  <div className='product-info-skeleton'>
                    {/* Product Brand Skeleton */}
                    <Skeleton width={100} />

                    {/* Product Name Skeleton */}
                    <Skeleton width={150} />

                    {/* Add to Cart Button Skeleton */}
                    <Skeleton width={80} height={30} />
                  </div>
                </div>
              ))}
            </ul>
          </div>

          {/* Search Bar Skeleton */}
          <div className='d--between d--middle d-flex'>
            <h1>
              <Skeleton width={200} />
            </h1>
            <div className='search-bar'>
              <label>
                <Skeleton height={20} width={80} />
              </label>
              <button className='search-button h-1'
                title='Skeleton button'
                type='button'>
                <Skeleton width={20} height={20} />
              </button>
            </div>
          </div>

          {/* Tags Skeleton */}
          <div className='mrg-v-5'>
            <span className='tag br-b active'>
              <Skeleton width={200} />
            </span>
            <span className='tag br-b'>
              <Skeleton width={200} />
            </span>
          </div>

          <ul className='d-grid d-grid__column--2fr!sm d-grid__column--3fr!md d-grid__column--5fr!lg d--gap-2'>
      {Array.from({ length: 5 }, (_, index) => (
        <div key={index} className='product-skeleton'>
          {/* Product Image Skeleton */}
          <Skeleton height={150} />

          {/* Product Info Skeleton */}
          <div className='product-info-skeleton'>
            {/* Product Brand Skeleton */}
            <Skeleton width={100} />

            {/* Product Name Skeleton */}
            <Skeleton width={150} />

            {/* Add to Cart Button Skeleton */}
            <Skeleton width={80} height={30} />
          </div>
        </div>
      ))}
    </ul>
        </div>
      </div>
      </SkeletonTheme>
    </div>
  );
};

export default ShopContentSkeleton;