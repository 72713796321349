import { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import Field from "../../snippets/field";
import common from '../../scripts/common';

const ProfileInformation = ({ userInfo, updateInfo, setUpdateInfo, handleOnSubmitForm }) => {
  const inputClass = "input input--outline w-0 bkg-white";
  const fieldClass = "pdd-h-3 mrg-b-4";
  const fieldLabelClass = "mrg-b-1 color-silver";
  const [nickname, setNickname] = useState(userInfo.nickname);
  const [name, setName] = useState(userInfo.name);
  const [lastname, setLastname] = useState(userInfo.last_name);
  const [email, setEmail] = useState(userInfo.email);
  const [description, setDescription] = useState(userInfo.profile ? userInfo.profile.description : "");
  const [phone, setPhone] = useState("");
  const [phones, setPhones] = useState(userInfo.profile ? userInfo.profile.phones : []);
  const [identificationNumber, setIdentificationNumber] = useState(userInfo.profile ? userInfo.profile.identification_number : "");
  const [birthdate, setBirthdate] = useState(userInfo.profile ? common.formatDate(userInfo.profile.birthdate) : "");

  const handleOnAddPhoneToList = () => {
    if (phone) {
      setPhones([...phones, phone]);
      setPhone("");
    }
  }

  const handleOnRemovePhoneOfList = (number) => {
    const filterPhones = phones.filter((p) => p !== number);

    setPhones(filterPhones);
  }

  useEffect(() => {
    setUpdateInfo({
      nickname: nickname,
      name: name,
      last_name: lastname,
      email: email,
      profile: {
        ...updateInfo.profile,
        description: description,
        phones: phones,
        identification_number: identificationNumber,
        birthdate: birthdate,
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nickname, name, lastname, email, description, phones, identificationNumber, birthdate]);


  return (
    <div className="profile-information">
      <form onSubmit={(e) => handleOnSubmitForm(e)}>
        <div className="d-flex">
          <Field label={"Apodo:"} addClass={`${fieldClass} d-flex__column--4`} addClassLabel={fieldLabelClass}>
            <input onChange={(e) => setNickname(e.target.value)} value={nickname} className={inputClass} type="text" placeholder="..." />
          </Field>

          <Field label={"Nombres:"} addClass={`${fieldClass} d-flex__column--4`} addClassLabel={fieldLabelClass}>
            <input onChange={(e) => setName(e.target.value)} value={name} className={inputClass} type="text" placeholder="..." required />
          </Field>

          <Field label={"Apellidos:"} addClass={`${fieldClass} d-flex__column--4`} addClassLabel={fieldLabelClass}>
            <input onChange={(e) => setLastname(e.target.value)} value={lastname} className={inputClass} type="text" placeholder="..." required />
          </Field>

          <Field label={"Mi descripción:"} addClass={`${fieldClass} d-flex__column--5`} addClassLabel={fieldLabelClass}>
            <textarea onChange={(e) => setDescription(e.target.value)} value={description} className={inputClass} type="text" placeholder="descripción..." rows={5} />
          </Field>

          <div className={`${fieldClass} d-flex d-flex__column--7`}>
            <Field label={"Correo:"} addClass={`${fieldClass} d-flex__column--12`} addClassLabel={fieldLabelClass}>
              <input onChange={(e) => setEmail(e.target.value)} value={email} className={inputClass} type="email" placeholder="ejemplo@..." required />
            </Field>

            <Field label={"DNI:"} addClass={`${fieldClass} d-flex__column--4`} addClassLabel={fieldLabelClass}>
              <input onChange={(e) => common.validateNumber(e, setIdentificationNumber)} value={identificationNumber} className={inputClass} type="text" maxLength={8} placeholder="00000000" required />
            </Field>

            <Field label={"Cumpleaños:"} addClass={`${fieldClass} d-flex__column--5`} addClassLabel={fieldLabelClass}>
              <input onChange={(e) => setBirthdate(e.target.value)} value={birthdate} className={inputClass} type="date" maxLength={8} placeholder="..." />
            </Field>
          </div>

          <Field label={"Teléfono/Celular:"} addClass={`${fieldClass} d-flex__column--4`} addClassLabel={fieldLabelClass} addClassContent={"d-flex d-flex--no-wrap d--gap-2"}>
            <input onChange={(e) => common.validateNumber(e, setPhone)} value={phone} className={inputClass} type="text" maxLength={11} placeholder="000000000" />
            <button onClick={handleOnAddPhoneToList} className='button button--outline pdd-v-1 pdd-h-2 bc-text-label color-text-label' type='button' title='agregar teléfono/celular'><Icon path={mdiPlus} size={0.6} /></button>
          </Field>

          <Field addClass={`${fieldClass} d-flex__column--8`} addClassLabel={fieldLabelClass} addClassContent={"d-flex d--gap-1 mrg-t-6"}>
            {
              phones.map((p, key) => (
                <span key={key} className='br-s pdd-h-1 bkg-whatermark color-silver'>
                  {p}

                  <button onClick={() => handleOnRemovePhoneOfList(p)} className='button color-red b-none pdd-0 mrg-l-2'>X</button>
                </span>
              ))
            }
          </Field>
        </div>

        <div className='pdd-v-3'>
          <button className='button button--primary bkg-green-2 w-0' type='submit' title='guardar cambios'>Guardar</button>
        </div>
      </form>
    </div>
  );
}

export default ProfileInformation;