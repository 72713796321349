import { useState } from 'react';
import Icon from "@mdi/react";
import { mdiAlert, mdiCheck } from "@mdi/js";
import Field from "../../snippets/field";
import profileFn from './profileFunctions';
import common from '../../scripts/common';
import ls from '../../services/ls';

const ProfilePassword = ({ account = "" }) => {
  const inputClass = "input input--outline w-0 bkg-white b-s";
  const fieldClass = "d-flex__column--12 d-flex__column--5!md";
  const fieldLabelClass = "mrg-b-1 color-silver";
  const liClass = "list-inside mrg-l-1";
  const [newPassword, setNewPassword] = useState("");
  const [validateNewPassword, setValidateNewPassword] = useState("");
  const [validatedRequirements, setValidatedRequirements] = useState(false);
  const [validationAlerts, setValidationsAlerts] = useState({ hasMayus: false, hasNumber: false, hasSpecialChar: false });

  const validateRequirements = (password) => {
    const hasMayus = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

    setValidationsAlerts({ hasMayus, hasNumber, hasSpecialChar });

    if (hasMayus && hasNumber && hasSpecialChar) {
      setValidatedRequirements(true);
    } else {
      setValidatedRequirements(false);
    }

    return password;
  }

  const handleOnChangePassword = (e) => {
    e.preventDefault();

    const isRestorePassword = window.location.href.includes('/restore-password?token');

    let accountId = account._id;
    const body = {
      password: newPassword,
    }

    if (isRestorePassword) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      accountId = urlParams.get("accountId");
      ls.set("ssToken", token);
    }

    profileFn.updateAccount(accountId, body).then(() => {
      setNewPassword("");
      setValidateNewPassword("");
      common.showNotification("Contraseña actualizada");

      if (isRestorePassword) {
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className="profile-password pdd-2">
      <form onSubmit={handleOnChangePassword}>
        <div className='bkg-whatermark pdd-2 b-xs color-silver mrg-b-4'>
          <span className='fw-700'>Recomendaciones:</span>
          <ul className='mrg-v-2'>
            <li className={liClass}>Longitud mínima de 6</li>
            <li className={liClass}>Longitud máxima de 18</li>
            <li className={liClass}>Debe contener al menos una letra en mayúscula</li>
            <li className={liClass}>Debe contener al menos un número</li>
            <li className={liClass}>Debe contener al menos un carácter especial (ejemplo: @!*$#)</li>
          </ul>
        </div>

        <div className="d-flex d--gap-2">
          <Field label={"Nueva contraseña:"} addClass={fieldClass} addClassLabel={fieldLabelClass}>
            <input
              onChange={(e) => setNewPassword(validateRequirements(e.target.value))}
              className={inputClass}
              type="password"
              value={newPassword}
              placeholder='☼☼☼☼'
              minLength={6}
              maxLength={18}
              required />
          </Field>

          <Field label={"Confirmar nueva contraseña:"} addClass={fieldClass} addClassLabel={fieldLabelClass}>
            <input onChange={(e) => setValidateNewPassword(e.target.value)}
              className={inputClass}
              type="password"
              value={validateNewPassword}
              placeholder='☼☼☼☼'
              minLength={6}
              maxLength={18}
              required />
          </Field>

          <div className='d-flex d--bottom pdd-b-1'>
            {
              newPassword === validateNewPassword && newPassword !== "" ? (
                <Icon path={mdiCheck} size={1} className='color-green' />
              ) : (
                <Icon path={mdiAlert} size={1} className='color-red' />
              )
            }
          </div>
        </div>

        <div className='mrg-2 color-red fs-s'>
          <ul>
            {
              !validationAlerts.hasMayus && <li className={liClass}>La contraseña no contiene mayúscula</li>
            }
            {
              !validationAlerts.hasNumber && <li className={liClass}>La contraseña no contiene número</li>
            }
            {
              !validationAlerts.hasSpecialChar && <li className={liClass}>La contraseña no contiene carácter especial</li>
            }
          </ul>
        </div>

        <div className='mrg-t-4'>
          <button
            className={`button button--primary bkg-green-2 d-flex d--center d--middle d--gap-1 w-0 ${validatedRequirements && newPassword === validateNewPassword ? "enable" : "disabled"}`}
            type="submit"
            title="actualizar contraseña">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProfilePassword;