import { useEffect, useState, useMemo } from "react";
import { tns } from "tiny-slider/src/tiny-slider";
import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';

const Carousel = (
  {
    children,
    heading = "",
    containerClass = "carousel__slider",
    controlContainerClass = "carousel__controls",
    controlsClass = "carousel__nav-control",
    controlsStyles = "" ,
    fixedWidth = false,
    autoWidth = false,
    speed = 1500,
    dots = false,
    autoplay = false,
    showControls = true,
    loop = false,
    v1440Items = 6,
    v1024Items = 6,
    v750Items = 6,
    v480Items = 6,
    vDefaultItems = 1,
    rebuild = false,
  }
) => {
  const [tnSlider, setTnSlider] = useState();
  const tnSliderSettings = useMemo(() => {
    return {
      container: `.${containerClass}`,
      slideBy: 'page',
      mode: 'carousel',
      items: vDefaultItems,
      fixedWidth: fixedWidth,
      autoWidth: autoWidth,
      responsive: {
        480: {
          items: v480Items
        },
        750: {
          items: v750Items
        },
        1024: {
          items: v1024Items
        },
        1440: {
          items: v1440Items
        }
      },
      lazyload: true,
      autoplay: autoplay,
      mouseDrag: true,
      nav: dots,
      speed: speed,
      nested: true,
      loop: loop,
      autoplayButton: false,
      controlsContainer: `.${controlContainerClass}`,
      prevButton: `.${controlsClass}--prev`,
      nextButton: `.${controlsClass}--next`,
    };
  }, [containerClass, fixedWidth, autoWidth, vDefaultItems, v480Items, v750Items, v1024Items, v1440Items, autoplay, dots, speed, loop, controlContainerClass, controlsClass]);

  useEffect(() => {
    const initTnSlider = tns(tnSliderSettings);
    setTnSlider(initTnSlider)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rebuild) {
      tnSlider.rebuild();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rebuild]);

  return (
    <div className="carousel">
      <div className="carousel__inner">
        {
          heading !== "" && (
            <div className="fs-m pdd-v-4">
              {heading}
            </div>
          )
        }

        <div className="carousel__container">
          <div className="carousel__slider">
            {children}
          </div>

          {
            showControls && (
              <div className={controlContainerClass}>
                <button
                  type="button"
                  className={`${controlsClass} ${controlsClass}--prev carousel-default__nav-control carousel-default__nav-control--${controlsStyles}  carousel-default__nav-control--prev`}
                  title="atras">
                  <Icon className="carousel-default__nav-control--icon" path={mdiChevronLeft} size={2} />
                </button>

                <button
                  type="button"
                  className={`${controlsClass} ${controlsClass}--next carousel-default__nav-control carousel-default__nav-control--${controlsStyles} carousel-default__nav-control--next`}
                  title="siguiente">
                  <Icon className="carousel-default__nav-control--icon" path={mdiChevronRight} size={2} />
                </button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Carousel;
