const Field = ({ label, addClass, addClassInner, addClassLabel, addClassContent, children }) => {
  return (
    <div className={`field ${addClass ? addClass : ''}`}>
      <div className={`field__inner ${addClassInner ? addClassInner : ''}`}>
        {
          label && (
            <div className={`field__label ${addClassLabel ? addClassLabel : ''}`}>
              {label}
            </div>
          )
        }

        <div className={`field__content ${addClassContent ? addClassContent : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Field;
