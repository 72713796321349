import { useState, useMemo, useEffect } from 'react';
import Breadcrumbs from '../../components/breadcrumbs';
import ProfileOption from './profile-option';
import ProfileAddress from './profile-address';
import ProfileInformation from './profile-information';
import ProfileOrder from './profile-order';
import ProfilePassword from './profile-password';
// import ProfileSession from './profile-session';
import profileFn from './profileFunctions';
import ls from '../../services/ls';
import common from '../../scripts/common';
import session from '../../services/session';

const Profile = () => {
  const [accountData, setAccountData] = useState(ls.get("accountData") ? JSON.parse(ls.get("accountData")) : null)
  const [update, setUpdate] = useState(false);
  const [optionSelected, setOptionSelected] = useState(0);
  const [userInfo, setUserInfo] = useState("");
  const [profileOptions, setProfileOptions] = useState([{ name: "", component: <></> }]);
  const [updateInfo, setUpdateInfo] = useState({});

  const options = useMemo(() => [
    'Información',
    'Seguimiento de pedidos',
    'Direcciones',
    'Cambiar contraseña',
    'Cerrar sesión',
  ], []);

  const handleOnCloseSession = () => {
    session.signOff(true);

    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }


  const handleOnChangeOption = (index) => {
    common.updateUrlValue('view', profileOptions[index].name);
    setOptionSelected(index);

    if (index === options.length - 1) { // Última opción: Cerrar sesión
      handleOnCloseSession(); // Manejar el cierre de sesión
      return;
    }
  }

  const handleOnSubmitForm = (e, setNoSelect = "") => {
    e.preventDefault();

    if (typeof setNoSelect === 'function') {
      const mainValues = Object.values(updateInfo.profile.address.main);
      const secondaryValues = Object.values(updateInfo.profile.address.secondary);

      if (mainValues.includes("seleccionar") || secondaryValues.includes("seleccionar")) return setNoSelect(true);
    }

    setUpdate(true);

    profileFn.updateUser(accountData.user._id, updateInfo).then((response) => {
      let updateAccountData = accountData;
      updateAccountData.user = response;
      setAccountData(updateAccountData);
      setUserInfo(response);
      ls.set("accountData", JSON.stringify(updateAccountData));
      setUpdate(false);

      if (typeof setNoSelect === 'function') setNoSelect(false);
      common.redirectTo('from');
      common.showNotification("Actualización exitosa");
    });
  }

  useEffect(() => {
    if (!update) {
      profileFn.fetchProfile(accountData.user._id).then((response) => {
        setUserInfo(response);
      });
    }
  }, [update]);

  useEffect(() => {
    setUpdateInfo({
      nickname: userInfo.nickname,
      name: userInfo.name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      profile: userInfo.profile,
    });
  }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      setProfileOptions([
        {
          name: "informacion",
          component: <ProfileInformation userInfo={userInfo} updateInfo={updateInfo} setUpdateInfo={setUpdateInfo} handleOnSubmitForm={handleOnSubmitForm} />,
        },
        {
          name: "pedidos",
          component: <ProfileOrder userId={accountData.user._id} />,
        },
        {
          name: "direcciones",
          component: <ProfileAddress address={userInfo.profile.address} updateInfo={updateInfo} setUpdateInfo={setUpdateInfo} handleOnSubmitForm={handleOnSubmitForm} />,
        },
        {
          name: "cambiar-contraseña",
          component: <ProfilePassword account={accountData.account} />,
        },
        {
          name: "cerrar-sesion",
          // component: <ProfileSession />,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, update, optionSelected, updateInfo, accountData]);

  useEffect(() => {
    if (!common.getUrlValue()) {
      common.setUrlValue('view', 'informacion');
    }
  }, []);

  useEffect(() => {
    profileOptions.forEach((item, index) => {
      if (item.name === common.getUrlValue()) {
        setOptionSelected(index);
      }
    });
  }, [profileOptions]);

  return (
    <div className='profile'>
      <div className='container wrapper'>
        <Breadcrumbs addClass={"pdd-v-4"} />

        <div className='profile__inner mrg-b-16'>
          <div className='d-flex d--between'>
            <div className='d-flex__column--12 d-flex__column--4!sm'>
              <div className='profile__section bkg-white mrg-b-3 mrg-b-0!sm mrg-r-3!sm pdd-3'>
                <ul>
                  {
                    options.map((option, key) => (
                      <ProfileOption
                        label={option}
                        optionSelected={optionSelected}
                        handleOnChangeOption={handleOnChangeOption}
                        key={key}
                        index={key}
                        lastOption={key === options.length - 1} 
                        />
                    ))
                  }
                </ul>
              </div>
            </div>

            <div className='d-flex__column--12 d-flex__column--8!sm'>
              <div className='profile__section bkg-white pdd-3'>
                {profileOptions[optionSelected].component}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
