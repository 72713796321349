import defaultImage from '../assets/default/no-image.png';
import defaultIcon from '../assets/default/no-icon.png';

const onErrorLoadingImage = (e) => {
  e.target.src = defaultImage;
  e.target.alt = "Sin imagen"
}

const onErrorLoadingIcon = (e) => {
  e.target.src = defaultIcon;
  e.target.alt = "Sin icono"
}


const imgFn = {
  onErrorLoadingImage,
  onErrorLoadingIcon,
}

export default imgFn;
