import React from 'react';

const SearchResultsHeader = ({ searchTerm, totalProducts }) => {
  return (
    <div className="search-results-header">
      <p className='mrg-0 mrg-b-5'>
        Resultados de búsqueda para: "{searchTerm}" ({totalProducts} productos encontrados)
      </p>
    </div>
  );
};

export default SearchResultsHeader;
