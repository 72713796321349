import checkoutFn from "./checkoutFunctions";
import ChkPaymentForm from "./payment/chk-payment-form";
import exampleYape01 from "../../assets/example_yape01.jpg";
import exampleYape02 from "../../assets/example_yape02.jpg";
import examplePlin from "../../assets/example_plin01.jpg";

const onChangeView = (position, handleOnBuy) => {
  const paymentViews = {
    yape: (
      <div className="d--center d--gap-1 d-flex">
        <img
          className="h-10 w-5!tn w-8!md w-8!sm w-9!lg"
          src={exampleYape01}
          alt="imagenYape01-QR"
        />
        <img
          className="h-10 w-5!tn w-8!md w-8!sm w-9!lg"
          src={exampleYape02}
          alt="imagenYape02-QR"
        />
        <button
          className="bkg-button-primary button button--primary h-0 fs-b!lg fs-b!md fs-b!sm"
          onClick={handleOnBuy}
        >
          CREAR ORDEN
        </button>
      </div>
    ),
    plin: (
      <div className="d--center d--gap-1 d--middle d-flex d-flex--column">
        <img className="h-10 " src={examplePlin} alt="imagenPlin-QR" />
        <button
          className="button button--primary h-0 fs-b!lg fs-b!md fs-b!sm"
          onClick={handleOnBuy}
        >
          CREAR ORDEN
        </button>
      </div>
    ),
    visa: <ChkPaymentForm />,
    mastercard: <ChkPaymentForm />,
    pagoefectivo: <></>,
  };

  return paymentViews[position];
};

const ChkPayment = ({
  onShowPopup,
  onChangePage,
  cartVariants,
  paymentOptions,
  paymentSelected,
  setPaymentSelected,
  offerValue,
  subTotalCosts,
  setOrder,
  total,
  info,
  sendTo,
}) => {
  const handleOnBuy = () => {
    const data = {
      client: {
        user: info.user._id,
        payment_method: "650a75a99a01d743f9ae9cc7",
      },
      products: Array.from(cartVariants.cart)
        .filter((c) => c.shipmentStatus === "available")
        .map((c) => {
          const productVariant = {
            variant: c.item._id,
            quantity: c.qty,
            price: c.offerPrice,
          };

          return productVariant;
        }),
      delivery_address: {
        full_address: info.user.profile.address[sendTo].full_address,
        ubigeo: info.user.profile.address[sendTo].ubigeo,
        reference: info.user.profile.address[sendTo].reference,
      },
      coupon: offerValue.data.value,
      shipping_cost: Array.from(subTotalCosts).reduce(
        (sum, cost) => cost + sum,
        0
      ),
      total_price: total,
    };

    checkoutFn
      .insertOrder(data)
      .then((response) => {
        // onShowPopup(
        //   `¡Pedido confirmado! 🎉 \n Numero de pedido: \n AQUPE-P-0000000${response.order_number}`
        // );
        setOrder(response);
        onChangePage(2);
        // console.log("Successful", response);
        // setTimeout(() => {
        //   onChangePage(2);
        // }, 1000);
      })
      .catch((error) => console.log(error.response.data.error.message));
  };

  return (
    <div className="chk-payment bkg-white mrg-auto mw-25 w-0 pdd-4 br-n">
      <div className="d-flex">
        <div className="d-flex__column--2 pdd-h-2">
          <ul className="d-flex d-flex--column d--gap-1">
            {paymentOptions.map((option, key) => (
              <li key={key}>
                <button
                  onClick={() => setPaymentSelected(option.name)}
                  className="bkg-button-primary br-n br-none button button--primary d--center d--middle d-flex fw-700 pdd-2 w-0"
                  type="button"
                  title={option.label}
                >
                  <div className="d-flex mw-2 w-0 h-1">
                    <img src={option.icon} alt={option.name} />
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="d-flex__column--10 pdd-h-2">
          {onChangeView(paymentSelected, handleOnBuy)}
        </div>
      </div>
    </div>
  );
};

export default ChkPayment;
