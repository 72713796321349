import { useEffect, useState } from "react";

const ClassChange = (getBreakpoints, getClasses, windowSize, classDefault) => {
  const [className, setClassName] = useState('');

  const changeSizeScreen = () => {
    if (className != null) {
      for (let x = 0; x < getBreakpoints.length; x++) {
        if (windowSize < getBreakpoints[x]) {
          setClassName(getClasses[x]);
          break;
        } else {
          setClassName(classDefault);
        }
      }
    }
  }

  useEffect(() => {
    changeSizeScreen();
  });

  return className;
}

export default ClassChange;