import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Specifications = ({ product, currentVariant }) => {
  return (
    <div className='spect__container mrg-v-6 w-0 pdd-5 bkg-white'>
      <div className='spect__container--table d-gap-2 pdd-h-2 pdd-b-5'>
        <div className='d-center d-flex'>
          <h2 className='w-0'>ESPECIFICACIONES GENERALES</h2>
          {product.general_specs && product.general_specs.length > 0 ? (
            <table className="spect__table ta-left">
              <tbody>
                {product.general_specs.map((spec, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{spec.key}</strong>
                    </td>
                    <td className='w-10'>{spec.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className='w-0'>No hay detalles disponibles en este producto.</p>
          )}
        </div>

        <div className='d-center d-flex'>
          <h2 className='w-0'>ESPECIFICACIONES UNICAS</h2>
          {product.variants[currentVariant].specs && product.variants[currentVariant].specs.length > 0 ? (
            <table className="spect__table ta-left">
              <tbody>
                {product.variants[currentVariant].specs.map((spec, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{spec.key}</strong>
                    </td>
                    <td className='w-10'>{spec.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay detalles disponibles en este producto.</p>
          )}
        </div>
      </div>

      <div className='pdd-h-5 spect__general ta-center w-0'>
        <h2 className='mrg-0'>INFORMACION ADICIONAL</h2>
        <p className='ta-left'>{product.description}</p>

        <LazyLoadImage
          src={product.variants[0].images[0].imageUrl}
          alt='imagen promocional'
        />
      </div>
    </div>
  );
};

export default Specifications;