// Recuperar pass 
const orderConfirmation = (htmlContent) => {
  const html = `
    <head>
      <style>
        h3 {
          margin: 0;
          padding: 10px 0;
        }

        .template__header {
          display: flex;
          padding: 20px 0;
          background-color: #73737342;
          justify-content: center;
        }

        .template__content {
          padding: 10px 30px;
        }

        .template__image {
          margin: 0 auto;
        }

        .template__footer {
          padding: 10px;
          background-color: #b60000;
          color: white;
        }
      </style>
    </head>

    <div class="template">
      <div class="template__inner">
        <div class="template__header">
          <img class="template__image" src="https://www.aqupe.com/assets/logo%20aqupe.png" alt="aqupe" width="180" />
        </div>

        <div class="template__content">${htmlContent}</div>

        <br>
        <div class="template__footer">
          <b>
            Saludos,
            el equipo de AQUPE
          </b>
        </div>
      </div>
    </div>
  `;

  return html;
}

const template = {
  orderConfirmation,
}

export default template;