import { useState } from 'react';
import Loading from "../../snippets/loading";
import common from '../../scripts/common';
import SR from '../../services/sunat-reniec';

const ChkResumeDocument = ({ setRucInfo }) => {
  const [documentSelected, setDocumentSelected] = useState("ticket");
  const [ruc, setRuc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidateRuc, setIsValidateRuc] = useState({ state: false, message: "" });

  const searchRuc = () => {
    setIsLoading(true);
    setIsValidateRuc({ state: false, message: "" });

    SR.request("ruc", ruc).then((response) => {
      setRucInfo(response);
      setIsValidateRuc({ state: true, message: `${response.razonSocial} ${response.direccion}` });
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsValidateRuc({ state: false, message: `RUC no válido` });
      setIsLoading(false);
    });
  }

  return (
    <div className="chk-resume-document mrg-t-4">
      <p className="mrg-0 mrg-b-2 fw-700">Seleccionar comprobante de pago: </p>

      <div className='d-flex d--gap-4 pdd-b-2'>
        <label className='d-flex'>
          Recibo
          <input onChange={() => { setDocumentSelected("ticket"); setIsValidateRuc({ state: false, message: "" }); }} className='input' type="radio" name="document" defaultChecked />
        </label>

        <label className='d-flex'>
          Factura
          <input onChange={() => { setDocumentSelected("bill"); setIsValidateRuc({ state: false, message: "" }); }} className='input' type="radio" name="document" />
        </label>
      </div>

      {
        documentSelected === "ticket" ? (
          <></>
        ) : (
          <div className="d-grid d-grid__column--2fa d--gap-1">
            <input
              onChange={(e) => common.validateNumber(e, setRuc)}
              value={ruc}
              className="input w-0"
              type="text"
              name="ruc"
              placeholder="RUC..."
              title="RUC"
              maxLength={11} />

            <button onClick={searchRuc} type='button' title='buscar RUC' className="button button--outline bc-green-2 color-green-2 w-2">
              {
                isLoading ? (
                  <Loading imgHeight={"16px"} />
                ) : (
                  "Buscar"
                )
              }
            </button>
          </div>
        )
      }

      {
        isValidateRuc.message !== "" && <div className='bkg-whatermark pdd-2 b-xs color-silver mrg-v-4'>{isValidateRuc.message}</div>
      }
    </div>
  );
}

export default ChkResumeDocument;
