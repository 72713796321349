import { useState, useEffect } from "react";
import UseWindowSize from "../../scripts/window-size";
import config from "../../config/config";
import imgFn from "../../scripts/image-functions";

const Categories = () => {
  const listCategories = config.listCategories;
  const [listSubcategories, setListSubcategories] = useState([]);
  const screen = UseWindowSize();

  const onSelectedCategory = (index, subcategories) => {
    if (screen.width < 480) return;

    const navbarAllCategories = document.querySelectorAll(".navbar__category");
    const navbarSubcategories = document.querySelector(
      ".navbar__subcategories"
    );

    setListSubcategories(subcategories);

    if (navbarSubcategories) {
      navbarSubcategories.classList.remove("navbar__subcategories--hidden");
    }

    if (navbarAllCategories.length > 0) {
      navbarAllCategories.forEach((category, i) => {
        if (i === index) {
          category.classList.add("navbar__category--selected");
        } else {
          category.classList.remove("navbar__category--selected");
        }
      });
    }
  };

  const handleOnSelectedSubCategory = (e) => {
    if (screen.width >= 480) return;

    const domSubcategoriesMobile = e.currentTarget.querySelector(
      ".navbar__subcategories-mobile"
    );

    if (domSubcategoriesMobile) {
      const visibleClass = "navbar__subcategories-mobile--visible";

      if (domSubcategoriesMobile.classList.contains(visibleClass)) {
        domSubcategoriesMobile.classList.remove(visibleClass);
      } else {
        domSubcategoriesMobile.classList.add(visibleClass);
      }
    }
  };

  const onHideSubCategories = () => {
    const navbarSubcategories = document.querySelector(
      ".navbar__subcategories"
    );

    if (navbarSubcategories) {
      navbarSubcategories.classList.add("navbar__subcategories--hidden");
    }
  };

  useEffect(() => {
    if (screen.width < 480) return;

    const navbarCategories = document.querySelector(".navbar__categories");
    const navbarSubcategories = document.querySelector(
      ".navbar__subcategories"
    );

    if (navbarCategories && navbarSubcategories) {
      const heightCategories = navbarCategories.offsetHeight;
      navbarSubcategories.style.height = `${heightCategories}px`;
    }
  }, [listSubcategories, screen]);
  return (
    <>
      <ul className="navbar__categories navbar__categories--visible">
        {listCategories.length > 0 &&
          listCategories.map((category, index) => (
            <li
              key={index}
              onClick={handleOnSelectedSubCategory}
              onMouseOver={() =>
                onSelectedCategory(index, category.subcategories)
              }
              className="navbar__category"
            >
              <div className="d-flex d-flex--no-wrap d--gap-1 d--middle">
                <img
                  className="w-auto"
                  src={config.imageBaseUrl + category.image}
                  alt={category.name}
                  height={25}
                  onError={(e) => imgFn.onErrorLoadingIcon(e)}
                />
                <div>{category.name}</div>
              </div>

              {screen.width < 480 && (
                <div className="navbar__subcategories-mobile">
                  {category.subcategories.map((subcategory, position) => (
                    <a
                      key={position}
                      href={`/productos/${category.name}/${subcategory}`}
                      className="d-flex"
                    >
                      <p className="navbar__subcategory fs-c">{subcategory}</p>
                    </a>
                  ))}
                </div>
              )}
            </li>
          ))}
      </ul>

      {screen.width >= 480 && (
  <div
    className="navbar__subcategories navbar__subcategories--hidden"
    onMouseLeave={onHideSubCategories}
  >
    {listSubcategories.map((subcategory, index) => {
      //se busca  la categoría a la que pertenece cada subcategoría de la lista principal "listCategories"
      const category = listCategories.find(category => category.subcategories.includes(subcategory));
      // Verificar si se encontró la categoría
      if (category) {
        return (
          <a
            key={index}
            href={`/productos/${category.name}/${subcategory}`}
            className="d-flex"
          >
            <p className="navbar__subcategory">{subcategory}</p>
          </a>
        );
      } 
    })}
  </div>
)}

    </>
  );
};

export default Categories;
