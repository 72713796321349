import Icon from "@mdi/react";
import { mdiPowerStandby } from "@mdi/js";

const ProfileOptions = ({ label, optionSelected, handleOnChangeOption, index,lastOption  }) => {
  return (
    <li className='profile-option'>
      <div
        className='d--gap-1 d--middle d-flex profile-option__option'
        data-selected={optionSelected === index ? true : false}
        onClick={() => handleOnChangeOption(index)}
      >
        {lastOption && <Icon path={mdiPowerStandby} size={1} />}
        <div className='fs-n'>{label}</div>
      </div>
    </li>
  )
}

export default ProfileOptions;
