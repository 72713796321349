import { Link } from 'react-router-dom'
import smart from '../assets/cel1.png'

const Smartphones5G = () => {
  return (
    <div className='shops section-t mrg-b-18'>
      <div className='container wrapper'>
        <h1 className='detail-heading'>celular android</h1>
        <div className='container'>
          <Link className='detail-link' to='/'>
            <img className='w-1'
              src={smart}
              alt='Descripción de la imagen'
            />
          </Link>
          <div className='button-container'>
            <Link className='detail-link' to='/ver-detalles/smarphone5g'>
              <button className='button button--primary'>Ver Detalles</button>
            </Link>

            <Link className='detail-link' to='/anadir-al-carrito'>
              <button className='button button--outline'>
                Añadir al Carrito
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Smartphones5G
