import UseWindowSize from '../scripts/window-size';
import ClassChange from '../scripts/class-change';
import { Fragment } from 'react';

const Link = (props) => {
  const { url, label, Icon, iconName } = props;
  const sizeScreen = UseWindowSize();
  const className = ClassChange([750], ["icon--xsmall"], sizeScreen.width, "icon--small");

  return (
    <Fragment>
      {
        url
          ? <a href={url} className={`link link--${iconName}`}>
            <div className='link__container'>
              {
                Icon && (
                  <div className={`link__icon ${className} link__icon--${iconName}`}>
                    <Icon />
                  </div>
                )
              }
              {
                sizeScreen.width > 1024 && (
                  <div className={`label label--${iconName}`}>
                    {label}
                  </div>
                )
              }
            </div>
          </a>
          : <div className={`link link--${iconName}`}>
            <div className='link__container'>
              {
                Icon && (
                  <div className={`link__icon ${className} link__icon--${iconName}`}>
                    <Icon />
                  </div>
                )
              }
              {
                sizeScreen.width > 1024 && (
                  <div className={`label label--${iconName}`}>
                    {label}
                  </div>
                )
              }
            </div>
          </div>
      }
    </Fragment>
  );
}

export default Link;