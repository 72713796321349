import { Fragment,useEffect } from "react";
import { tns } from "tiny-slider/src/tiny-slider";
import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';

const ImagesSlider = (props) => {
  const { images } = props;

  useEffect(() => {
    tns({
      container: '.slider__container--images',
      slideBy: 'page',
      mode: 'carousel',
      items: 1,
      mouseDrag: true,
      loop: true,
      lazyload: true,
      autoplay: true,
      speed: 1500,
      autoplayButton: false,
    });
  }, []);

  return (
    <Fragment>
      <div className="slider slider--images mrg-b-18">
        <div className="slider__container slider__container--images">
          {
            images.map((image, key) => (
              <div className="slider__image" key={key}>
                <img
                  className={`image--${key}`}
                  src={image.url}
                  alt={`slider ${key}`}
                  loading="eager"
                  width={"100%"}
                  placeholder={`image--${key}`}
                />
              </div>
            ))
          }
        </div>
      </div>
    </Fragment>
  );
}

const CategoriesSlider = (props) => {
  const { data } = props;

  window.addEventListener("load", () => {
    tns({
      container: '.slider__container--categories',
      slideBy: 'page',
      mode: 'carousel',
      center: true,
      items: 1,
      responsive: {
        750: {
          items: 3
        },
        1024: {
          items: 4
        },
        1440: {
          items: 5
        }
      },
      lazyload: true,
      autoplay: false,
      mouseDrag: true,
      speed: 2000,
      autoplayButton: false,
      controlsContainer: '.slider__controls',
      prevButton: '.slider__nav-control--prev',
      nextButton: '.slider__nav-control--next',
    });
  });

  return (
    <Fragment>
      <div className="slider slider--categories">
        <div className="slider__container slider__container--categories">
          {
            data && data.map((card, key) => (
              <a href={card.url} className={`slider__link slider__link--${card.name}`} key={key}>
                <div className="slider__image-container">
                  <img
                    src={card.image}
                    alt={card.name}
                    className={`slider__image slider__image--${key}`}
                    loading="lazy"
                    placeholder={card.name}
                  />
                </div>
                <div className="slider__content">
                  <div className="slider__label h4 up-case weight-2">
                    {card.label}
                  </div>
                </div>
              </a>
            ))
          }
        </div>

        <div className="slider__controls">
          <button type="button" className="slider__nav-control slider__nav-control--prev" title="nav--prev">
            <Icon path={mdiChevronLeft} size={2} />
          </button>

          <button type="button" className="slider__nav-control slider__nav-control--next" title="nav--next">
            <Icon path={mdiChevronRight} size={2} />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const AllSlider = {
  ImagesSlider,
  CategoriesSlider,
}

export default AllSlider;