import { useState, useEffect } from "react";

import ChkResumeItem from "./chk-resume-item";
import ChkResumeOffers from "./chk-resume-offers";
import ChkResumeResult from "./chk-resume-result";
import ChkResumeCta from "./chk-resume-cta";
import ChkResumeShipping from "./chk-resume-shipping";
import ChkResumeDocument from "./chk-resume-document";
import checkoutFn from "./checkoutFunctions";

const ChkResume = ({
  onChangePage,
  offerValue,
  setOfferValue,
  subTotalCosts,
  setSubTotalCosts,
  total,
  setTotal,
  cartVariants,
  info,
  sendTo,
  setRucInfo,
}) => {
  const [shippingInfo, setShippingInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [couponInputValue, setCouponInputValue] = useState("");
  const [shopsKeys, setShopKeys] = useState("");

  const handleOnSearchAndValidateOffer = () => {
    setIsLoading(true);

    if (couponInputValue === "") {
      onOfferError("");
      return;
    }

    
    checkoutFn
      .getOneOffer(couponInputValue)
      .then((data) => {
        if (data.symbol === "%") {
          setOfferValue({
            state: "available",
            data: {
              amount:
                cartVariants.subTotal -
                (data.value / 100) * cartVariants.subTotal,
              value: data.value + data.symbol,
            },
          });
        } else {
          setOfferValue({
            state: "available",
            data: {
              amount: cartVariants.subTotal - data.value,
              value: data.symbol + data.value,
            },
          });
        }

        setIsLoading(false);
      })
      .catch(() => {
        onOfferError("unavailable");
      });
  };
//aplicar cupon cuando se elimine o aumente el stock en la cantidad de productos 
  useEffect(()=>{
    handleOnSearchAndValidateOffer();
  },[cartVariants])

  const onOfferError = (state) => {
    setIsLoading(false);
    setOfferValue({ state: state, data: { amount: 0, value: "" } });
  };

  useEffect(() => {
    if (info) {
      const variantsIds = cartVariants.cart.map((variant) => {
        const variantInfo = {
          _id: variant.item._id,
          quantity: variant.qty,
          shipmentStatus: variant.shipmentStatus,
        };

        return variantInfo;
      });

      const dataVariants = {
        ubigeo: info.user.profile.address[sendTo].ubigeo,
        variants: variantsIds,
      };

      checkoutFn.findShippingCost(dataVariants).then((data) => {
        setShippingInfo(data.result);
      });
    }
  }, [sendTo, cartVariants, info]);

  useEffect(() => {
    setShopKeys(Object.keys(shippingInfo).map((shopKey) => shopKey));
  }, [shippingInfo]);

  useEffect(() => {
    setSubTotalCosts(
      Array.from(shopsKeys).map((shopName) => {
        //verificar si existen productos
        if (shippingInfo[shopName] && shippingInfo[shopName].products) {
          const subTotalShipment = Array.from(
            shippingInfo[shopName].products
          ).reduce((sum, item) => {
            return item.shipmentCost + sum;
          }, 0);
          return subTotalShipment;
        } else {
          return 0;
        }
      })
    );
  }, [setSubTotalCosts, shopsKeys, shippingInfo]);

  useEffect(() => {
    const totalCost = Array.from(subTotalCosts).reduce(
      (sum, itemSubTotalCost) => {
        return itemSubTotalCost + sum;
      },
      0
    );

    setTotal(
      offerValue.data.amount === 0
        ? cartVariants.subTotal + offerValue.data.amount + totalCost
        : offerValue.data.amount + totalCost
    );
  }, [setTotal, subTotalCosts, offerValue, cartVariants]);

  return (
    <section className="chk-resume">
      <div className="chk-resume__inner pdd-2 br-n fs-c fs-n!sm">
        <h3 className="ta-center">RESUMEN DEL PEDIDO</h3>

        <div className="pdd-2">
          <div className="chk-resume__inner--little d-grid ta-right">
            <p className="ta-left">Nombre</p>
            <p className="ta-center">Cantidad</p>
            <p>Precio</p>
          </div>
          {cartVariants &&
            cartVariants.cart.map((variant, index) => (
              <ChkResumeItem
                variant={variant}
                index={index}
                key={variant.item._id}
                shop_name={shippingInfo.shop_name}
              />
            ))}

          {cartVariants && (
            <ChkResumeResult
              label={"Subtotal"}
              result={cartVariants.subTotal}
            />
          )}

          <ChkResumeOffers
            handleOnSearchAndValidateOffer={handleOnSearchAndValidateOffer}
            isLoading={isLoading}
            couponInputValue={couponInputValue}
            setCouponInputValue={setCouponInputValue}
            offerValue={offerValue}
          />

          {subTotalCosts && <ChkResumeShipping subTotalCosts={subTotalCosts} />}

          {total ? (
            <ChkResumeResult
              label={"Total"}
              result={total}
              labelClass={"fw-700 fs-m"}
              resultClass={"fs-m"}
            />
          ) : (
            0
          )}

          <ChkResumeDocument setRucInfo={setRucInfo} />
        </div>

        {info && (
          <ChkResumeCta
            onChangePage={onChangePage}
            total={total}
            hasItems={cartVariants.cart.length > 0 ? true : false}
          />
        )}
      </div>
    </section>
  );
};

export default ChkResume;
