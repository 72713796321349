import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";
import Product from "./product";
import Profile from "./profile-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "/node_modules/react-loading-skeleton/dist/skeleton.css";
import ShopContentSkeleton from "./loading-shop";
import UseWindowSize from "../scripts/window-size";

function ShopContent({
  handleAddToCart,
  handleAddToList,
  handleRemoveOfFavorites,
}) {
  const screen = UseWindowSize();
  const [datashop, setDatashop] = useState([]);
  const [filtradoImageFeatures, setFiltradoImageFeatures] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showRecommended, setShowRecommended] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  // const { nombreTienda } = useParams();
  const { id } = useParams();

  useEffect(() => {
    api
      .get(`api/product/singleShop/${id}`) //traer todos los productos de la tienda
      .then(async (response) => {
        const data = response.data;
        setDatashop(data.data);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, [id]);

  useEffect(() => {
    api
      .get(`api/shop/${id}`) // traer productos destacados de la tienda
      .then(async (response) => {
        const data = response.data;
        setFiltradoImageFeatures(data.featured_products);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, [id]);

  const handleSearchInputChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText); // Actualiza el estado del texto de búsqueda

    if (searchText === "") {
      setNoResults(false);
    } else {
    }
  };

  const handleSearch = () => {
    const filteredProducts = datashop.filter((product) => {
      const lowerCaseSearchText = searchText.toLowerCase();
      const lowerCaseProductName = product.name.toLowerCase();
      const lowerCaseBrand = product.brand.toLowerCase();

      // Busca por nombre o marca
      return (
        lowerCaseProductName.includes(lowerCaseSearchText) ||
        lowerCaseBrand.includes(lowerCaseSearchText)
      );
    });

    // Verifica si se encontraron resultados antes de actualizar datashop
    if (filteredProducts.length > 0) {
      setDatashop(filteredProducts);
      setNoResults(false); // Restablece el estado de noResults
    } else {
      setNoResults(true); // No se encontraron resultados
    }
  };

  // busqueda al presionar la tecla enter
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <>
      {datashop.length === 0 ? (
        <ShopContentSkeleton />
      ) : (
        <div className="container ">
          <div className="bkg-white pdd-6!tn pdd-8!lg shop-container">
            <Profile datashop={datashop} />
            {datashop[0].shop_id.description ? (
              <div className="shop-profile__info">
                <p className="mrg-0 shop-description fs-n mrg-t-5 cr--pointer">
                  <span className="fw-700">Descripción:</span>{" "}
                  {screen.width >= 480
                    ? showFullDescription
                      ? datashop[0].shop_id.description
                      : `${datashop[0].shop_id.description.slice(0, 500)}...`
                    : showFullDescription
                    ? datashop[0].shop_id.description
                    : `${datashop[0].shop_id.description.slice(0, 100)}...`}
                  {datashop[0].shop_id.description.length >
                    (screen.width >= 480 ? 500 : 100) && (
                    <span
                      className="product-details__read-more td-underline color-red"
                      onClick={toggleDescription}
                    >
                      {showFullDescription ? "Ver menos" : "Ver más"}
                    </span>
                  )}
                </p>
              </div>
            ) : (
              <p>Sin descripcion</p>
            )}

            <div className="shop-products-container">
              {filtradoImageFeatures.length > 0 && (
                <div className="shop-products-container__offers d-flex d--center">
                  <h2>LOS MEJORES DESCUENTOS</h2>

                  <ul className="d--center d--gap-2 d-grid ">
                    {filtradoImageFeatures.map((product, index) => (
                      <Product
                        key={index}
                        handleAddToCart={handleAddToCart}
                        dataVariant={product.first_variant}
                        handleAddToList={handleAddToList}
                        handleRemoveOfFavorites={handleRemoveOfFavorites}
                      />
                    ))}
                  </ul>
                </div>
              )}
              <div className="shop-products-container__allProducts d--between d--middle d-flex">
                <h2>TODOS LOS PRODUCTOS</h2>

                <div className="search-bar ">
                  <input
                    className="h-1 w-7!tn w-8!lg "
                    type="text"
                    placeholder="Buscar productos"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    onKeyUp={handleKeyUp}
                  />

                  <button
                    className="search-button h-1 "
                    onClick={handleSearch}
                    title="buscar"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>
              <div className="mrg-v-5 d-flex d--gap-2">
                <span
                  className={
                    showRecommended
                      ? "bc-black bkg-whatermark br-b pdd-h-2 pdd-v-1 active cr--pointer "
                      : "tag bc-black  br-b pdd-h-2 pdd-v-1 cr--pointer"
                  }
                  onClick={() => {
                    setShowRecommended(true);
                    setShowAllProducts(false);
                  }}
                >
                  Productos Recomendados
                </span>
                <span
                  className={
                    showAllProducts
                      ? "bc-black bkg-whatermark br-b pdd-h-2 pdd-v-1 active cr--pointer"
                      : "tag bc-black  br-b pdd-h-2 pdd-v-1 cr--pointer"
                  }
                  onClick={() => {
                    setShowAllProducts(true);
                    setShowRecommended(false);
                  }}
                >
                  Todos los Productos
                </span>
              </div>

              <div className="shop-products-container__container ">
                <ul className="d-grid  d--gap-2 ">
                  {showRecommended ? (
                    datashop
                      .filter(
                        (product) =>
                          product.variants && product.variants.length > 0
                      )

                      .slice(0, 5)
                      .map((product) => (
                        <Product
                          key={product._id}
                          handleAddToCart={handleAddToCart}
                          dataVariant={product.variants[0]}
                          handleAddToList={handleAddToList}
                          handleRemoveOfFavorites={handleRemoveOfFavorites}
                        />
                      ))
                  ) : !noResults ? (
                    datashop
                      .filter(
                        (product) =>
                          product.variants && product.variants.length > 0
                      )
                      .map((product) => (
                        <Product
                          key={product._id}
                          handleAddToCart={handleAddToCart}
                          dataVariant={product.variants[0]}
                          handleAddToList={handleAddToList}
                          handleRemoveOfFavorites={handleRemoveOfFavorites}
                        />
                      ))
                  ) : (
                    <div className="ta-center">
                      No se encontraron resultados
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ShopContent;
