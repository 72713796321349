import React, { useState, useEffect } from 'react'
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const SideCart = ({hasClose=true, verifySession}) => {
  const [cartNotEmpty, setCartNotEmpty] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const sideCart = document.querySelector('.side-cart__products');
      if (sideCart && sideCart.hasChildNodes()) {
        setCartNotEmpty(true);
      } else {
        setCartNotEmpty(false);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const closeMenu = () => {
    const hideMenu = document.querySelector('.side-menu__cart');
    const menuContainer = hideMenu.querySelector('.side-menu__container');
  
    if (hideMenu && menuContainer) {
      hideMenu.classList.add('hidden');
      menuContainer.removeAttribute('style');
    }
  }

  return (
    <div className='d-flex d-flex--column d--gap-2 mw-10'>
         {
          hasClose && (
            <button onClick={closeMenu} className="b-none button button--outline d--right d-flex mrg-v-2 pdd-0 w-0" type="button" title="cerrar">
              <Icon path={mdiClose} size={1} />
            </button>
          )
        }
      <div className='fs-b fw-700'>
        Carro de compras
      </div>

       {!verifySession &&
        <div className='fs-n'>
        <a href="/login" className='td-underline'>Inicia sesión</a>
        <span> para acceder a tu carro de compras desde cualquier dispositivo</span>
      </div>
       }
      <div className='fs-m'>
        Productos en carrito: <span className="side-cart__quantity"> </span>
      </div>

      <div className="side-cart__products d-flex d-flex__column d--gap-2 d-flex--no-wrap"> </div>

      {!cartNotEmpty && (
        <p className='w-0'>No hay productos en el carrito</p>
      )}
      {cartNotEmpty && (
        <a
          href="/checkout"
          className="button button--primary bkg-green-2 w-0 ta-center"
        >
          Comprar
        </a>
      )}
    </div>
  );
}

const Cart = {
  SideCart,
}

export default Cart;