export const Open = (e, name, side) => {
  if (e.currentTarget.dataset.menu.includes(`data-menu-${name}`)) {
    const sideMenu = document.querySelector(`.side-menu__${name}`);
    const container = sideMenu.querySelector(".side-menu__container");

    if (document.querySelector(".tns-nav")) {
      document.querySelector(".tns-nav").style.zIndex = "0";
    }

    document.getElementById("header").classList.add("position--1");
    sideMenu.classList.remove("hidden");
    if (side === 'left') {
      container.style.left = "0";
    } else {
      container.style.right = "0";
    }
  }
}

export const Close = (e, name) => {
  if (e.target.dataset !== undefined) {
    try {
      if (e.target.dataset.closeMenu.includes(`data-menu-${name}`)) {
        const container = e.target.querySelector(".side-menu__container");

        if (document.querySelector(".tns-nav")) {
          document.querySelector(".tns-nav").removeAttribute("style");
        }

        document.getElementById("header").classList.remove("position--1");
        e.target.classList.add('hidden');
        container.removeAttribute("style");
      }
    } catch (error) {
      console.error();
    }
  }
}