import { Fragment } from 'react'
import DetailBlock from '../snippets/detail-block'
import Image from '../snippets/image'

const Footer = () => {
  const cardClass =
    'd-flex d-flex--no-wrap d--center mw-1 pdd-v-1 pdd-h-2 mrg-1 bkg-white br-n'

  return (
    <footer id='footer'>
      <div className='footer'>
        <div className='container wrapper'>
          <div className='d-grid d-grid__row--2aut d--gap-3 pdd-v-6'>
            <div className='footer__section footer__section--top'>
              <DetailBlock
                heading={'Acerca de AQUPE'}
                detailsList={[
                  { name: 'Administración', link: '/' },
                  { name: '¿Quiénes somos?', link: '/' },
                  { name: 'Unete', link: '/' },
                  { name: 'Contactanos', link: '/' },
                ]}
              />
              <DetailBlock
                heading={'Servicio al cliente'}
                detailsList={[
                  { name: 'Libro de reclamaciones', link: '/' },
                  { name: 'Preguntas frecuentes', link: '/' },
                  { name: 'Términos y Condiciones', link: '/' },
                  { name: 'Tiempos y costo de envios', link: '/' },
                ]}
              />
            </div>
            <div className='footer__section footer__section--bottom'>
              <div className='footer__copyright pdd-2'>
                <div className='footer__text fs-n'>
                  © 2023 TODOS LOS DERECHOS RESERVADOS
                </div>
                <div className='footer__text fs-n'>
                  AQUPE S.A. Arequipa - Perú
                </div>
              </div>

              <div className='footer__payments'>
                <Image
                  src={'visa-logo.png'}
                  title={'visa'}
                  classImg={cardClass}
                />
                <Image
                  src={'mastercard-logo.png'}
                  title={'mastercard'}
                  classImg={cardClass}
                />
                <Image
                  src={'yape-logo.png'}
                  title={'yape'}
                  classImg={cardClass}
                />
                <Image
                  src={'plin-logo.png'}
                  title={'plin'}
                  classImg={cardClass}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
