import api from "../../services/api";
import config from "../../config/config";

const findShippingCost = (data) => {
  return new Promise((resolve, reject) => {
    api.post(`api/product/shippingCost`, data, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const checkProductShipment = (product_id, ubigeo) => {
  return new Promise((resolve, reject) => {
    api.get(`api/variant/check/shipment/${product_id}/${ubigeo}`, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const insertOrder = (data) => {
  return new Promise((resolve, reject) => {
    api.post(`api/order`, data, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const getOneOffer = (offer_id) => {
  return new Promise((resolve, reject) => {
    api.get(`api/offer/${offer_id}`, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const getDate = (date) => {
  const objDate = new Date(date);

  const newDate = {
    Year: objDate.getFullYear(),
    Month: objDate.toLocaleString('es-ES', { month: '2-digit' }),
    Day: objDate.toLocaleString('es-ES', { day: '2-digit' }),
    hours: objDate.toLocaleTimeString('es-ES', { hour: '2-digit' }),
    minutes: objDate.toLocaleTimeString('es-ES', { minute: '2-digit' }),
  }

  return newDate;
}

// const getDistrict = (ubigeo) => {
//   const findDistrict = districts.districts.find(district => district.ubigeo === ubigeo);

//   if (!findDistrict) return null;

//   return findDistrict;
// }

const checkoutFn = {
  checkProductShipment,
  insertOrder,
  findShippingCost,
  getOneOffer,
  getDate,
}

export default checkoutFn;