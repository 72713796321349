import React from "react";
import Icon from "@mdi/react";
import { mdiStar } from "@mdi/js";
import imgFn from "../scripts/image-functions";

function Profile({ datashop }) {
  return (
    <div className="shop-profile">
      <div className="info-container">
        <img
          className="h-8!lg h-8!md h-8!sm info-container__image-background"
          src={datashop[0].shop_id.images.background.imageUrl}
          alt="Cover-shop"
          onError={(e) => imgFn.onErrorLoadingImage(e)}
        />
        <img
          className="info-container__image-logo br-circle w-2!tn w-4!sm w-5!lg h-2!tn h-4!sm h-5!lg"
          src={datashop[0].shop_id.images.logo.imageUrl}
          alt="Profile-shop"
          onError={(e) => imgFn.onErrorLoadingImage(e)}
        />

      </div>
      <div className="d--between d-flex shop-name mrg-t-6 d--gap-1">
        <h1 className="mrg-0">{datashop[0].shop_id.shop_name}</h1>
        <div className="bkg-primary color-white d--middle d-flex info-container__shop__rating pdd-h-2">
          <Icon className="star-icon color-yellow" path={mdiStar} size={1.5} />

          {datashop[0].shop_id.rating.scores.length > 0 ? (
            <p>
              <span className="product-details__rating__average">
                {datashop[0].shop_id.rating.average.toFixed(1)}
              </span>{" "}
              votos:({datashop[0].shop_id.rating.scores.length}){" "}
            </p>
          ) : (
            <p>
              <span className="product-details__rating__average">0</span>{" "}
              votos:(0){" "}
            </p>
          )}
        </div>

      </div>
    </div>
  );
}
export default Profile;
