import ls from "./ls";
import common from "../scripts/common";

const signIn = (data, ssToken) => {
  ls.set("accountData", JSON.stringify(data));
  ls.set("ssToken", ssToken);
  ls.set("sessionId", data.account._id);

  common.redirectTo('from');
  common.showNotification("Sesión iniciada");
}

const signOff = (notification = true) => {
  ls.clear("accountData");
  ls.clear("ssToken");
  ls.clear("sessionId");

  if (notification) {
    common.showNotification("Sesión terminada");
  }
}

const session = {
  signIn,
  signOff,
}

export default session;
