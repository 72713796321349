import config from "../../config/config";
import imgFn from "../../scripts/image-functions";
import onImageRescaling from "../../scripts/imageRescaling";
import ls from "../../services/ls";
import { useEffect } from "react";


const ChkProductTile = ({
  handleOnProductRemove,
  variant,
  isSelected,
  onSelectItem,
  updateStockCartVariants,
  cartVariants,
}) => {
  const handleCheckboxChange = () => {
    onSelectItem(variant.item._id);
  };
  
  const handleStockChange = (productId, action) => {

    const productIndex = cartVariants.cart.findIndex(
      (product) => product.item._id === productId
    );
    if (productIndex !== -1) {
      const updatedCart = [...cartVariants.cart]; // Copiar el carrito actual

      // Actualizar la cantidad del producto específico según la acción
      if (action === "increase") {
        updatedCart[productIndex].qty += 1;
      } else if (action === "decrease" && updatedCart[productIndex].qty > 0) {
        updatedCart[productIndex].qty -= 1;
      }
      updateStockCartVariants(updatedCart);
      ls.set(
        "cart",
        JSON.stringify({ cart: updatedCart, subTotal: cartVariants.subTotal })
      );
    }
  };



  return (
    <li
      className={`chk-product-tile bkg-white b-n br-n ${variant.shipmentStatus} ${variant.shipmentStatus}--shipment d-grid d--middle`}
    >
      <div className="d--gap-1 d--middle d-grid d-grid__column--2fa!md fs-c fs-n!sm pdd-t-2 pdd-l-2 pdd-r-2">
        <div className="d-grid d-grid__column--2af">
          <div className="d--middle d-flex">
            <input
              type="checkbox"
              id={`chk-product-${variant.item._id}`}
              checked={isSelected} // Establece el estado del checkbox
              onChange={handleCheckboxChange}
            />
          </div>

          <div className="d-grid d-grid__column--2af">
            <div className="d--center d-flex pdd-b-2">
              <img
                className="w-3"
                onError={imgFn.onErrorLoadingImage}
                src={variant.item.images.length > 0 && onImageRescaling(variant.item.images[0].imageUrl, 200)}                



                alt={
                  variant.item.images.length > 0
                    ? variant.item.images[0].nameImage
                    : ""
                }
              />
            </div>

            <div className="d-flex d-flex--column pdd-t-5 w-10 d--gap-1 mrg-l-2">
              <p className="d--center d--gap-1 ft-upper fw-700 mrg-0">
                {variant.item.product_name + " " + variant.item.variant_name}
              </p>
              {variant.item.offer && variant.item.offer.value && (
                <span className="br-n chk-product-tile__offer pdd-h-6 w-1">
                  {variant.item.offer.value &&
                    ("-" + variant.item.offer.symbol === "%"
                      ? variant.item.offer.value + variant.item.offer.symbol
                      : variant.item.offer.symbol +
                        variant.item.offer.value.toFixed(2))}
                </span>
              )}

              <span
                className={`${
                  variant.item.offer && variant.item.offer.value
                    ? "td-through color-silver"
                    : ""
                }`}
              >
                PU: S/{variant.item.price.toFixed(2)}
              </span>
              {variant &&
                variant.item.offer &&
                variant.item.offer.value &&
                variant.offerPrice && (
                  <span>PU S/{variant.offerPrice.toFixed(2)}</span>
                )}
            </div>
          </div>
        </div>

        <div className="d--gap-4 d-flex d-flex--column h-0">
          <div className="b-m bc-black d--middle d-grid d-grid__column--3fr mrg-t-auto ta-center">
            <button
              onClick={() => handleStockChange(variant.item._id, "decrease")}
              className="b-r-m b-none bc-black fs-l fw-600 "
              title="reducir la cantidad"
              type="button"
            >
              -
            </button>
            <span>{variant.qty}</span>

            <button
              onClick={() => handleStockChange(variant.item._id, "increase")}
              className="b-none b-l-m bc-black fs-l fw-600 "
              title="aumentar la cantidad"
              type="button"
            >
              +
            </button>
          </div>
          <p
            onClick={handleOnProductRemove}
            className="cr--pointer mrg-0 mrg-b-1 td-underline"
            title="eliminar"
            type="button"
            data-id={variant.item._id}
          >
            eliminar producto
          </p>
        </div>
      </div>
    </li>
  );
};

export default ChkProductTile;
