import { Fragment } from 'react';

const TextCard = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <div className='text-card'>
        <div className='text-card__container'>
          {
            data && data.map((value, key) => (
              <div className={`text-card__item text-card__item--${key}`} key={key}>
                {
                  value.Icon && (
                    <div className='text-card__icon mw-1 w-0 mrg-auto'>
                      <value.Icon />
                    </div>
                  )
                }
                <div className='text-card__header'>
                  <div className='text-card__heading fs-m fw-700'>
                    {value.heading}
                  </div>
                </div>

                <div className='text-card__content'>
                  <div className='text-card__text fs-n'>
                    {value.desc}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Fragment>
  );
}

const SectionTextCard = (props) => {
  const { data } = props;

  return (
    <Fragment>
      <div className='section-text-card '>
        <div className='section-text-card__inner'>
          <TextCard data={data} />
        </div>
      </div>
    </Fragment>
  );
}

export default SectionTextCard;