import Icon from "@mdi/react";
import { mdiCheckBold } from "@mdi/js";

const CartNotification = () => {
  return (
    <div className="cart-notification d-flex bkg-black color-white pdd-3">
      <Icon path={mdiCheckBold} size={2} color={'green'} />
      <h3>Producto agregado al carrito</h3>
    </div>
  );
}

export default CartNotification;