import { useState, useEffect } from "react";
import SectionTextCard from "../components/section-text-card";
import IconBox from "../snippets/icon-box";
import IconCard from "../snippets/icon-card";
import AllSlider from "../components/slider";
import api from "../services/api";
import PromotionsShop from "../components/shop-promotions";
import PromotionsProducts from "../components/product-promotions";
import common from "../scripts/common";
import RecentProducts from "../components/recent-products";
import HomeCategories from "../components/home-categories";

const images = [
  { url: require("../assets/slider/slider1.jpg") },
  { url: require("../assets/slider/slider2.jpg") },
  { url: require("../assets/slider/slider3.jpg") },
];

const CardValues = [
  {
    Icon: IconBox,
    heading: "Envíos a todo el Perú",
    desc: "Disfruta de tus productos lo antes posible",
  },
  {
    Icon: IconCard,
    heading: "pagas de manera fácil y segura",
    desc: "Aceptamos los métodos de pago más utilizados",
  },
  {
    Icon: IconCard,
    heading: "Te ayudamos en tu compra",
    desc: "Escríbenos cualquier día de la semana",
  },
];

const calloutText = {
  heading: "Los productos mas populares en ",
  text: "Sombreros, gafas, bufandas y más",
  textButton: "VER TODOS",
  url: "/",
};

const calloutText3 = {
  heading: "LAS TIENDAS MÁS POPULARES",
  text: "Explora las tiendas más populares, donde encontrarás una amplia gama de productos",
  textButton: "VER TODOS",
  url: "/shops",
};

const Home = ({
  handleAddToCart,
  handleAddToList,
  handleRemoveOfFavorites,
}) => {
  const [datapromotions, setDatapromotions] = useState();

  useEffect(() => {
    api.get("api/adminHighlight/65fe1d32039a15edd97711ad").then((response) => {
      setDatapromotions(response.data);
    });
  }, []);

  useEffect(() => {
    common.removeAllUrlValues();
  }, []);

  return (
    <div className="home">
      <div className="container wrapper">
        <div className="home__inner">
          <AllSlider.ImagesSlider images={images} />
          <SectionTextCard data={CardValues} />

          <RecentProducts
            heading={"Productos Recientes"}
            handleAddToCart={handleAddToCart}
          />

          <div className="home__section mrg-v-10">
            <div className="d-flex d-flex--no-wrap d--middle d--between pdd-v-6">
              <h3 className="fs-b fw-700 ft-upper mrg-0">
                Nuestras colecciones
              </h3>

              <a href="/">Ver todos</a>
            </div>

            <HomeCategories />
          </div>
        </div>
          {datapromotions &&
            datapromotions.product_by_category &&
            datapromotions.product_by_category.map(
              (category, index) =>
                // Verifica si la categoría tiene productos antes de renderizar el componente
                category.products.length > 0 && (
                  <PromotionsProducts
                    key={index}
                    position={index}
                    categoryName={category.name}
                    data={calloutText}
                    dataProduct={category.products}
                    handleAddToCart={handleAddToCart}
                    handleAddToList={handleAddToList}
                    handleRemoveOfFavorites={handleRemoveOfFavorites}
                  />
                )
            )}
  
        {datapromotions && (
          <PromotionsShop
            data={calloutText3}
            datapromotions={datapromotions}
            uniqueClass="unique-class-3"
          />
        )}
      </div>
    </div>
  );
};

export default Home;
