import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { useState, useEffect } from "react";
import ls from "../services/ls";
import config from "../config/config";

import Footer from "../components/footer";
import Header from "../components/header";
import Main from "../components/main";

// Pages
import Home from "../pages/home";
// import Catalogue from '../pages/catalogue';
import Shops from "../pages/shops";
import JoinUs from "../pages/join-us";
import Smartphones5G from "../pages/Smartphones5G";
import Checkout from "../pages/checkout/checkout";
import Profile from "../pages/profile/profile";
import ProfilePassword from "../pages/profile/profile-password";

// Components
import Authentication from "../auth/authentication";
import ProductCardV2 from "../snippets/product-card-v2";
import ProductList from "../components/product-provider";
import ProductDetails from "../components/product-detail";
import ShopContent from "../components/shop-content";
import SearchResultsBody from "../components/SearchResultsBody";
import ProductCard from "../snippets/product-card-favorite";
import NotFoundPage from "../components/ErrorPage";

const promise = new Promise((resolve, reject) => {
  const interval = setInterval(() => {
    const sideCart = document.querySelector(".side-cart__products");
    const favoritesList = document.querySelector(".favorites-list__products");

    if (sideCart && favoritesList) {
      const cartRoot = ReactDOM.createRoot(sideCart);
      const favoritesRoot = ReactDOM.createRoot(favoritesList);

      cartRoot && favoritesRoot
        ? resolve([cartRoot, favoritesRoot])
        : reject("error");
      clearInterval(interval);
    }
  }, 500);
});

const ProductsInCart = ({
  cartVariants,
  handleOnPlus,
  handleOnMinus,
  handleRemoveOfCart,
}) => {
  const cartStorage = cartVariants;

  return (
    cartStorage &&
    cartStorage.cart &&
    cartStorage.cart.map((variant, index) => (
      <ProductCardV2
        variant={variant}
        index={index}
        key={variant.item._id}
        handleOnPlus={handleOnPlus}
        handleOnMinus={handleOnMinus}
        handleRemoveOfCart={handleRemoveOfCart}
      />
    ))
  );
};

const ProductsInFavorites = (props) => {
  const { handleRemoveOfFavorites, handleAddToCart } = props;

  const favoritesStorage = JSON.parse(ls.get("favorites"));

  return (
    favoritesStorage.favorites &&
    favoritesStorage.favorites.map((product, index) => (
      <ProductCard
        products={product.item}
        handleAddToCart={handleAddToCart}
        handleRemoveOfFavorites={handleRemoveOfFavorites}
        key={index}
      />
    ))
  );
};

const DinamycRoutes = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedPriceFilter, setSelectedPriceFilter] = useState({
    min: 0,
    max: 1000,
  });

  const filterProducts = (products, selectedBrand, selectedPrice) => {
    return products.filter((product) => {
      if (selectedBrand.length > 0 && !selectedBrand.includes(product.brand)) {
        return false; // No coincide con ninguna de las marcas seleccionadas
      }

      if (selectedPrice) {
        // Comprobar si algún variant cumple con el rango de precios seleccionado
        const firstVariant = product.variants[0];
        if (
          firstVariant &&
          parseFloat(firstVariant.price) >= selectedPrice.min &&
          parseFloat(firstVariant.price) <= selectedPrice.max
        ) {
          return true;
        } else {
          return false; // El primer variant no cumple con el rango de precios seleccionado
        }
      }
      return true; // Pasa los filtros de marca y precio
    });
  };
  const filteredProducts = filterProducts(
    products,
    selectedFilter,
    selectedPriceFilter
  );

  const handleFilterChange = (filter) => {
    if (filter === "todos") {
      // Si se selecciona "Todos", limpiar todas las marcas seleccionadas
      setSelectedFilter([]);
    } else {
      // Si no se selecciona "Todos", agregar o quitar la marca seleccionada
      setSelectedFilter((prevSelected) => {
        if (prevSelected.includes(filter)) {
          return prevSelected.filter((item) => item !== filter);
        } else {
          return [...prevSelected, filter];
        }
      });
    }
  };

  const handlePriceFilterChange = (values) => {
    setSelectedPriceFilter({ min: values[0], max: values[1] });
  };

  const [cartVariants, setCartVariants] = useState(config.cartVariants);
  const cartQuantity = document.querySelector(".side-cart__quantity");
  const cartCount = document.querySelector(".icon--cart__count");
  const [favProductsList, setFavProductsList] = useState(
    config.favoriteVariants
  );

  // AGREGAR A LA LISTA DE FAVORITOS
  const handleAddToList = (product) => {
    const notification = document.querySelector(".cart-notification");
    const updatedFavProductsList = {
      favorites: [...favProductsList.favorites, { item: product, qty: 1 }],
      subTotal: favProductsList.subTotal,
    };
    setFavProductsList(updatedFavProductsList);

    if (window.innerWidth > 480) {
      notification.querySelector("h3").textContent =
        "Producto agregado a la lista de favoritos";
      notification.style.top = 0;
      notification.style.visibility = "visible";
      notification.style.opacity = 1;

      setTimeout(() => {
        notification.removeAttribute("style");
      }, 1000);
    }
  };

  const handleRemoveOfFavorites = async (e) => {
    const notification = document.querySelector(".cart-notification");
    const id = e.currentTarget.dataset.id;
    const filterProducts = favProductsList.favorites.filter(
      (p) => p.item._id !== id
    );
    if (filterProducts) {
      setFavProductsList({ favorites: filterProducts });
    }

    if (notification) {
      // Buscar el elemento por su ID y quitar la clase 'favorite'
      const favoriteHeartIcon = document.querySelector(
        `[data-id="${id}"] .heart-icon`
      );
      if (favoriteHeartIcon) {
        favoriteHeartIcon.classList.remove("favorite");
      }

      // Verificar si existe el elemento h3 dentro de la notificación

      if (window.innerWidth > 480) {
        const notificationHeading = notification.querySelector("h3");
        if (notificationHeading) {
          notificationHeading.textContent =
            "producto eliminado de la lista de favoritos";
          notification.style.top = 0;
          notification.style.visibility = "visible";
          notification.style.opacity = 1;

          setTimeout(() => {
            notification.removeAttribute("style");
          }, 1000);
        }
      }
    }

    return favProductsList;
  };
  // REDUCIR LA CANTIDAD DEL PRODUCTO EN CARRITO
  const handleOnPlus = (quantityRef, index) => {
    if (quantityRef) {
      const updatedCartVariants = [...cartVariants.cart];
      quantityRef.current.textContent =
        parseInt(quantityRef.current.textContent) + 1;
      updatedCartVariants[index].qty++;

      setCartVariants({ cart: updatedCartVariants });
    }
  };

  // INCREMENTAR LA CANTIDAD DEL PRODUCTO EN CARRITO
  const handleOnMinus = (quantityRef, index) => {
    if (parseInt(quantityRef.current.textContent) > 1) {
      const updatedCartVariants = [...cartVariants.cart];
      quantityRef.current.textContent =
        parseInt(quantityRef.current.textContent) - 1;
      updatedCartVariants[index].qty--;

      setCartVariants({ cart: updatedCartVariants });
    }
  };

  // REMOVER PRODUCTO DEL CARRITO
  const handleRemoveOfCart = async (e) => {
    const id = e.currentTarget.dataset.id;
    const filterProducts = cartVariants.cart.filter((v) => v.item._id !== id);

    if (filterProducts) {
      setCartVariants({ cart: filterProducts });
    }

    return filterProducts;
  };

  // AGREGAR PRODUCTO AL CARRITO
  const handleAddToCart = (variant) => {
    const notification = document.querySelector(".cart-notification");
    const findVariant = cartVariants.cart.find(
      (v) => v.item._id === variant._id
    );

    if (findVariant) {
      const value = document.querySelectorAll(".product-card-v2__quantity");

      if (value) {
        value.forEach((item) => {
          if (item.getAttribute("data-id") === findVariant.item._id) {
            const position = cartVariants.cart.indexOf(findVariant);
            const updatedCartVariants = [...cartVariants.cart];
            item.textContent = parseInt(item.textContent) + 1;
            updatedCartVariants[position].qty++;
            setCartVariants({ cart: updatedCartVariants });
          }
        });
      }
    } else {
      const updatedCart = [...cartVariants.cart, { item: variant, qty: 1 }];
      setCartVariants({ ...cartVariants, cart: updatedCart });
    }
    if (window.innerWidth > 480) {
      notification.style.top = 0;
      notification.style.visibility = "visible";
      notification.style.opacity = 1;

      setTimeout(() => {
        notification.removeAttribute("style");
      }, 1000);
    }
  };

  useEffect(() => {
    ls.set("cart", JSON.stringify(cartVariants));
    if (cartQuantity && cartCount) {
      cartQuantity.textContent = cartVariants.cart.length;
      cartCount.textContent = cartVariants.cart.length;
    }
  }, [cartVariants, cartQuantity, cartCount]);

  useEffect(() => {
    ls.set("favorites", JSON.stringify(favProductsList));
  }, [favProductsList]);

  promise.then((response) => {
    response[0].render(
      <ProductsInCart
        cartVariants={cartVariants}
        handleOnPlus={handleOnPlus}
        handleOnMinus={handleOnMinus}
        handleRemoveOfCart={handleRemoveOfCart}
      />
    );
    response[1].render(
      <ProductsInFavorites
        handleRemoveOfFavorites={handleRemoveOfFavorites}
        handleAddToCart={handleAddToCart}
      />
    );
  });

  return (
    <Router basename={'/'}> 
    {/* process.env.PUBLIC_URL ||  */}
      {!window.location.href.includes("/checkout") && <Header />}
      <Main>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />

          <Route
            path="/"
            element={
              <Home
                handleAddToCart={handleAddToCart}
                handleAddToList={handleAddToList}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />

          <Route
            path="/login"
            element={
              <Authentication
                innerClass={"mw-10 w-0 mrg-auto mrg-v-10 pdd-6 bkg-white"}
                hasClose={false}
              />
            }
          />
          <Route
            path="/login?from=checkout"
            element={
              <Authentication
                innerClass={"mw-10 w-0 mrg-auto mrg-v-10 pdd-6 bkg-white"}
                hasClose={false}
              />
            }
          />

          {window.location.href.includes("/restore-password?token") && (
            <Route
              path="/restore-password"
              element={
                <div className="mw-16 w-0 mrg-auto mrg-v-10 pdd-6 bkg-white">
                  <ProfilePassword />
                </div>
              }
            />
          )}

          <Route path="/shops" element={<Shops />} />
          <Route
            path="/checkout"
            element={
              <Checkout
                handleRemoveOfCart={handleRemoveOfCart}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />
          <Route path="/join-us" element={<JoinUs />} />

          {/* jonathan */}
          <Route
            path="/Telefonia/Smartphone/Android"
            element={<Smartphones5G />}
          />
          <Route
            path="/product/:productname/:id"
            element={
              <ProductDetails
                handleAddToCart={handleAddToCart}
                handleAddToList={handleAddToList}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />
          <Route
            path="/productos/:category"
            element={
              <ProductList
                products={products}
                setProducts={setProducts}
                loading={loading}
                setLoading={setLoading}
                brands={brands}
                setBrands={setBrands}
                selectedFilter={selectedFilter}
                selectedPriceFilter={selectedPriceFilter}
                handleFilterChange={handleFilterChange}
                handlePriceFilterChange={handlePriceFilterChange}
                filteredProducts={filteredProducts}
                handleAddToCart={handleAddToCart}
                handleAddToList={handleAddToList}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />
          <Route
            path="/productos/:category/:subcategory"
            element={
              <ProductList
                products={products}
                setProducts={setProducts}
                loading={loading}
                setLoading={setLoading}
                brands={brands}
                setBrands={setBrands}
                selectedFilter={selectedFilter}
                selectedPriceFilter={selectedPriceFilter}
                handleFilterChange={handleFilterChange}
                handlePriceFilterChange={handlePriceFilterChange}
                filteredProducts={filteredProducts}
                handleAddToCart={handleAddToCart}
                handleAddToList={handleAddToList}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />

          <Route
            path="/search/:searchTerm"
            element={
              <SearchResultsBody
                handleAddToCart={handleAddToCart}
                handleAddToList={handleAddToList}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />
          <Route
            path="/shops/tienda/:nombreTienda/:id"
            element={
              <ShopContent
                handleAddToCart={handleAddToCart}
                handleAddToList={handleAddToList}
                handleRemoveOfFavorites={handleRemoveOfFavorites}
              />
            }
          />

          {config.sessionId && <Route path="/profile" element={<Profile />} />}
        </Routes>
      </Main>
      {!window.location.href.includes("/checkout") && <Footer />}
    </Router>
  );
};
export default DinamycRoutes;
