import { useNavigate } from 'react-router-dom'
import imgFn from '../scripts/image-functions';
import onImageRescaling from '../scripts/imageRescaling';
import UseWindowSize from '../scripts/window-size';


const ShopCard = (props) => {
  const { data } = props
  const navigate = useNavigate()
  const screen = UseWindowSize();

  const redirectToShop = (name, id) => {
    // Realiza cualquier acción necesaria antes de redirigir
    navigate(
      `/shops/tienda/$${encodeURIComponent(name).replace(/%20/g, '-')}/${id}`
    )
  }
  return (
    data &&
    data.map((shop, key) => (
      <div className={`shop-card shop-card--${key}`} key={key}>
        <div className='pdd-h-2 pdd-v-1 shop-card__inner'>
          <div className='shop-card__header d--middle'>
            <div className='shop-card__logo'>
            {screen.width >= 480 ? (
              <img
                className='h-2 w-2 shop-card__image'
                src={onImageRescaling(shop.shop.images.logo.imageUrl, 200)}
                title={shop.shop.shop_name}
                alt={shop.shop.shop_name}
                onError={(e) => imgFn.onErrorLoadingImage(e)}

              />
            ):(
<img
                className='h-2 w-2 shop-card__image'
                src={onImageRescaling(shop.shop.images.logo.imageUrl, 200)}
                title={shop.shop.shop_name}
                alt={shop.shop.shop_name}
                onError={(e) => imgFn.onErrorLoadingImage(e)}

              />
            )}
            </div>
            <div className='shop-card__content h-1 d--center'>
              
              <div className='shop-card__heading '>{shop.shop.shop_name}</div>


              <div className='shop-card__reviews'></div>
            </div>
          </div>

          <div className='shop-card__products'>
            {shop.products.map((product, index) => (
              <img
                className='b-xs bc-black w-2 h-2 shop-card__image'
                key={index}
                src={onImageRescaling(product.first_variant.images[0].imageUrl, 200)}                
                title={shop.shop.shop_name}
                alt={shop.shop.shop_name}
                onError={(e) => imgFn.onErrorLoadingImage(e)}

              />
            ))}
          </div>

          <div className='shop-card__button'>
            <button
              onClick={() => redirectToShop(shop.shop.shop_name, shop._id)}
              className='button button--primary'
              title='ver tienda'
              type='button'
            >
              Ver tienda
            </button>
          </div>
        </div>
      </div>
    ))
  )
}

export default ShopCard
