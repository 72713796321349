import { Fragment } from "react";

const DetailBlock = (props) => {
  const { heading, detailsList } = props;

  return (
    <Fragment>
      <div className="detail">
        <div className="detail-heading p1 weight-2">
          {heading}
        </div>
        <ul>
          {
            detailsList && detailsList.map((item, key) => (
              <li className="label" key={key}>
                <a className="detail-link" href={item.link}>
                  <div className="p1">
                    {item.name}
                  </div>
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </Fragment>
  );
}

export default DetailBlock;