import api from "./api";

const mail = (to, subject, text = "", html = "") => {
  const mailData = {
    to: to,
    subject: subject,
    text: text,
    html: html,
  };

  return new Promise((resolve, reject) => {
    api.post(`service/mail`, mailData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default mail;