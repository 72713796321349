import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import imgFn from "../scripts/image-functions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import onImageRescaling from "../scripts/imageRescaling";

const Product = ({
  handleAddToCart,
  dataVariant,
  handleAddToList,
  handleRemoveOfFavorites,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [isBouncing, setIsBouncing] = useState(false);
  const favoritesStorage = JSON.parse(localStorage.getItem("favorites")); // Recuperando la lista de productos favoritos del localStorage

  useEffect(() => {
    // Verificar si el producto actual está en la lista de favoritos
    const isProductFavorite =
      favoritesStorage.favorites &&
      favoritesStorage.favorites.some(
        (favorite) => favorite.item._id === dataVariant._id
      );
    // Actualizar el estado de isFavorite
    setIsFavorite(isProductFavorite);
  }, []);

  const toggleFavorite = (e) => {
    if (isFavorite) {
      // Si es favorito, lo quitamos de la lista
      handleRemoveOfFavorites(e);
    } else {
      // Si no es favorito, lo agregamos a la lista
      handleAddToList(dataVariant);
    }
    // Invertimos el estado de isFavorite
    setIsBouncing(true);
    setIsFavorite(!isFavorite);
    setTimeout(() => {
      setIsBouncing(false);
    }, 1000);
  };



  const handleOnClick = (e) => {
    e.preventDefault();
    handleAddToCart(dataVariant);
  };

  return (
    dataVariant && (
      <li
        key={dataVariant._id}
        className="d-flex d-flex--column d--between mrg-l-2 mrg-r-2"
      >
        <div className="card--product__container bkg-white ">
          <div className="d-grid d-grid__column--2fa w-0  d--middle">
            <p className="fs-m fw-700 mrg-0 mrg-l-3">{dataVariant.brand}</p>

            <div onClick={(e) => toggleFavorite(e)} data-id={dataVariant._id}>
              <FontAwesomeIcon
                icon={faHeart}
                className={`heart-icon ${isFavorite ? "favorite" : ""}`}
                {...(isBouncing ? { bounce: true } : {})}
              />
            </div>
          </div>

          <a
            className="card--product-container product "
            href={`/product/${encodeURIComponent(dataVariant.variant_name).replace(
              /%20/g,
              "-"
            )}/${dataVariant._id}`}
          >
            <div className="d-flex mrg-auto mw-5 pdd-b-1 pdd-h-3 product-image-container w-0 mrg-b-2">
              {dataVariant.offer ? (
                <span className="product-image-container__discount-label bkg-red color-white discount-label fs-xl pdd-1">
                  {dataVariant.offer.value}%
                </span>
              ) : (
                <></>
              )}
              <LazyLoadImage
                className="mw-5 w-0 h-4"
                src={onImageRescaling(dataVariant.images[0].imageUrl, 200)}
                alt={dataVariant.product_name}
                onError={(e) => imgFn.onErrorLoadingImage(e)}
              />
            </div>

            <div className="card--product-container description fs-n ta-center h-1"
              title={`${dataVariant.product_name} ${dataVariant.variant_name}`}>
              <p className="mrg-0">{dataVariant.product_name} {dataVariant.variant_name}</p>
            </div>

            {dataVariant.offer ? (
              <div className="d--center d--middle d-flex d-flex--column mrg-t-2 mh-2  pdd-b-1 ta-center">
                <p className="mrg-0 td-through ">
                  S/.{dataVariant.price.toFixed(2)}
                </p>
                <p className="new-price-value color-red fs-l mrg-0 pdd-">
                  S/
                  {(
                    dataVariant.price *
                    (1 - dataVariant.offer.value / 100)
                  ).toFixed(2)}
                </p>
              </div>
            ) : (
              <div className="d--center d--middle d-flex d-flex--column pdd-2 ta-center mh-2 mrg-t-2">
                <p className="fs-l mrg-0">S/{dataVariant.price.toFixed(2)}</p>
              </div>
            )}
          </a>

          <div className="card--product-provider__cta w-0 pdd-h-2 color-white">
            <button
              className="button button--outline w-0"
              type="button"
              title="añadir carrito"
              onClick={(e) => handleOnClick(e)}
            >
              Añadir al Carrito
            </button>
          </div>
        </div>
      </li>
    )
  );
};

export default Product;
