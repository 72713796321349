import api from "./api";
import config from "../config/config";

const request = (type, number) => {
  return new Promise((resolve, reject) => {
    api.get(`service/sunat-reniec/${type}/${number}`, config.ssToken, config.sessionId)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

const SR = {
  request,
}

export default SR;
