import { useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronUp } from '@mdi/js';

const Select = ({ name, options, value, setValue, setPosicion, setDisableOption, addClass, addClassInner, addClassLabel }) => {
  const [display, setDisplay] = useState(false);

  const handleOnShowOptions = () => {
    setDisplay(!display);
  }

  const handleOnPointerLeave = () => {
    setDisplay(false);
  }

  const handleOnSelectOption = (e) => {
    setValue(e.target.dataset.option);
    setDisplay(false);

    if (setPosicion) {
      setPosicion(parseInt(e.target.dataset.position));
    }
  }

  const onSelectOption = () => {
    setDisableOption(false);
  }

  return (
    <div className={`select ${addClass ? addClass : ''}`} onPointerLeave={handleOnPointerLeave} data-name={name}>
      <div className={`select__inner ${addClassInner ? addClassInner : ''}`}>
        <div className={`select__label ${addClassLabel ? addClassLabel : ''}`} onClick={handleOnShowOptions}>
          {value}

          <Icon path={mdiChevronUp} data-open={display} className='select__icon' />
        </div>

        {
          display && (
            <div className='select__options'>
              {
                options.map((option, index) => (
                  <div className='select__option' key={index} data-position={index} data-option={option} onClick={handleOnSelectOption} onClickCapture={onSelectOption}>{option}</div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Select;
