import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SearchResultsHeader from './searchResultHeader'
import api from '../services/api'
import Product from './product'

const SearchResultsBody = ({handleAddToCart,handleAddToList,handleRemoveOfFavorites}) => {
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(true)


  const { searchTerm } = useParams()
  useEffect(() => {
    setLoading(false)
    api
      .get(
        `api/variant/filterSearch?query=${searchTerm}`
      )
      .then(async (response) => {
        const data = response.data
        setProduct(data.data)
      })

      .catch((error) => {
        console.error('Error al obtener los datos:', error)
        setLoading(true)
      })
  }, [])

  return (
    <div className='container wrapper bkg-white pdd-t-4'>
      <SearchResultsHeader
        searchTerm={searchTerm}
        totalProducts={product.length}
      />

          {loading ? (
              <p className='product-list-loading'>Cargando...</p>
            ) 
            : (<ul className='d--center d-grid d-grid__column--2fr!sm d-grid__column--3fr!md d-grid__column--5fr!lg d--gap-4 pdd-b-10'>
                {product.map((product,index) => (
                  <Product
                  key={index}
                  handleAddToCart={handleAddToCart}
                  dataVariant={product}
                  handleAddToList={handleAddToList}
                  handleRemoveOfFavorites={handleRemoveOfFavorites}

                  />
                ))}
              </ul>
            )}
    </div>
  )
}

export default SearchResultsBody


