import iconYape from '../assets/logo-yape.svg';
import iconPlin from '../assets/logo-plin.svg';
import iconVisa from '../assets/logo-visa.svg';
import iconMastercard from '../assets/mastercard-logo.png';
import iconPagoefectivo from '../assets/logo-pagoefectivo.svg';
import departments from '../files/departments.json';
import provinces from '../files/provinces.json';
import districts from '../files/districts.json';

const paymentOptions = [
  {
    label: "Yape",
    name: "yape",
    icon: iconYape,
  },
  {
    label: "Plin",
    name: "plin",
    icon: iconPlin,
  },
  {
    label: "Visa",
    name: "visa",
    icon: iconVisa,
  },
  {
    label: "Mastercard",
    name: "mastercard",
    icon: iconMastercard,
  },
  {
    label: "Pago efectivo",
    name: "pagoefectivo",
    icon: iconPagoefectivo,
  },
]

const peru = {
  departments,
  provinces,
  districts,
}

const variables = {
  paymentOptions,
  peru,
}

export default variables;