import { useState, useEffect } from 'react';
import ProfileAddressForm from './forms/profile-address-form';

const ProfileAddress = ({ address, updateInfo, setUpdateInfo, handleOnSubmitForm }) => {
  const [mainAddress, setMainAddress] = useState({});
  const [secondaryAddress, setSecondaryAddress] = useState({});
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [showSecondaryAddress, setShowSecondaryAddress] = useState(false);
  const [noSelect, setNoSelect] = useState(false);

  const onActivateButton = () => {
    setDisabledBtn(false);
  }

  useEffect(() => {
    setUpdateInfo({
      ...updateInfo,
      profile: {
        ...updateInfo.profile,
        address: {
          main: mainAddress,
          secondary: secondaryAddress
        },
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainAddress, secondaryAddress]);

  return (
    <div className="profile-address">
      <form onSubmit={(e) => handleOnSubmitForm(e, setNoSelect)} onInput={onActivateButton}>
        <ProfileAddressForm mainClass={"mrg-b-6"} heading={"Dirección principal"} address={address ? address.main : ""} set={setMainAddress} setDisabledBtn={setDisabledBtn} />

        {
          address.secondary && address.secondary.ubigeo === "" ? (
            showSecondaryAddress ? (
              <ProfileAddressForm heading={"Dirección secundaria"} address={address ? address.secondary : ""} set={setSecondaryAddress} setDisabledBtn={setDisabledBtn} />
            ) : (
              <button
                onClick={() => setShowSecondaryAddress(true)}
                className={`button button--primary bkg-text-label w-0`}
                type='button'
                title='mostrar dirección secundaria'>
                + Agregar dirección secundaria
              </button>
            )
          ) : (
            <ProfileAddressForm heading={"Dirección secundaria"} address={address ? address.secondary : ""} set={setSecondaryAddress} setDisabledBtn={setDisabledBtn} />
          )
        }

        {
          noSelect && <span className='color-red pdd-t-1'>Seleccioné un departamento, provincia y distrito</span>
        }

        <div className='pdd-v-3'>
          <button className={`button button--primary bkg-green-2 w-0 ${disabledBtn ? "disabled" : ""}`} type='submit' title='guardar cambios' disabled={disabledBtn}>Guardar</button>
        </div>
      </form>
    </div>
  )
}

export default ProfileAddress;