import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseWindowSize from '../scripts/window-size'
import ClassChange from '../scripts/class-change'
import IconSearch from './icon-search'
import api from '../services/api'
import Loading from './loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import productNofound from '../assets/product-not-found.png'
import onImageRescaling from '../scripts/imageRescaling'

const Search = () => {
  const sizeScreen = UseWindowSize()
  const className = ClassChange(
    [640],
    ['icon--xsmall'],
    sizeScreen.width,
    'icon--small'
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const handleSearchClick = () => {
    api
      .get(`api/variant/filterSearch?query=${searchTerm}`)
      .then(async (response) => {
        const { data } = response.data
        setSearchResults(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error)
        setLoading(false)
      })
  }

  const redirectToPage = (name, id) => {
    navigate(`/product/${encodeURIComponent(name).replace(/%20/g, '-')}/${id}`)

    setSearchTerm('')
    setSearchResults([])
  }

  const handleChange = (e) => {
    setLoading(true)

    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setSearchResults([])
      setLoading(false)
    }
  }

  // busqueda al presionar la tecla enter
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick()
    }
  }
  const handleSeeMore = () => {
    if (searchTerm.trim() !== '') {
      navigate(`/search/${encodeURIComponent(searchTerm)}`)
      setSearchTerm('')
      setSearchResults([])
    }
  }
  const cleanInput = () => {
    setSearchTerm('')
    setLoading(false)
  }

  return (
    <Fragment>
      <div className='search'>
        <div className='search__input-container'>
          <input
            className={`search__input transition--normal `}
            type='text'
            placeholder='Buscar Producto...'
            value={searchTerm}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />

          {searchTerm && (
            <button
              className='bkg-transparent'
              onClick={cleanInput}
              title='cancelar'
              type='button'
            >
              <FontAwesomeIcon icon={faCircleXmark} size='2x' />
            </button>
          )}

          <button
            className={`icon ${className} icon--search icon--search-2`}
            onClick={handleSearchClick}
            title='buscar'
            type='button'
            disabled={searchTerm === ''}
          >
            <IconSearch />
          </button>
        </div>
      </div>

      <div
        className={`h-0 breadcrumbs__level d-flex search__results transition--normal w-15!lg ${
          searchTerm === '' ? 'hide' : ''
        }`}
      >
        {loading && searchTerm.length >= 3 &&(
          <div className='bkg-white pdd-h-2 pdd-v-2 ta-center w-0'>
            <span>
              CARGANDO BÚSQUEDA PRESIONE ENTER O EL ICONO DE LA LUPA PARA
              CONTINUAR
            </span>
            <div className="h-1 loading mrg-t-2">
      <div className="loading__inner">
        <div className="image">
          <img src={require('../assets/loading.png')} alt="loading" width={100} height={100} />
        </div>
      </div>
    </div>
          </div>
        )}

        {!loading && searchTerm !== '' && searchResults.length > 0 && (
          <div className='ListResult bkg-white d-flex'>
            <ul className='d-grid d-grid__column--1fr!sm d-grid__column--2fr!lg d-grid__column--2fr!md w-0!sm d--gap-2 pdd-3'>
              {searchResults.slice(0, 6).map((product, key) => (
                <li
                  key={key}
                  className='d--middle pdd-2 search-result-item w-7'
                  onClick={() =>
                    redirectToPage(product.product_name, product._id)
                  }
                >
                  <img
                    className='h-2 mrg-0 variant-image '
                    src={onImageRescaling(product.images[0].imageUrl, 80)}                
                    alt={product.name}
                  />

                  <div className='d--center d-flex d-flex--column h-0 ta-center w-4'>
                    <span className='variant-name'>{product.product_name}</span>
                    <span>S/ {product.price.toFixed(2)}</span>
                  </div>
                </li>
              ))}
            </ul>

            <button
              className='more-results color-red fs-n  w-0 bkg-white pdd-t-2 mrg-t-2 pdd-b-2'
              onClick={handleSeeMore}
              title='ver más'
              type='button'
            >
              Ver más resultados
            </button>
          </div>
        )}

        {!loading && searchTerm !== '' && searchResults.length === 0 && (
          <div className='bkg-white d--center d-flex pdd-2 ta-center d-flex--column w-0 d--middle'>
            <span>
              ¡Lo sentimos! No se encontraron resultados para "{searchTerm}"
            </span>
            <img
              className='mw-5 mh-5'
              src={productNofound}
              alt='imagen-buscada'
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Search
